import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { decodePageParam } from '../page/Page.slice.helper';
import { ActiveDate, DatebarState } from './Datebar.slice.types';

const searchParams = new URLSearchParams(window.location.search);

const p = searchParams.get('p');

const decoded = p && decodePageParam(p);

const initialState: DatebarState = {
  activeDate:
    (decoded && {
      period: decoded.period,
      date: decoded.date,
    }) ||
    undefined,
};

export const datebarSlice = createSlice({
  name: 'datebar',
  initialState,
  reducers: {
    changeActiveDate: (state, { payload }: PayloadAction<ActiveDate>) => {
      state.activeDate = payload;
    },
  },
});

export const datebarReducer = datebarSlice.reducer;

export const useDatebarActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators(datebarSlice.actions, dispatch),
    [dispatch],
  );
};
