import { classes } from '@nowadays/ui/utils';

const MockupWarning = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 792 632'
      className={styles.root}
    >
      <g clipPath='url(#a)'>
        <path
          fill='#E26B48'
          d='M667.471 604.529c3.118.194 4.841-3.681 2.48-5.939l-.235-.933.093-.223c1.86-4.438 6.982-6.539 11.429-4.701 14.06 5.813-.784 19.171.332 28.21.391 3.119 12.611 3.291 11.92 6.353 6.498-14.208 9.915-29.721 9.91-45.321a105.236 105.236 0 0 0-.657-11.748 108.362 108.362 0 0 0-1.505-9.525c-3.487-17.022-11.029-33.233-21.905-46.772-5.227-2.855-2.039-7.322-4.673-12.673-.946-1.93-9.386-1.974-9.738-4.089.378.049 5.834-5.617 4.031-8.418-1.185-1.844-.816-4.191.707-5.767.149-.155.29-.318.42-.493 4.499-6.105 10.702-5.041 13.943 3.252 6.918 3.488 6.987 9.278 2.745 14.848-2.699 3.543-3.068 8.337-5.436 12.13.244.312.497.614.741.926a111.064 111.064 0 0 1 11.601 18.365c-.924-7.194.433-15.863 2.75-21.451 2.655-6.413 7.652-11.807 12.029-17.354 5.227-6.625 15.865-3.619 16.79 4.769l.026.242a31.26 31.26 0 0 0-1.901 1.164c-3.53 2.336-2.307 7.81 1.878 8.454l.094.014c-.233 2.331-8.502 9.672-9.086 11.944 5.593 21.598 1.408 24.45-15.799 24.812l-.903 1.286a110.556 110.556 0 0 1 3.928 14.352 111.357 111.357 0 0 1 1.935 13.659c.451 5.781.414 11.592-.072 17.364l.029-.205c1.236-6.357 4.686-12.296 9.696-16.412 7.463-6.135 18.01-8.397 26.062-13.329 3.876-2.375 8.845.69 8.17 5.185l-.033.215a31.269 31.269 0 0 0-3.499 1.688c-.648.366-1.283.755-1.903 1.165-3.531 2.337-2.306 7.812 1.88 8.453l.094.015.195.029c-2.055 4.885-21.639 11.777-25.226 15.688-3.487 18.866-1.774 18.304-17.829 12.816h-.009a113.399 113.399 0 0 1-7.607 22.232l-27.202.009c-.098-.302-.186-.614-.274-.916 2.516.155 5.051.008 7.527-.451-2.019-2.476-4.038-4.971-6.057-7.447-.049-.048-.088-.097-.127-.146l-.031-.038-2.8-9.219a46.326 46.326 0 0 1 1.072-6.069Z'
        />
        <path
          fill='#3C3C3A'
          d='m380.013 628.595-240.703.083c-12.093.004-22.925-6.245-28.974-16.715-3.026-5.236-4.539-10.979-4.541-16.724-.002-5.744 1.508-11.489 4.529-16.726l120.275-218.955c6.044-10.475 16.871-16.731 28.964-16.735 12.092-.004 22.924 6.244 28.975 16.715L408.98 578.437c3.017 5.221 4.526 10.958 4.527 16.699 0 5.741-1.509 11.486-4.531 16.724-6.043 10.474-16.87 16.73-28.963 16.735Zm-271.192-33.36c0 5.229 26.561-6.038 29.312-1.276 5.504 9.526-9.826 31.704 1.176 31.7 0 0 261.555-5.779 267.052-15.308 2.748-4.764 4.122-9.992 4.12-15.217-.002-5.226-1.379-10.452-4.131-15.214L285.907 361.021c-5.488-9.498-15.342-15.183-26.343-15.179-10.997.004-150.743 244.164-150.743 249.393Z'
        />
        <path
          fill='#3C3C3A'
          d='M382.686 625.574c-3.017 6.039 138.293 4.183 139.964 4.183 0 0 39.718-.878 40.553-2.325a4.616 4.616 0 0 0 .626-2.311 4.618 4.618 0 0 0-.628-2.31l-18.29-33.241a4.572 4.572 0 0 0-4-2.305c-.069 0-.172.062-.304.18-24.399 21.804-54.468 36.217-87.186 36.704-34.79.517-70.543 1.041-70.735 1.425Z'
        />
        <path
          fill='#3C3C3A'
          d='M0 629.593c0 .996.8 1.796 1.797 1.796l788.407-.273c.997 0 1.796-.8 1.796-1.797 0-.996-.801-1.796-1.797-1.796l-788.407.273A1.792 1.792 0 0 0 0 629.593Z'
        />
        <path
          fill='#A0616A'
          d='m609.662 620.874-13.654.004-6.513-52.661 20.151-.006.016 52.663Z'
        />
        <path
          fill='#3C3C3A'
          d='M562.884 620.581c-.425.716-.648 3.027-.648 3.86a4.634 4.634 0 0 0 4.635 4.632l42.283-.015a3.16 3.16 0 0 0 3.16-3.162l-.001-1.76s2.09-5.292-2.218-11.811c0 0-5.351 5.108-13.351-2.887l-2.36-4.272-17.068 12.491-9.462 1.168c-2.07.256-3.906-.038-4.97 1.756Z'
        />
        <path
          fill='#A0616A'
          d='m557.311 575.833-12.933 4.376-23.036-47.801 19.087-6.459 16.882 49.884Z'
        />
        <path
          fill='#3C3C3A'
          d='M512.903 590.537c-.173.814.355 3.075.622 3.864a4.633 4.633 0 0 0 5.875 2.903l40.05-13.555a3.161 3.161 0 0 0 1.981-4.008l-.564-1.668s.285-5.682-5.885-10.478c0 0-3.432 6.552-13.572 1.541l-3.604-3.291-12.167 17.3-8.59 4.137c-1.879.905-3.713 1.215-4.146 3.255Zm23.318-320.197-26.482 87.705-31.263 78.889 49.495 112.661 18.352-8.164-21.96-103.493 46.052-70.227 21.49 219.23 18.354-1.026 24.891-264.623 3.549-57.615-102.478 6.663Z'
        />
        <path
          fill='#E26B48'
          d='m616.201 76.058-38.747 7.151-3.053 17.336-23.449 11.225-12.193 127.468s-20.385 26.52-4.067 34.672l104.007-10.233s5.092-18.855-.007-20.643c-5.099-1.789-5.114-46.825-5.114-46.825l23.421-92.633-34.675-12.225-6.123-15.293Z'
        />
        <path
          fill='#A0616A'
          d='M593.347 75.723c17.28 0 31.289-14.008 31.289-31.289 0-17.28-14.009-31.288-31.289-31.288s-31.289 14.008-31.289 31.288 14.009 31.289 31.289 31.289Z'
        />
        <path
          fill='#3C3C3A'
          d='M614.067 49.078c-2.497 1.367-.128 7.893-1.688 8.457-1.804.652-8.381-6.834-8.462-15.219-.025-2.537.559-3.756-.002-6.765-.735-3.936-2.99-8.578-5.077-8.455-1.238.074-2.446 1.823-2.536 3.384-.126 2.188 1.999 3.049 1.693 4.228-.583 2.244-9.508 3.794-12.685.004-2.491-2.972-.58-8.189-1.694-8.456-.821-.197-1.702 2.676-5.072 5.921-1.269 1.223-3.158 3.042-4.227 2.539-1.546-.727-.423-5.852-.848-5.92-.352-.056-.573 3.546-2.535 6.767-2.52 4.137-7.719 7.326-10.145 5.923-1.487-.86-1.604-3.255-1.693-5.074-.188-3.831-5.756-12.644-4.316-16.743 2.932-8.342 7.956-2.461 10.228-5.245 3.075-3.77 5.835-1.743 10.991-5.924 5.037-4.084 5.081-8.189 8.453-8.459 3.038-.243 4.118 2.997 7.612 2.534 2.96-.392 3.604-2.905 5.919-2.539 2.119.335 2.259 2.55 5.075 3.381 1.798.531 2.193-.238 4.228-.001 4.235.491 7.08 4.348 7.612 5.07 2.055 2.786 1.258 3.96 3.385 6.765 1.835 2.418 5.901 1.391 7.43 2.599 4.995 3.946 7.142 11.223 7.185 19.517.031 5.801-.726 8.732-2.414 16.055-1.281 5.56-7.218 12.745-10.491 15.95-.878.86-3.341 3.27-5.073 2.538-1.641-.694-1.69-3.816-1.692-4.228-.008-1.417.37-3.171 3.38-7.612 3.161-4.664 4.417-5.196 4.225-6.766-.338-2.775-4.776-5.316-6.766-4.226Z'
        />
        <path
          fill='#A0616A'
          d='M504.926 340.789c-.973 8.155 2.444 15.269 7.634 15.888 5.19.62 10.186-5.489 11.16-13.644.427-3.57.011-6.94-1.026-9.654l3.693-34.608-16.278-1.387-1.912 34.264c-1.647 2.393-2.844 5.571-3.271 9.141Z'
        />
        <path
          fill='#E26B48'
          d='M551.716 110.946s-8.433.12-14.017 11.026c-2.967 5.795-18.842 114.161-18.842 114.161l-14.623 88.773 24.91.125 19.411-84.07 16.329-48.758-13.168-81.257Z'
        />
        <path
          fill='#A0616A'
          d='M621.816 327.762c-2.385 7.859-.265 15.461 4.737 16.979 5.001 1.518 10.989-3.622 13.375-11.482 1.044-3.44 1.224-6.831.678-9.684l9.69-33.429-15.784-4.213-7.877 33.402c-2.04 2.068-3.775 4.987-4.819 8.427Z'
        />
        <path
          fill='#E26B48'
          d='M639.154 105.112s12.234-9.182 22.436 5.09c10.202 14.273 14.321 131.538 14.321 131.538l-28.527 72.409-21.416-7.13 20.364-88.722-35.714-67.289 28.536-45.896ZM329.799 580.307c60.859 0 110.195-49.336 110.195-110.195 0-60.859-49.336-110.195-110.195-110.195-60.859 0-110.195 49.336-110.195 110.195 0 60.859 49.336 110.195 110.195 110.195Z'
        />
        <path
          fill='#EBE9E5'
          d='M329.819 537.663c5.588 0 10.118-4.53 10.118-10.118s-4.53-10.118-10.118-10.118-10.117 4.53-10.117 10.118 4.529 10.118 10.117 10.118Zm-.043-135.102c-.929 6.176.736 7.468.745 34.093.009 26.625 5.534 62.322-.712 62.324-6.245.002-8.529-73.669-30.215-80.189-25.498-7.666 33.191-36.22 30.182-16.228Z'
        />
        <path
          fill='#3C3C3A'
          d='m181.902 526.373-9.956 21.036c12.036-8.631 30.835-16.071 45.795-20.055-13.939-6.733-30.995-17.589-41.185-28.337l5.727 22.227C115.148 507.57 66.938 456.068 66.918 397.072l-2.425-.835c.02 61.623 47.564 116.175 117.409 130.136Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h792v631.389H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

const styles = {
  root: classes('w-full', 'h-full', 'max-w-[30rem]', 'max-h-[30rem]'),
};

export default MockupWarning;
