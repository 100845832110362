import { AnchorButton, NotificationItem } from '@nowadays/ui/components';
import { APP_ENVS, classes } from '@nowadays/ui/utils';

export const accountDisabled = (translate: Translate): NotificationItem => {
  return {
    title: translate('ui.shared.auth.notifications.accountDisabled.message'),
    color: 'red',
    content: (
      <div>
        <span className={styles.description}>
          {translate(
            'ui.shared.auth.notifications.accountDisabled.description',
          )}
        </span>

        <AnchorButton color='accent' href={`mailto:${APP_ENVS.SUPPORT_EMAIL}`}>
          {APP_ENVS.SUPPORT_EMAIL}
        </AnchorButton>
      </div>
    ),
  };
};

const styles = {
  description: classes('text-sm', 'text-skin-muted'),
};
