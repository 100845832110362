export const app = {
  ai: {
    greeting: {
      hello: 'Merhaba,',
      help: 'Bugün size nasıl yardımcı olabilirim?',
    },
    prompt: { placeholder: 'Bir mesaj gönder', title: 'Bir şey sor' },
    suggested: { title: 'Önerilen' },
  },
  appbar: { navigatePlanners: 'Planlayıcılarım' },
  datebar: {
    panel: { active: { week: 'H' }, footer: { confirm: 'Sayfaya git' } },
  },
  draw: {
    actions: {
      cancel: 'İptal',
      color: 'Renk',
      eraser: 'Silgi',
      pencil: 'Kalem',
      save: 'Kaydet',
      size: 'Boyut',
    },
  },
  home: {
    beta: {
      description:
        'Herhangi bir geri bildiriminiz varsa veya herhangi bir sorunla karşılaşırsanız, bize iletebilirsiniz. Düşüncelerinizi paylaşın ve uygulamayı daha da iyi hale getirmemize yardımcı olun.',
      info: 'Uygulamamız şu anda beta aşamasında!',
      thanks:
        'Geri bildirimleriniz, uygulamayı geliştirmemize yardımcı olmak için çok değerlidir.',
      title: "Nowadays'e hoş geldiniz!",
    },
    greeting: {
      description: 'Kişisel planlayıcınızı yapmak için hazır mısınız?',
      hello: 'Merhaba,',
      newPlanner: 'Kendi planlayıcını oluştur',
      usePreset: 'Hazır planlayıcı kullan',
      welcome: 'HOŞGELDİN,',
    },
    planners: {
      bar: {
        category: {
          active: 'Planlayıcılarım',
          archived: 'Arşivlenenler',
          deleted: 'Silinenler',
          preset: 'Hazır planlayıcılar',
        },
        new: { title: 'Yeni planlayıcı' },
        permanentlyDelete: {
          confirm:
            'Tüm planlayıcıları kalıcı olarak silmek istediğinizden emin misiniz?',
          title: 'Tümünü sil',
        },
        presetFilter: {
          daily: 'Günlük',
          monthly: 'Aylık',
          search: 'Planlayıcı ara...',
          weekly: 'Haftalık',
          yearly: 'Yıllık',
        },
      },
      card: {
        detail: {
          createdAt: 'Oluşturulma',
          createdBy: 'Oluşturan {{ by }}',
          deletedAt: 'Silinme',
          lastEditedAt: 'Son düzenleme',
          periods: {
            daily: 'Günlük',
            monthly: 'Aylık',
            weekly: 'Haftalık',
            yearly: 'Yıllık',
          },
        },
        image: {
          edit: 'Planlayıcıyı düzenle',
          use: 'Planlayıcıyı kullan',
          view: 'Planlayıcıyı görüntüle',
        },
      },
      form: {
        planner: {
          add: 'Planlayıcı ekle',
          description: 'Açıklama',
          edit: 'Planlayıcı düzenle',
          editPreset: 'Hazır planlayıcı düzenle',
          images: {
            add: {
              planner: 'Kapak görseli ekle',
              presetPlanner: 'Planlayıcı görseli ekle',
            },
            delete: 'Kapağı sil',
            notFound: 'Kapak görselleri bulunamadı',
            title: 'Kapaklar',
          },
          period: {
            daily: 'Günlük',
            monthly: 'Aylık',
            weekly: 'Haftalık',
            yearly: 'Yıllık',
          },
          periods: 'Planlayıcı periyotları',
          status: 'Durum',
          title: 'Başlık',
        },
      },
      menu: {
        archive: { title: 'Arşivle' },
        delete: {
          confirm: 'Planlayıcıyı silmek istediğinizden emin misiniz?',
          title: 'Sil',
        },
        deletePreset: {
          confirm:
            'Hazır planlayıcıyı kalıcı olarak silmek istediğinizden emin misiniz?',
          title: 'Sil',
        },
        edit: { title: 'Düzenle' },
        images: { title: 'Kapaklar' },
        permanentlyDelete: {
          confirm:
            'Planlayıcıyı kalıcı olarak silmek istediğinizden emin misiniz?',
          title: 'Kalıcı olarak sil',
        },
        restore: { title: 'Geri al' },
        unarchive: { title: 'Arşivden çıkar' },
      },
      notFound: {
        createNew: 'Planlayıcı oluştur',
        createNewPreset: 'Hazır planlayıcı oluştur',
        or: 'veya',
        presetTitle: 'Hazır planlayıcı bulunamadı',
        title: 'Planlayıcı bulunamadı',
        usePreset: 'Hazır planlayıcı kullan',
      },
      plannerImage: {
        add: { button: 'Sayfa görseli ekle', title: 'Ekle' },
        delete: {
          confirm: 'Görseli silmek istediğinizden emin misiniz?',
          title: 'Sil',
        },
        edit: { title: 'Düzenle' },
        form: {
          image: 'Görsel',
          images: { dark: 'Koyu', light: 'Açık' },
          period: 'Periyot',
          periods: {
            daily: 'Günlük',
            monthly: 'Aylık',
            weekly: 'Haftalık',
            yearly: 'Yıllık',
          },
        },
        image: {
          period: {
            daily: 'Günlük',
            monthly: 'Aylık',
            weekly: 'Haftalık',
            yearly: 'Yıllık',
          },
        },
      },
      usePresetPlanner: {
        copy: 'Planlayıcıyı kullan',
        edit: 'Planlayıcıyı düzenle',
      },
    },
  },
  login: {
    form: {
      email: 'E-posta',
      login: 'Oturum aç',
      password: 'Parola',
      passwordReset: 'Parolamı unuttum',
      rememberMe: 'Beni hatırla',
    },
    redirectSignup: { createAccount: 'Hasabınız yok mu?', signup: 'Kayıt ol' },
    title: 'Oturum aç',
  },
  modules: {
    items: {
      ai: 'YZ',
      draw: 'Çizim',
      sticker: 'Etiket',
      template: 'Şablon',
      text: 'Not',
      widget: 'Araç',
    },
  },
  page: {
    actions: {
      edit: 'Sayfayı düzenle',
      fitContent: 'Sayfayı ekrana sığdır',
      inOut: 'Sayfayı büyüt/küçült',
      pages: 'Sayfalar',
      redo: 'İleri al',
      undo: 'Geri al',
    },
    element: {
      menu: {
        context: {
          copy: 'Kopyala',
          delete: 'Sil',
          duplicate: 'Çoğalt',
          more: 'Diğer',
          toBack: 'Arkaya gönder',
          toFront: 'Öne getir',
        },
      },
    },
    sizer: { height: 'Yükseklik', width: 'Genişlik' },
  },
  pages: {
    bar: {
      category: {
        active: 'Sayfalar',
        deleted: 'Silinenler',
        favorite: 'Favoriler',
      },
      filter: { search: { placeholder: 'Sayfa ara...' } },
    },
    card: { lastEditedAt: 'Son düzenleme', week: 'Hafta' },
    favorite: { title: 'Favori' },
  },
  passwordReset: {
    form: { button: 'Parola sıfırla', email: 'E-posta' },
    help: {
      login: 'Oturum açma',
      loginPost: ' sayfasına git',
      loginPre: '',
      needHelp: 'Yardıma mı ihtiyacınız var?',
    },
    info: {
      info: 'Lütfen kaydolmak için kullandığınız e-postanızı girin, size parola sıfırlamak için bir bağlantı içeren bir e-posta göndereceğiz.',
    },
    mailSent: {
      description:
        "Parola sıfırlama bağlantısı adresine gönderildi. Eğer parola sıfırlama mailini 'Gelen' klasöründe bulamıyorsanız, lütfen 'Spam' klasörünü kontrol ediniz.",
      message: 'Size bir e-posta gönderdik',
    },
    title: 'Parola sıfırla',
  },
  profile: {
    logout: { title: 'Çıkış yap' },
    report: { title: 'Rapor et' },
    settings: {
      account: {
        create: {
          button: 'Parola oluşturma bağlantısı gönder',
          info: 'Bu hesap Google veya Apple hesabı kullanılarak oluşturuldu ve bir parolası yok. Lütfen e-postanızı değiştirmeden önce bu hesap için bir parola oluşturun.',
          send: "Parola oluşturma bağlantısı adresine gönderildi. Eğer parola ayarlama mailini 'Gelen' klasöründe bulamıyorsanız, lütfen 'Spam' klasörünü kontrol ediniz.",
          title: 'Öncelikle bir parola oluşturun!',
        },
        delete: {
          button: 'Hesabı sil',
          description: 'Hesabınızı kalıcı olarak sil',
          dialog: {
            confirm: 'kalıcı olarak sil',
            description:
              'Hesabınızı kalıcı olarak silmek istiyorsanız lütfen "kalıcı olarak sil" yazınız.',
            title: 'Hesabımı sil',
          },
          title: 'Hesabımı sil',
        },
        email: {
          button: 'E-posta değiştir',
          dialog: { title: 'E-posta değiştir' },
          form: {
            current: "Mevcut e-postanız '{{email}}'",
            label: 'E-posta',
            placeholder: 'Yeni e-postanız',
            send: 'Doğrulama bağlantısı gönder',
            success:
              "Doğrulama bağlantısı adresine gönderildi. Eğer doğrulama mailini 'Gelen' klasöründe bulamıyorsanız, lütfen 'Spam' klasörünü kontrol ediniz.",
          },
          title: 'E-posta',
          verification: {
            success: 'Doğrulama bağlantısı adresine gönderildi',
            verified: 'Doğrulandı',
            verify: 'Doğrulanmadı, şimdi doğrula',
          },
          verify: { label: 'Mevcut parolanız' },
        },
        password: {
          button: 'Parola değiştir',
          description: 'Giriş yapmak için bir parola ayarların',
          dialog: { title: 'Parolamı değiştir' },
          form: {
            button: 'Parolamı değiştir',
            field: {
              confirmNewPassword: 'Yeni parola tekrarı',
              newPassword: 'Yeni parola',
              password: 'Mevcut parolanız',
            },
            placeholder: {
              confirmNewPassword: 'Yeni parolanızın tekrarı',
              newPassword: 'Yeni parolanızın',
              password: 'Mevcut parolanız',
            },
            success: 'Yeni parolanız ayarlandı',
          },
          title: 'Parola',
        },
        profile: { title: 'Profilim' },
        section: 'Hesabım',
        title: 'Hesap',
      },
      appearance: {
        rem: {
          description: 'Uygulamanın görsel boyutunu değiştirir',
          options: { large: 'Büyük', medium: 'Orta', small: 'Küçük' },
          title: 'Arayüz boyutu',
        },
        section: 'Görünüm',
        theme: {
          description: 'Uygulamanın nasıl görünüceğini değiştirir',
          options: { dark: 'Koyu', light: 'Açık', system: 'Sistem teması' },
          title: 'Arayüz teması',
        },
        title: 'Görünüm',
      },
      emailNotification: {
        announcements: {
          description: 'Özellik duyuruları, ipuçları ve ürün haberleri',
          title: 'Size göndereceğimiz...',
        },
        section: { email: 'E-posta' },
        title: 'E-posta & bildirimler',
      },
      languageRegion: {
        currency: {
          description: 'Kullandığınız para birimini değiştirir',
          title: 'Para birimi',
        },
        dayEndsAt: {
          description: 'Etkinlikler bu saate biter',
          title: 'Günün bitiş saati',
        },
        dayStartsAt: {
          description: 'Etkinlikler bu saate başlar',
          title: 'Günün başlangıç saati',
        },
        hourFormat: {
          description: 'Kullanmak istediğiniz saat formatını değiştirir',
          hour: 'Saat',
          title: '24 Saatlik zaman',
        },
        language: { description: 'Uygulamanın dilini değiştir', title: 'Dil' },
        section: { language: 'Dil', region: 'Bölge' },
        timeZone: {
          description: 'İçinde bulunduğunuz zaman dilimini değiştirir',
          title: 'Zaman dilimi',
        },
        title: 'Dil & bölge',
        weekStartsOn: {
          description: 'Takvimlerde hafta bu günde başlar',
          monday: 'Pazartesi',
          sunday: 'Pazar',
          title: 'Hafta başlangıç günü',
        },
      },
      privacy: { title: 'Gizlilik' },
      title: 'Ayarlar',
      widget: { title: 'Araç' },
    },
    upgrade: { title: 'Premium' },
  },
  resetPassword: {
    failed: {
      expired: {
        description:
          'Parola sıfırlama linkinin süresi doldu, lütfen yeni link isteyin',
        message: 'Parola sıfırlama linkinin süresi doldu',
      },
      invalid: {
        description:
          'Parola sıfırlama linki geçersiz, lütfen yeni link isteyin',
        message: 'Parola sıfırlama linki geçersiz',
      },
      passwordReset: 'Parola sıfırla',
    },
    form: {
      button: 'Parolamı sıfırla',
      confirmPassword: 'Yeni parola tekrarı',
      password: 'Yeni parola',
    },
    success: {
      description:
        'Parolanız başarılı bir şekilde ayarlandı, şimdi oturum açabilirsiniz',
      login: 'Oturum aç',
      message: 'Parolanız ayarlandı',
    },
    title: 'Parolamı sıfırla',
  },
  router: {
    home: 'Planlayıcılar',
    login: 'Giriş',
    notFound: '404 - Bulunamadı',
    passwordReset: 'Parola sıfırla',
    resetPassword: 'Parolamı sıfırla',
    sheet: 'Döküman',
    signup: 'Kayıt ol',
    verification: 'Hesap doğrulama',
  },
  shared: {
    module: {
      compact: { seeAll: 'Tümünü gör' },
      notFound: {
        seeAll: 'Tümünü gör',
        sticker: 'Etiket bulunamadı',
        template: 'Şablon bulunamadı',
        widget: 'Araç bulunamadı',
      },
    },
    report: {
      dialog: {
        additionally:
          'Ayrıca, her türlü talep veya güncelleme için sosyal medya hesaplarımız üzerinden bize ulaşabilirsiniz. Uygulamamızı daha da iyi hale getirmek için çalışırken desteğiniz ve katkınız için teşekkür ederiz!',
        description:
          'Uygulamamızı geliştirmemize yardımcı olduğunuz için teşekkür ederiz! Uygulamamız şu anda beta aşamasındadır ve geri bildirimleriniz geliştirme sürecimiz için çok önemlidir. Herhangi bir sorunla karşılaşırsanız veya yeni özellikler veya iyileştirmeler için önerileriniz varsa, lütfen düşüncelerinizi paylaşmak için aşağıdaki formu kullanın',
        success: {
          description: {
            feedback:
              'Geri bildiriminiz için teşekkür ederiz ve kısa süre içinde inceleyeceğiz.',
            report:
              'Raporunuz için teşekkür ederiz ve kısa süre içinde inceleyeceğiz.',
          },
          title: {
            feedback: 'Geri bildiriminiz başarıyla gönderildi.',
            report: 'Raporunuz başarıyla gönderildi.',
          },
        },
        title: { feedback: 'Geri bildirim', report: 'Rapor edin' },
      },
      form: {
        categories: {
          bug: {
            description: 'Düzeltebilmemiz için acığı veya hatayı açıklayın',
            title: 'Açık veya hata',
          },
          feature: {
            description:
              'Önermek istediğiniz özelliği veya iyileştirmeyi açıklayın',
            title: 'Özellik veya iyileştirme',
          },
          other: {
            description:
              'Görüşleriniz, kullanıcı deneyimini geliştirmemize ve uygulamamızı daha da iyi hale getirmemize yardımcı olur',
            title: 'Diğer',
          },
        },
        category: 'Kategori',
        description: 'Açıklama',
      },
    },
  },
  sidebar: {
    header: {
      toggle: { close: 'Kenar çubuğunu kapat', open: 'Kenar çubuğunu aç' },
    },
  },
  signup: {
    agreements: {
      and: ' ve ',
      info1:
        "'Google/Apple ile devam et' veya 'Kayıt ol' butonuna tıkladığınızda Nowadays'in",
      info2: ' okuyup, anladığınızı kabul etmektesiniz.',
      privacy: 'Gizlilik Politikası',
      terms: 'Koşullar',
    },
    form: {
      confirmPassword: 'Parola tekrarı',
      email: 'E-posta',
      firstName: 'İsim',
      lastName: 'Soyisim',
      password: 'Parola',
      signup: 'Kayıt ol',
    },
    redirectLogin: { isAccountExist: 'Hasabınız var mı?', login: 'Oturum aç' },
    title: 'Kayıt ol',
  },
  socket: { error: 'Bağlantı koptu, yeniden bağlanmaya çalışılıyor...' },
  sticker: {
    actions: { colors: 'Renkler' },
    card: {
      favorite: { add: 'Favorilere ekle', remove: 'Favorilerden çıkart' },
      menu: { favorite: { title: 'Favori' }, report: { title: 'Rapor et' } },
    },
    search: { placeholder: 'Etiket ara...' },
  },
  template: {
    action: {
      close: { title: 'Düzenlemeyi sonlandır' },
      saveAs: {
        form: {
          header: 'Sayfayı şablon olarak kaydet',
          isDefault: 'Varsayılan şablon olarak ayarla',
          title: 'Şablon ismi',
        },
        title: 'Sayfayı şablon olarak kaydet',
      },
    },
    card: {
      menu: {
        apply: {
          dialog: {
            confirm: 'Şablonu uygula',
            description:
              'Bu şablonu uygulamanız halinde mevcut sayfadaki veriler kaybolacaktır, devam etmek istiyor musunuz?',
          },
          title: 'Uygula',
        },
        duplicate: { title: 'Çoğalt' },
        edit: {
          form: { field: { title: 'Şablon ismi' }, title: 'Şablonu düzenle' },
        },
        editPage: { title: 'Düzenle' },
        info: { createdAt: 'Oluşturulma', updatedAt: 'Son güncelleme' },
        permanentlyDelete: {
          confirm: 'Şablonu kalıcı olarak silmek istediğinizden emin misiniz?',
          title: 'Sil',
        },
        report: { title: 'Rapor et' },
      },
      setAs: {
        default: 'Varsayılan şablon olarak ayarla',
        description:
          '*Sonraki oluşturulacak sayfalarda varsayılan olarak kullanılacak',
      },
    },
    search: { placeholder: 'Şablon ara...' },
  },
  text: {
    action: {
      align: { center: 'Ortala', left: 'Sola hizala', right: 'Sağa hizala' },
      backgroundColor: { title: 'Arkaplan rengi' },
      color: { title: 'Metin rengi' },
      decoration: { strikeThrough: 'Üzeri çizgili', underline: 'Altı çizgili' },
      size: { decrease: 'Artır', fontSize: 'Metin boyutu', increase: 'Azalt' },
      style: { italic: 'Italic' },
      weight: { title: 'Kalın' },
    },
    default: 'Buraya yazın',
  },
  verification: {
    email: { verifying: 'E-postanız doğrulanıyor, lütfen bekleyiniz' },
    failed: {
      expired: {
        description:
          'E-posta doğrulama linkinin süresi doldu, lütfen yeni link isteyin',
        message: 'E-posta doğrulama linkinin süresi doldu',
      },
      invalid: {
        description:
          'E-posta doğrulama linki geçersiz, lütfen yeni link isteyin',
        message: 'E-posta doğrulama linki geçersiz',
      },
    },
    success: {
      description: 'E-postanız doğrulandı, şimdi oturum açabilirsiniz',
      login: 'Oturum aç',
      message: 'E-postanız doğrulandı',
    },
    title: 'E-posta doğrulama',
  },
  widget: {
    card: {
      favorite: { add: 'Favorilere ekle', remove: 'Favorilerden çıkart' },
      menu: { favorite: { title: 'Favori' }, report: { title: 'Rapor et' } },
    },
    config: {
      contentChart: {
        feedType: { card: 'Kart', list: 'Liste' },
        platform: 'Platform',
        status: {
          analyzed: 'Analiz edildi',
          idea: 'Fikir',
          'in-progress': 'Çalışılıyor',
          planned: 'Planlandı',
          published: 'Yayınlandı',
          scheduled: 'Zamanlandı',
        },
        title: 'İçerik özeti',
        types: {
          feed: 'Akış',
          pillar: 'Konu',
          status: 'Durum',
          view: 'Gösterim',
        },
      },
      contentPlanner: {
        form: {
          caption: 'Alt yazı',
          content: 'İçerik',
          cover: 'Kapak',
          date: 'Tarihi',
          delete: {
            confirm: 'İçeriği silmek istediğinizden emin misiniz?',
            title: 'Sil',
          },
          hashtags: 'Etiketler',
          header: { add: 'İçerik ekle', edit: 'İçeriği düzenle' },
          links: 'Bağlantılar',
          metrics: 'Metrikler',
          notes: 'Notlar',
          pillar: 'Konu',
          platform: 'Platform',
          post: 'Tip',
          status: 'Durum',
          title: 'Başlık',
        },
        platforms: {
          blog: 'Blog',
          facebook: 'Facebook',
          instagram: 'Instagram',
          linkedin: 'LinkedIn',
          pinterest: 'Pinterest',
          tiktok: 'TikTok',
          x: 'X',
          youtube: 'YouTube',
        },
        posts: {
          blog: 'Blog',
          carousel: 'Slayt gösterisi',
          image: 'Fotoğraf',
          story: 'Hikaye',
          text: 'Metin',
          video: 'Video',
        },
        settings: { pillar: 'Konular' },
        statuses: {
          analyzed: 'Analiz edildi',
          canceled: 'İptal edildi',
          draft: 'Taslask',
          planned: 'Planlandı',
          published: 'Yayınlandı',
          scheduled: 'Zamanlandı',
        },
        title: 'İçerikler',
      },
      expenseChart: {
        category: 'Kategori',
        title: 'Harcama özeti',
        types: { breakdown: 'Ayrıntılı döküm', distribution: 'Dağılım' },
      },
      expenseTracker: {
        form: {
          amount: 'Tutar',
          category: 'Kategori',
          color: 'Renk',
          date: 'Tarih',
          delete: {
            confirm: 'Harcamayı silmek istediğinizden emin misiniz?',
            title: 'Sil',
          },
          header: { add: 'Harcama ekle', edit: 'Harcama düzenle' },
          notes: 'Notlar',
          subcategory: 'Alt kategori',
        },
        settings: {
          addCategory: 'Kategori ekle',
          category: 'Kategori',
          subcategory: 'Alt kategori',
          title: 'Kategoriler',
        },
        title: 'Harcamalar',
        total: 'Toplam',
      },
      focus: { placeholder: 'Odağınız...', title: 'Odak' },
      habitChart: {
        completed: 'Tamamlanan',
        habit: 'Alışkanlık',
        remaining: 'Geriye kalan',
        title: 'Alışkanlık özeti',
        types: { checkbox: 'Onay kutusu', goal: 'Hedef' },
        uncompleted: 'Tamamlanmayan',
      },
      habitTracker: {
        form: {
          delete: {
            confirm: 'Alışkanlığı silmek istediğinizden emin misiniz?',
            title: 'Sil',
          },
          endDate: 'Tarihine kadar',
          goal: 'Hedef',
          header: { add: 'Alışkanlık ekle', edit: 'Alışkanlık düzenle' },
          placeholder: { goal: 'Hedef sayısı' },
          recurrence: 'Tekrar',
          recurrences: { never: 'Asla', until: 'Tarihine kadar' },
          repeatOn: 'Tekrarla',
          startDate: 'Başlangıç',
          title: 'Başlık',
          type: 'Takip tipi',
          types: { checkbox: 'Onay kutusu', goal: 'Hedef' },
        },
        placeholder: 'Alışkanlıklar',
        title: 'Alışkanlıklar',
      },
      intention: { placeholder: 'Amacınız...', title: 'Amaç' },
      moodChart: { title: 'Ruh hali özeti' },
      moodTracker: {
        moods: {
          Bored: 'Sıkılmış surat',
          Cool: 'Havalı surat',
          Cry: 'Ağlayan surat',
          Laugh: 'Haykıran surat',
          Love: 'Mutlu surat',
          Sad: 'Üzgün surat',
          Smile: 'Gülen surat',
        },
        title: 'Ruh hali',
      },
      pomodoro: {
        mode: {
          focus: 'Odaklan',
          longBreak: 'Uzun mola',
          shortBreak: 'Kısa mola',
        },
        reset: 'Sıfırla',
        settings: {
          cycle: 'Pomodoro döngüsü',
          focus: 'Odaklanma süresi',
          longBreak: 'Uzun mola süresi',
          min: 'Dakika',
          mode: 'Mod',
          modes: { auto: 'Otomatik', manual: 'Manuel' },
          mute: 'Sessiz',
          remainCycle: 'Kalan döngü',
          shortBreak: 'Kısa mola süresi',
        },
        start: 'Başlat',
        stop: 'Durdur',
        title: 'Pomodoro',
      },
      priorityList: { placeholder: 'Öncelikler grubu', title: 'Öncelikler' },
      reflection: { placeholder: 'Düşünceniz...', title: 'Düşünce' },
      scheduleTable: {
        cell: { halfHour: '30 dk', hour: 'saat' },
        form: {
          color: 'Renk',
          delete: {
            confirm: 'Etkinliği silmek istediğinizden emin misiniz?',
            title: 'Sil',
          },
          description: 'Açıklama',
          endDate: 'Bitiş tarihi',
          endTime: 'Bitiş zamanı',
          header: { add: 'Etkinlik ekle', edit: 'Etkinlik düzenle' },
          recurrence: 'Tekrar',
          recurrences: { never: 'Asla', until: 'Tarihine kadar' },
          repeatOn: 'Tekrarla',
          startDate: 'Tarih',
          startTime: 'Başlangıç zamanı',
          title: 'Başlık',
          type: 'Tipi',
          types: {
            'all-day': 'Tüm gün',
            appointment: 'Randevu',
            event: 'Etkinlik',
          },
        },
        title: 'Etkinlikler',
      },
      textBox: { placeholder: 'Buraya yazınız...', title: 'Metin kutusu' },
      toDoChart: {
        completed: 'Tamamlanan',
        title: 'Yapılacak özeti',
        uncompleted: 'Tamamlanmayan',
        ungrouped: 'Gurupsuz',
      },
      toDoList: { placeholder: 'Yapılacaklar grubu', title: 'Yapılacaklar' },
      waterIntakeChart: { drunk: 'İçilen sayı', title: 'Su içme özeti' },
      waterIntakeTracker: {
        settings: { cupCount: 'Adet', cupType: 'Tip' },
        title: 'Su içme',
      },
      weatherChart: { title: 'Hava durumu özeti' },
      weatherTracker: {
        title: 'Hava durumu',
        weathers: {
          Cloudy: 'Bulutlu',
          PartlySunny: 'Parçalı güneşli',
          Rainbow: 'Gökkuşağı',
          Rainy: 'Yağmurlu',
          Snowy: 'Karlı',
          Sunny: 'Güneşli',
          Windy: 'Rüzgarlı',
        },
      },
    },
    schedule: { table: { allday: 'Tüm gün' } },
    search: { placeholder: 'Araç ara...' },
    settings: {
      types: {
        'content-planner': 'İçerik planlayıcı',
        'expense-tracker': 'Harcama takipçisi',
        'meal-planner': 'Öğün planlayıcı',
        pomodoro: 'Pomodoro',
        'water-intake-tracker':
          '<br class="Apple-interchange-newline">Su içme takipçisi',
      },
    },
  },
};
