'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Button from '../../../button/button/Button';
import { useRegion } from '../../../region/RegionContext';
import CalendarBase from '../../calendar-base/CalendarBase';
import { CalendarMonthProps } from './CalendarMonth.types';

const CalendarMonth: React.FC<CalendarMonthProps> = ({
  month,
  color,
  variant,
  className,
  children,
  onClick,
  monthFormat = 'MMM',
  ...props
}) => {
  const { format } = useRegion();

  return (
    <CalendarBase.Month month={month}>
      {({ month, onChanged, isSelected, isThisMonth }) => (
        <Button
          onClick={(e) => {
            onChanged(month);
            onClick && onClick(e);
          }}
          color={
            color
              ? typeof color === 'function'
                ? color({ isSelected, isThisMonth })
                : color
              : isSelected
                ? 'silent'
                : 'primary'
          }
          variant={
            variant
              ? typeof variant === 'function'
                ? variant({ isSelected, isThisMonth })
                : variant
              : isSelected
                ? 'contained'
                : 'flat'
          }
          className={classNames(
            styles.button,
            isSelected && styles.selected,
            typeof className === 'function'
              ? className({ isSelected, isThisMonth })
              : className,
          )}
          {...props}
        >
          {children
            ? children({ month, onChanged })
            : format(month, monthFormat)}
        </Button>
      )}
    </CalendarBase.Month>
  );
};

const styles = {
  button: classes('duration-0'),
  selected: classes('font-semibold'),
};

export default CalendarMonth;
