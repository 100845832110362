import { Routes } from '@nowadays/admin/shared';
import { Language, Namespaces } from '@nowadays/base/locales';
import { DeviceTheme, UserRole } from '@nowadays/base/types';
import {
  InterfaceProvider,
  NotificationProvider,
  RegionProvider,
  RouteMapper,
  SnackbarProvider,
} from '@nowadays/ui/components';
import { I18NProvider } from '@nowadays/ui/i18n/client';
import {
  AuthProvider,
  Fallback,
  LoadingProvider,
  LoadingWrapper,
  NetworkListener,
  WaitFor,
} from '@nowadays/ui/shared';
import { store } from '@nowadays/ui/store';
import { getLocalStorage } from '@nowadays/ui/utils';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  const roles: UserRole[] = [UserRole.Admin];

  const waitFor: WaitFor[] = ['user'];

  const namespaces: Namespaces[] = ['base', 'ui', 'admin'];

  const theme = getLocalStorage<DeviceTheme>('theme');

  const language = getLocalStorage<Language>('language');

  return (
    <BrowserRouter>
      <Provider store={store}>
        <LoadingProvider waitFor={waitFor}>
          <InterfaceProvider theme={theme}>
            <I18NProvider language={language} namespaces={namespaces}>
              <ErrorBoundary FallbackComponent={Fallback}>
                <RegionProvider language={language}>
                  <AuthProvider roles={roles}>
                    <NotificationProvider>
                      <SnackbarProvider>
                        <NetworkListener />
                        <LoadingWrapper />
                        <RouteMapper routes={Routes} />
                      </SnackbarProvider>
                    </NotificationProvider>
                  </AuthProvider>
                </RegionProvider>
              </ErrorBoundary>
            </I18NProvider>
          </InterfaceProvider>
        </LoadingProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
