'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment } from 'react';

import Divider from '../../../divider/Divider';
import Icon from '../../../icon/Icon';
import { TValues } from '../Combobox.types';
import ComboboxOption from '../combobox-option/ComboboxOption';
import { ComboboxOptionsProps } from './ComboboxOptions.types';

const ComboboxOptions = <T extends TValues>({
  options,
  hideIcon,
  hideTick,
}: ComboboxOptionsProps<T>) => {
  return options?.map(
    (option, key) =>
      !option.hidden && (
        <Fragment key={key}>
          <ComboboxOption
            key={option.key}
            option={option}
            className={styles.option}
          >
            {({ selected }) => (
              <Fragment>
                {!hideIcon && (
                  <div className={styles.icon}>
                    {!hideTick && selected ? (
                      <Icon name='Tick' className={styles.tickIcon} />
                    ) : (
                      <span className={styles.empty} />
                    )}
                  </div>
                )}
                <div className={classNames(styles.body)}>
                  {option?.children || option.key}
                </div>
              </Fragment>
            )}
          </ComboboxOption>
          {option.divide && <Divider />}
        </Fragment>
      ),
  );
};

const styles = {
  option: classes('flex', 'items-center', 'p-2', 'gap-2'),
  body: classes('flex-1'),
  icon: classes('grid', 'place-content-center'),
  empty: classes('w-4'),
  tickIcon: classes('w-4', 'h-4', 'text-skin-muted'),
};

export default React.memo(ComboboxOptions);
