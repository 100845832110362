'use client';

import { BADGE_COLORS } from '@nowadays/base/types';
import { classes, classNames } from '@nowadays/ui/utils';

import { BadgeProps } from './Badge.types';

const Badge: React.FC<BadgeProps> = ({
  title,
  color,
  border,
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        color && styles.background,
        border && styles.border,
        color && BADGE_COLORS.text[color],
        color && BADGE_COLORS.background[color],
        border && BADGE_COLORS.border[color],
        className,
      )}
    >
      <span>{title || children}</span>
    </div>
  );
};

const styles = {
  root: classes('px-1', 'w-fit', 'rounded-md', 'text-sm'),
  background: classes('bg-opacity-10'),
  border: classes('border', 'border-opacity-30'),
};

export default Badge;
