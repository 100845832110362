import { CopyPresetPlannerParams, Planner } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from '../planner/Planner.endpoints';

export const copyPresetPlanner = (builder: Builder) =>
  builder.mutation<Planner, CopyPresetPlannerParams>({
    query: ({ id }) => ({
      url: `/preset-planner/copy/${id}`,
      method: 'POST',
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'planners',
            undefined,
            (draft) => {
              draft.unshift(data);
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
