import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgTextItalic = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M17 5a1 1 0 1 0 0-2zM9 3a1 1 0 1 0 0 2zm6 18a1 1 0 1 0 0-2zm-8-2a1 1 0 1 0 0 2zm6.992-14.876a1 1 0 1 0-1.984-.248zm-3.984 15.752a1 1 0 0 0 1.984.248zM17 3H9v2h8zm-2 16H7v2h8zM12.008 3.876l-2 16 1.984.248 2-16z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTextItalic);
export default ForwardRef;
