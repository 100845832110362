'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import ButtonIcon from './button-icon/ButtonIcon';
import ButtonSpinner from './button-spinner/ButtonSpinner';
import { bgStyles } from './button-styles/Button.bg';
import { bgHoverStyles } from './button-styles/Button.bg.hover';
import { bgHoverActiveStyles } from './button-styles/Button.bg.hover.active';
import { textStyles } from './button-styles/Button.text';
import { textHoverStyles } from './button-styles/Button.text.hover';
import { textHoverActiveStyles } from './button-styles/Button.text.hover.active';
import {
  ButtonColor,
  ButtonVariant,
  InnerButtonBaseProps,
} from './ButtonBase.types';

const ButtonBase = <T extends React.ElementType = 'button'>(
  {
    as,
    children,
    onClick,
    color = 'primary',
    variant = 'flat',
    loading,
    disabled,
    readOnly,
    hidden,
    className,
    ...props
  }: InnerButtonBaseProps<T>,
  ref: AsRef<T>,
) => {
  const Component = as || 'button';
  const [executing, setExecuting] = useState<boolean>(false);
  const innerLoading = loading || executing;
  const isDisabled = innerLoading || disabled || false;

  const handleClick = async (event: React.MouseEvent<Element, MouseEvent>) => {
    if (innerLoading) {
      return event.preventDefault();
    }
    if (onClick) {
      if (onClick.constructor.name === 'AsyncFunction') {
        setExecuting(true);
        await onClick(event);
        setExecuting(false);
      } else {
        onClick(event);
      }
    }
  };

  if (hidden) {
    return <span />;
  }

  return (
    <Component
      ref={ref}
      onClick={handleClick}
      disabled={disabled}
      className={classNames(
        styles.default(variant, color),
        !isDisabled && styles.enabled(variant, color),
        isDisabled && styles.disabled,
        readOnly && styles.readOnly,
        hidden && styles.hidden,
        className,
      )}
      {...props}
    >
      {typeof children === 'function'
        ? children({ loading: innerLoading })
        : children}
    </Component>
  );
};

const styles = {
  default: (variant: ButtonVariant, color: ButtonColor) =>
    classes(
      'text-sm',
      'font-sans',
      'flex',
      'items-center',
      'justify-center',
      'gap-2',
      'min-w-max',
      'overflow-hidden',
      'transition-colors',
      'duration-200',
      'cursor-pointer',
      'font-medium',
      bgStyles[color][variant],
      textStyles[color][variant],
    ),
  disabled: classes(
    'opacity-70',
    'cursor-default',
    'disabled:opacity-70',
    'disabled:cursor-default',
  ),
  readOnly: classes(
    'opacity-100',
    'disabled:opacity-100',
    'pointer-events-none',
  ),
  enabled: (variant: ButtonVariant, color: ButtonColor) =>
    classes(
      'focus-ring',
      bgHoverActiveStyles[color][variant],
      textHoverActiveStyles[color][variant],
      !isMobile && bgHoverStyles[color][variant],
      !isMobile && textHoverStyles[color][variant],
    ),
  hidden: classes('hidden'),
};

export default Object.assign(React.forwardRef(ButtonBase), {
  Spinner: ButtonSpinner,
  Icon: ButtonIcon,
});
