import {
  TabList as HeadlessTabList,
  TabListProps as HeadlessTabListProps,
} from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';

const TabBaseList: React.FC<HeadlessTabListProps> = ({
  as = 'div',
  className,
  ...props
}) => {
  return (
    <HeadlessTabList
      as={as}
      className={classNames(
        styles.root,
        typeof className === 'string' && className,
      )}
      {...props}
    />
  );
};

const styles = {
  root: classes('bg-skin-primary', 'p-1', 'rounded-lg'),
};

export default TabBaseList;
