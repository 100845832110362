import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import Feedback from '../../../feedback/Feedback';

const ImageSliderNotFound = () => {
  const translate = useTranslate();

  return (
    <div className={styles.root}>
      <Feedback
        color='muted'
        title={translate('ui.components.imageSlider.notFound')}
        border={false}
        className={styles.feedback}
        icon={{ name: 'Image', className: styles.icon }}
      />
    </div>
  );
};

const styles = {
  root: classes(
    'w-full',
    'flex',
    'items-center',
    'justify-center',
    'aspect-[1.5]',
  ),
  icon: classes('w-6', 'h-6'),
  feedback: classes(
    'text-md',
    'bg-transparent',
    'rounded-none',
    'items-center',
    'justify-center',
  ),
};

export default ImageSliderNotFound;
