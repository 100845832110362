import { Button, NotificationItem } from '@nowadays/ui/components';
import { classes } from '@nowadays/ui/utils';

export const accountNotFound = (
  translate: Translate,
  onSignupClick?: () => void,
): NotificationItem => {
  return {
    title: translate('ui.shared.auth.notifications.accountNotFound.message'),
    color: 'red',
    description: translate(
      'ui.shared.auth.notifications.accountNotFound.description',
    ),
    content: onSignupClick
      ? ({ close }) => (
          <div className={styles.container}>
            <span>
              {translate(
                'ui.shared.auth.notifications.accountNotFound.createAccount',
              )}
            </span>
            <Button
              variant='contained'
              color='accent'
              onClick={() => {
                onSignupClick();
                close();
              }}
            >
              {translate('ui.shared.auth.notifications.accountNotFound.signup')}
            </Button>
          </div>
        )
      : undefined,
  };
};

const styles = {
  container: classes(
    'flex',
    'text-xs',
    'items-center',
    'text-skin-muted',
    'justify-between',
  ),
};
