import { StickerPackItem, StickerPackItemsParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { stickerPackItemEndpoints } from './StickerPackItem.endpoints';

export const stickerPackItems = (builder: Builder) =>
  builder.query<StickerPackItem[], StickerPackItemsParams>({
    query: ({ pack }) => `/sticker-pack-item/items/${pack}`,
    providesTags: (res, err, args) => [
      { type: 'StickerPackItems', id: args.pack },
    ],
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        data.forEach(async (i) => {
          await dispatch(
            stickerPackItemEndpoints.util.upsertQueryData(
              'stickerPackItem',
              { sticker: i.id },
              i,
            ),
          );
        });
      } catch (err) {
        console.error(err);
      }
    },
  });
