import { Page, PagesParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const pages = (builder: Builder) =>
  builder.query<Page[], PagesParams>({
    query: (params) => ({ url: '/page/pages/', params }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        for (let i = 0; i < data.length; i++) {
          const page = data[i];

          dispatch(
            pageEndpoints.util.upsertQueryData(
              'datePage',
              { planner, period: page.period, date: page.date },
              page,
            ),
          );

          dispatch(
            pageEndpoints.util.upsertQueryData('page', { id: page.id }, page),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
