'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Icon from '../../../icon/Icon';
import { ListboxSelectAllProps } from './ListboxSelectAll.types';

const ListboxSelectAll: React.FC<ListboxSelectAllProps> = ({
  className,
  disabled,
  handleSelectAll,
}) => {
  const translate = useTranslate();

  const onHandleSelectAll = () => {
    if (!disabled) {
      handleSelectAll();
    }
  };

  return (
    <div
      onClick={onHandleSelectAll}
      className={classNames(
        styles.root,
        disabled && styles.disabled,
        className,
      )}
    >
      <Icon name='Tick' className={styles.icon} />
      <span>{translate('ui.components.inputs.listbox.selectAll')}</span>
    </div>
  );
};

const styles = {
  root: classes(
    'p-2',
    'cursor-pointer',
    'text-skin-accent',
    'flex',
    'items-center',
    'space-x-2',
  ),
  icon: classes('w-4', 'h-4'),
  disabled: classes('opacity-70', 'cursor-default'),
};

export default ListboxSelectAll;
