import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSunny = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 14.766a2.766 2.766 0 1 0 0-5.533 2.766 2.766 0 0 0 0 5.533M12 3v1.385m0 15.23V21m9-9h-1.385m-15.23 0H3m15.37-6.37-.984.984M6.614 17.386l-.983.983m12.739.001-.984-.984M6.614 6.614 5.63 5.63'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSunny);
export default ForwardRef;
