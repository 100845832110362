'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import {
  endOfWeek,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isToday as isTodayFn,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';

import { useRegion } from '../../../region/RegionContext';
import { useCalendarBase } from '../calendar-base-context/CalendarBaseContext';
import { CalendarBaseDayProps } from './CalendarBaseDay.types';

const CalendarBaseDay: React.FC<CalendarBaseDayProps> = ({
  day,
  month,
  children,
  hideDay,
  formatStr,
}) => {
  const {
    disabledBefore,
    disabledAfter,
    currentDay,
    currentWeek,
    currentMonth,
    currentPeriod,
    handleDayChanged,
    handlePeriodChanged,
  } = useCalendarBase();
  const { format, formatOptions } = useRegion();

  const onChanged = (day: Date) => {
    handleDayChanged(day);
    handlePeriodChanged('daily');
  };

  const today = startOfToday();

  const active = day || currentDay;

  const isSelected = currentPeriod === 'daily' && isSameDay(active, currentDay);

  const isToday = isTodayFn(active);

  const isDiffirentMonth = !isSameMonth(active, month || currentMonth);

  const isWeekSelected = useMemo(
    () =>
      currentPeriod === 'weekly' &&
      isSameWeek(active, currentWeek, formatOptions),
    [active, currentWeek, currentPeriod],
  );

  const isStartOfWeek = isSameDay(active, startOfWeek(active, formatOptions));

  const isEndOfWeek = isSameDay(active, endOfWeek(active, formatOptions));

  const isDisabled = useMemo(
    () =>
      (typeof disabledBefore === 'boolean'
        ? isBefore(day, today)
        : isBefore(day, disabledBefore)) ||
      (typeof disabledAfter === 'boolean'
        ? isAfter(day, today)
        : isAfter(day, disabledAfter)),
    [day, today, disabledBefore, disabledAfter],
  );

  const formatted = (formatStr && format(active, formatStr)) || undefined;

  return typeof children === 'function'
    ? children({
        day: active,
        formatted,
        component: (
          <div className={styles.label}>
            <span>{formatted}</span>
            {!hideDay && (
              <span className={classNames(styles.day, isToday && styles.today)}>
                {format(active, 'dd')}
              </span>
            )}
          </div>
        ),
        onChanged,
        isDisabled,
        isSelected,
        isToday,
        isDiffirentMonth,
        isWeekSelected,
        isStartOfWeek,
        isEndOfWeek,
      })
    : children;
};

const styles = {
  label: classes('space-x-1', 'text-skin-muted', 'px-0.5', 'text-3xs'),
  day: classes('leading-3'),
  today: classes(
    'bg-skin-red',
    'text-skin-light',
    'rounded-full',
    'w-5',
    'h-5',
    'font-medium',
    'inline-grid',
    'place-content-center',
  ),
};

export default CalendarBaseDay;
