import { Button, Divider, Popover } from '@nowadays/ui/components';
import { useAuth } from '@nowadays/ui/shared';
import { classes } from '@nowadays/ui/utils';

import LanguageSelection from './components/language-selection/LanguageSelection';
import LogoutButton from './components/logout-button/LogoutButton';
import ThemeSelection from './components/theme-selection/ThemeSelection';
import Title from './components/title/Title';

const SidebarFooter = () => {
  const {
    user: { firstName, lastName },
  } = useAuth();

  return (
    <Popover>
      <Popover.Button as={Button} name='AvatarSquare' className={styles.button}>
        <span className={styles.abbreviated}>
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </span>
        <span>{[firstName, lastName].join(' ')}</span>
      </Popover.Button>
      <Popover.Backdrop />
      <Popover.Panel
        floating={{ placement: 'top-start' }}
        className={styles.panel}
      >
        <Title />
        <Divider />
        <ThemeSelection />
        <Divider />
        <LanguageSelection />
        <Divider />
        <LogoutButton />
      </Popover.Panel>
    </Popover>
  );
};

const styles = {
  button: classes('p-2', 'w-full', 'justify-start'),
  panel: classes('w-72', 'space-y-2', 'p-3'),
  abbreviated: classes(
    'bg-skin-accent',
    'text-skin-light',
    'font-semibold',
    'p-3',
    'rounded-md',
  ),
};

export default SidebarFooter;
