import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSmile = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M7.43 13.385c.693 2.492 3.462 4.015 5.954 3.323 1.524-.554 2.77-1.8 3.185-3.323M8.885 9.923a.346.346 0 1 1 0-.692m0 .692a.346.346 0 1 0 0-.692m6.23.692a.346.346 0 0 1 0-.692m0 .692a.346.346 0 0 0 0-.692'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSmile);
export default ForwardRef;
