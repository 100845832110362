import { WidgetSetItem } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { widgetSetItemEndpoints } from './WidgetSetItem.endpoints';

export const widgetSetItems = (builder: Builder) =>
  builder.query<WidgetSetItem[], string>({
    query: (set) => `/widget-set-item/items/${set}`,
    providesTags: (res, err, set) => [{ type: 'WidgetSetItems', id: set }],
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        data.forEach(async (i) => {
          await dispatch(
            widgetSetItemEndpoints.util.upsertQueryData(
              'widgetSetItem',
              { widget: i.id },
              i,
            ),
          );
        });
      } catch (err) {
        console.error(err);
      }
    },
  });
