'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import IconButton from '../icon-button/IconButton';
import { AdornmentButtonProps } from './AdornmentButton.types';

const AdornmentButton: React.FC<AdornmentButtonProps> = (
  { className, icon = {}, ...props },
  ref: React.Ref<HTMLButtonElement>,
) => {
  return (
    <IconButton
      ref={ref}
      color='silent'
      variant='text'
      {...props}
      className={classNames(styles.button, className)}
      icon={{ className: classNames(styles.icon, icon.className) }}
    />
  );
};

const styles = {
  button: classes('p-1.5'),
  icon: classes('w-5', 'h-5'),
};

export default React.memo(React.forwardRef(AdornmentButton));
