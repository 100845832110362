'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import ActionButtons from '../../../button/action-buttons/ActionButtons';
import { useDialog } from '../dialog-context/DialogContext';
import { DialogFooterProps } from './DialogFooter.types';

const DialogFooter: React.FC<DialogFooterProps> = ({
  className,
  children,
  confirm,
  cancel,
  actions,
}) => {
  const { onClose } = useDialog();

  return (
    <div className={classNames(styles.root, className)}>
      <ActionButtons
        actions={actions}
        confirm={confirm}
        cancel={{
          onClick: onClose,
          ...cancel,
        }}
      />
      {children}
    </div>
  );
};

const styles = {
  root: classes('pt-2', 'flex', 'justify-end', 'gap-2'),
};

export default DialogFooter;
