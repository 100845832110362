import { Language } from '@nowadays/base/locales';
import { Radio, useRegion } from '@nowadays/ui/components';
import { useLanguage, useTranslate } from '@nowadays/ui/i18n/client';
import { classes, setLocalStorage } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import SelectionTitle from '../selection-title/SelectionTitle';

export type AdminLanguage = Extend<Language, 'en-US' | 'tr-TR'>;

export const ADMIN_LANGUAGES: AdminLanguage[] = ['en-US', 'tr-TR'];

const LanguageSelection = () => {
  const translate = useTranslate();
  const { changeSettings } = useRegion();
  const { language, changeLanguage } = useLanguage();

  const handleChange = (value: AdminLanguage) => {
    changeLanguage(value);
    changeSettings({ language: value });
    setLocalStorage<Language>('language', value);
  };

  const value = ADMIN_LANGUAGES.find((lng) => lng === language);

  return (
    <Fragment>
      <SelectionTitle
        title={translate('admin.shared.sidebar.footer.language.title')}
      />
      <Radio value={value} onChange={handleChange} className={styles.root}>
        {ADMIN_LANGUAGES.map((lng, key) => (
          <Radio.Option key={key} value={lng} className={styles.option}>
            {translate(`base.language.constant.${lng}`)}
          </Radio.Option>
        ))}
      </Radio>
    </Fragment>
  );
};

const styles = {
  root: classes('flex', 'space-x-4'),
  option: classes('flex', 'items-center', 'px-2', 'justify-center'),
};

export default LanguageSelection;
