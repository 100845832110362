import { Tab as HeadlessTab } from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';

import Button from '../../../button/button/Button';
import { TabBaseItemProps } from './TabBaseItem.types';

const TabBaseItem: React.FC<TabBaseItemProps> = ({
  active = 'base',
  passive = 'muted',
  className,
  ...props
}) => {
  return (
    <HeadlessTab as='span' className={styles.root}>
      {({ selected }) => (
        <Button
          color={selected ? active : passive}
          variant={selected ? 'contained' : 'text'}
          className={classNames(
            styles.button,
            selected && styles.selected,
            className,
          )}
          {...props}
        />
      )}
    </HeadlessTab>
  );
};

const styles = {
  root: classes('w-full'),
  button: classes('py-1', 'px-4', 'rounded-md', 'w-full'),
  selected: classes('font-medium'),
};

export default TabBaseItem;
