'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import Checkbox from '../../../input/checkbox/Checkbox';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormCheckboxProps } from './FormCheckbox.types';

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  field,
  label,
  disabled,
  input,
  container,
  ...props
}) => {
  const { register, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, container?.className)}>
        {label && (
          <FieldLabel
            label={label}
            htmlFor={field}
            className={classNames(styles.label, isDisable && styles.disabled)}
          />
        )}
        <Checkbox
          id={field}
          key={field}
          disabled={isDisable}
          input={{ error: isError, disable: isDisable, ...input }}
          {...props}
          {...register(field)}
        />
      </div>
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
  container: classes('flex', 'items-center', 'gap-1'),
  label: classes('select-none', 'cursor-pointer'),
  disabled: classes('cursor-default'),
};

export default FormCheckbox;
