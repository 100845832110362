export const ui = {
  components: {
    button: {
      action: {
        cancel: { cancel: 'İptal', close: 'Kapat', no: 'Hayır' },
        confirm: {
          add: 'Ekle',
          confirm: 'Onayla',
          create: 'Oluştur',
          ok: 'Tamam',
          save: 'Kaydet',
          send: 'Gönder',
          update: 'Güncelle',
          yes: 'Evet',
        },
      },
    },
    inputs: {
      chip: { add: 'Ekle' },
      combobox: {
        clear: 'Temizle',
        clearAll: 'Tümünü temizle',
        loading: 'Yükleniyor',
        notFound: 'Bulunamadı',
        selectAll: 'Tümünü seç',
      },
      file: {
        maxFiles: 'Maksimum seçebileceğiniz dosya sayısı {{max}}',
        maxSize: "{{size}} MB'dan büyük dosya seçemezsiniz",
        multiple: 'Dosyaları seç veya sürükle',
        single: 'Dosyayı seç veya sürükle',
      },
      image: {
        multiple: 'Görselleri seç veya sürükle',
        single: 'Görsel seç veya sürükle',
      },
      listbox: {
        clear: 'Temizle',
        clearAll: 'Tümünü temizle',
        notFound: 'Bulunamadı',
        selectAll: 'Tümünü seç',
      },
      richText: {
        clearMarks: 'İşaretleri temizle',
        clearNodes: 'Düğümleri temizle',
        heading: 'Başlık',
        headings: {
          h1: 'Başlık 1',
          h2: 'Başlık 2',
          h3: 'Başlık 3',
          h4: 'Başlık 4',
          h5: 'Başlık 5',
          h6: 'Başlık 6',
        },
        horizontalRule: 'Yatay bölme',
        image: { title: 'Görsel ekle' },
        link: { remove: 'Kaldır', title: 'Bağlantı' },
        node: {
          image: {
            alt: 'Alt metin',
            altMissing: 'Alt metin eksik',
            editAlt: 'Düzenle',
            height: 'Boy',
            save: 'Kaydet',
            saveSize: 'Kaydet',
            weight: 'En',
          },
        },
      },
      switch: {
        'active-passive': { false: 'Pasif', true: 'Aktif' },
        'enable-disable': { false: 'Devredışı', true: 'Devrede' },
        'open-closed': { false: 'Kapalı', true: 'Açık' },
        'true-false': { false: 'Yanlış', true: 'Doğru' },
        'yes-no': { false: 'Hayır', true: 'Evet' },
      },
    },
    calendar: {
      datepicker: { weeks: { week: 'H' } },
      table: { allday: 'Tüm gün' },
    },
    captcha: {
      and: ' ve ',
      googleTerms: 'Google koşulları',
      post: ' uygulanmaktadır.',
      pre: 'Bu site reCAPTCHA tarafından korunmaktadır ve ',
      privacyPolicy: 'gizlilik politikası',
    },
    chart: { noData: 'Veri yok' },
    colorPicker: {
      current: 'Mevcut renkler',
      preset: 'Renkler',
      select: 'Seç',
      transparent: 'Transparan',
    },
    form: { action: { cancel: { cancel: 'İptal', no: 'Hayır' } } },
    imageSlider: { notFound: 'Görseller bulunamadı' },
    indicator: {
      upload: {
        failed: 'Başarısız',
        loaded: 'Yüklendi',
        uploading: 'Yükleniyor',
      },
    },
    notFound: {
      description:
        'Aradığınız sayfa taşınmış, ismi değişmiş veya geçici olarak erişilemiyor olabilir.',
      home: 'Anasayfaya dön',
      title: '404 - Sayfa bulunamadı',
    },
    table: {
      body: { notFound: { text: 'Veri yok' } },
      caption: { filter: { text: 'Ara...' }, refresh: { text: 'Yenile' } },
      pagination: {
        button: {
          end: 'Son sayfa',
          next: 'İleri',
          previous: 'Geri',
          start: 'İlk sayfa',
        },
        info: { goto: 'Sayfaya git:', page: 'Sayfa' },
        shown: { title: 'Gösterilen' },
      },
    },
    wentWrong: {
      description:
        'Maalesef teknik bir sorun yaşıyoruz. Lütfen sayfayı yenileyin veya anasayfaya dönün.',
      home: 'Anasayfaya dön',
      title: 'Bir şeyler ters gitti',
      try: 'Tekrar dene',
    },
  },
  shared: {
    auth: {
      notifications: {
        accountAlreadyExists: {
          description: 'Bu e-postaya bağlı zaten bir hesap mevcut',
          message: 'Hesap zaten mevcut',
          signup: 'Oturum aç',
        },
        accountDisabled: {
          description: 'Lütfen bizimle iletişime geçiniz:',
          message: 'Hesabınız devre dışı bırakıldı',
        },
        accountNotFound: {
          createAccount: 'Hesabınız yok mu?',
          description: 'Bu e-postaya bağlı hesap bulunamadı',
          message: 'Hesap bulunamadı',
          signup: 'Kayıt ol',
        },
        invalidCredentials: {
          description:
            'Lütfen giriş bilgilerinizi kontrol edip tekrar deneyiniz',
          message: 'Giriş bilgileriniz hatalı',
          passwordReset: 'Parolanızı mı unuttunuz?',
          resetPassword: 'Parola sıfırla',
        },
        socialEmailError: {
          description:
            'Bu hesap e-posta ile kayıtlı değil, lütfen başka yöntemlerle oturum açınız',
          message: 'Hesap oluşturma hatası',
        },
        somethingWentWrong: {
          description:
            'Bir şeyler ters gitti, lütfen data sonra tekrar deneyiniz',
          message: 'Bir şeyler ters gitti',
        },
      },
    },
    networkListener: {
      accessError: {
        description: 'Şu anda sunucularımıza erişilemiyor',
        title: 'Erişim hatası',
      },
      intervalServerError: {
        title:
          'Beklenmedik bir hata meydana geldi, lütfen daha sonra tekrar deneyiniz.',
      },
      networkError: {
        description: 'Lütfen internet bağlantınızı kontrol ediniz',
        title: 'Bağlantı problemi',
      },
      requestError: { title: 'İsteğiniz gerçekleştirilemedi' },
    },
    socialButtons: {
      withApple: 'Apple ile devam Et',
      withGoogle: 'Google ile devam Et',
    },
  },
};
