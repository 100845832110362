import { baseApi } from '../base/query/Api';
import { contentChartDatum } from './ContentChartDatum.api';
import { contentPlannerDatum } from './ContentPlannerDatum.api';
import { expenseChartDatum } from './ExpenseChartDatum.api';
import { expenseTrackerDatum } from './ExpenseTrackerDatum.api';
import { fileBoxDatum } from './FileBoxDatum.api';
import { focusDatum } from './FocusDatum.api';
import { habitChartDatum } from './HabitChartDatum.api';
import { habitTrackerDatum } from './HabitTrackerDatum.api';
import { imageBoxDatum } from './ImageBoxDatum.api';
import { intentionDatum } from './IntentionDatum.api';
import { mealPlannerDatum } from './MealPlannerDatum.api';
import { moodChartDatum } from './MoodChartDatum.api';
import { moodTrackerDatum } from './MoodTrackerDatum.api';
import { pomodoroDatum } from './PomodoroDatum.api';
import { priorityListDatum } from './PriorityListDatum.api';
import { reflectionDatum } from './ReflectionDatum.api';
import { scheduleTableDatum } from './ScheduleTableDatum.api';
import { textBoxDatum } from './TextBoxDatum.api';
import { toDoChartDatum } from './ToDoChartDatum.api';
import { toDoListDatum } from './ToDoListDatum.api';
import { waterIntakeChartDatum } from './WaterIntakeChartDatum.api';
import { waterIntakeTrackerDatum } from './WaterIntakeTrackerDatum.api';
import { weatherChartDatum } from './WeatherChartDatum.api';
import { weatherTrackerDatum } from './WeatherTrackerDatum.api';

export const datumEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    contentChartDatum: contentChartDatum(builder),
    contentPlannerDatum: contentPlannerDatum(builder),
    expenseChartDatum: expenseChartDatum(builder),
    expenseTrackerDatum: expenseTrackerDatum(builder),
    fileBoxDatum: fileBoxDatum(builder),
    focusDatum: focusDatum(builder),
    habitChartDatum: habitChartDatum(builder),
    habitTrackerDatum: habitTrackerDatum(builder),
    imageBoxDatum: imageBoxDatum(builder),
    intentionDatum: intentionDatum(builder),
    mealPlannerDatum: mealPlannerDatum(builder),
    moodChartDatum: moodChartDatum(builder),
    moodTrackerDatum: moodTrackerDatum(builder),
    pomodoroDatum: pomodoroDatum(builder),
    priorityListDatum: priorityListDatum(builder),
    reflectionDatum: reflectionDatum(builder),
    scheduleTableDatum: scheduleTableDatum(builder),
    textBoxDatum: textBoxDatum(builder),
    toDoChartDatum: toDoChartDatum(builder),
    toDoListDatum: toDoListDatum(builder),
    waterIntakeChartDatum: waterIntakeChartDatum(builder),
    waterIntakeTrackerDatum: waterIntakeTrackerDatum(builder),
    weatherChartDatum: weatherChartDatum(builder),
    weatherTrackerDatum: weatherTrackerDatum(builder),
  }),
});

export const {
  useContentChartDatumQuery,
  useContentPlannerDatumQuery,
  useExpenseChartDatumQuery,
  useExpenseTrackerDatumQuery,
  useFileBoxDatumQuery,
  useFocusDatumQuery,
  useHabitChartDatumQuery,
  useHabitTrackerDatumQuery,
  useImageBoxDatumQuery,
  useIntentionDatumQuery,
  useMealPlannerDatumQuery,
  useMoodChartDatumQuery,
  useMoodTrackerDatumQuery,
  usePomodoroDatumQuery,
  usePriorityListDatumQuery,
  useReflectionDatumQuery,
  useScheduleTableDatumQuery,
  useTextBoxDatumQuery,
  useToDoChartDatumQuery,
  useToDoListDatumQuery,
  useWaterIntakeChartDatumQuery,
  useWaterIntakeTrackerDatumQuery,
  useWeatherChartDatumQuery,
  useWeatherTrackerDatumQuery,
} = datumEndpoints;
