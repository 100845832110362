import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import Input from '../input/Input';
import { SelectboxProps } from './Selectbox.types';

const Selectbox: React.FC<SelectboxProps> = (
  { input, className, disabled, values = [], options = [], onChange, ...props },
  ref: React.MutableRefObject<HTMLUListElement>,
) => {
  const { disable, ...inputProps } = input || {};

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        inputStyles,
        backgroundStyles,
        focusStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
      }) => (
        <ul
          ref={ref}
          className={classNames(styles.options, customStyles)}
          {...props}
        >
          {options.map((option, key) => {
            const selected = values.find((i) => i.key === option.key);

            const addOrRemove = () =>
              !disabled &&
              onChange(
                selected
                  ? values.filter((i) => i.key !== option.key)
                  : [...values, option],
              );

            const handleClick = () => addOrRemove();

            const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
              if (e.code === 'Space' || e.code === 'Enter') {
                addOrRemove();
              }
            };

            return (
              <Input.Box
                as='li'
                key={key}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                className={classNames(
                  inputStyles,
                  backgroundStyles,
                  borderStyles,
                  disabledStyles,
                  errorStyles,
                  focusStyles,
                  styles.option,
                  selected && styles.selected,
                  className,
                )}
                tabIndex={0}
              >
                {option.key}
              </Input.Box>
            );
          })}
        </ul>
      )}
    </Input>
  );
};

const styles = {
  options: classes('flex', 'gap-2', 'justify-around', 'tracking-tight'),
  option: classes(
    'p-0',
    'grid',
    'select-none',
    'place-content-center',
    'cursor-pointer',
  ),
  selected: classes('bg-skin-inverted', 'text-skin-inverted'),
};

export default React.forwardRef(Selectbox);
