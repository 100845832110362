'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';

import Input from '../input/Input';
import { CheckboxProps } from './Checkbox.types';

const Checkbox: React.FC<CheckboxProps> = (
  { input, className, disabled, value, ...props },
  ref: React.Ref<HTMLInputElement>,
) => {
  const { disable, ...inputProps } = input || {};

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        backgroundStyles,
        focusStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
      }) => (
        <input
          ref={ref}
          type='checkbox'
          disabled={disabled}
          className={classNames(
            backgroundStyles,
            borderStyles,
            focusStyles,
            errorStyles,
            disabledStyles,
            styles.checkbox,
            className,
          )}
          {...props}
        />
      )}
    </Input>
  );
};

const styles = {
  checkbox: classes(
    'h-[1.125rem]',
    'w-[1.125rem]',
    'flex',
    'rounded-md',
    'items-center',
    'justify-center',
    'appearance-none',
    'place-content-center',
    'cursor-pointer',
    'disabled:cursor-default',
    'before:rounded-sm',
    'before:scale-0',
    'before:w-2.5',
    'before:h-2.5',
    'before:duration-100',
    'before:transition',
    'before:ease-in-out',
    'before:checked:scale-100',
    'before:bg-skin-green',
    'before:[clip-path:polygon(14%_44%,_0_65%,_50%_100%,_100%_16%,_80%_0%,_43%_62%)]',
  ),
};

export default React.forwardRef(Checkbox);
