'use client';

import { classes } from '@nowadays/ui/utils';

import { MockupWarning } from '../mockup';
import WentWrongInfo from './went-wrong-info/WentWrongInfo';

const WentWrong = () => {
  return (
    <div className={styles.root}>
      <MockupWarning />
      <WentWrongInfo />
    </div>
  );
};

const styles = {
  root: classes(
    'p-6',
    'h-full',
    'gap-8',
    'flex',
    'flex-col',
    'sm:flex-row',
    'items-center',
    'text-center',
    'justify-center',
  ),
};

export default WentWrong;
