import { DatePageParams, Page } from '@nowadays/base/types';
import { parseISO } from 'date-fns';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from './Page.endpoints';

export const datePage = (builder: Builder) =>
  builder.query<Page, DatePageParams>({
    query: (params) => ({
      url: '/page/date-page',
      params,
    }),
    async onQueryStarted(
      { planner, date, period },
      { dispatch, queryFulfilled },
    ) {
      try {
        const { data } = await queryFulfilled;

        if (!date || !period) {
          dispatch(
            pageEndpoints.util.upsertQueryData(
              'datePage',
              { planner, date: data.date, period: data.period },
              data,
            ),
          );
        }

        dispatch(
          pageEndpoints.util.upsertQueryData('page', { id: data.id }, data),
        );

        dispatch(
          pageEndpoints.util.updateQueryData(
            'pages',
            { planner, period },
            (draft) => {
              const page = draft.find((i) => i.id === data.id);

              if (!page) {
                const index = draft.findIndex(
                  (i) => parseISO(data.date) < parseISO(i.date),
                );

                index === -1 ? draft.push(data) : draft.splice(index, 0, data);
              }
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
