'use client';

import { Fragment } from 'react';

import AdornmentButton from '../../../button/adornment-button/AdornmentButton';
import ComboboxBase from '../combobox-base/ComboboxBase';
import { ComboboxAdornmentProps } from './ComboboxAdornment.types';

const ComboboxAdornment: React.FC<ComboboxAdornmentProps> = ({
  loading,
  disabled,
}) => {
  return (
    <ComboboxBase.Button as={Fragment}>
      <AdornmentButton
        as='span'
        name='Down'
        loading={loading}
        disabled={disabled}
      />
    </ComboboxBase.Button>
  );
};

export default ComboboxAdornment;
