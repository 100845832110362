'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { TitleProps } from './Title.types';

const Title = <T extends React.ElementType = 'h1'>({
  as,
  className,
  children,
  ...props
}: TitleProps<T>) => {
  const Component = as || 'h1';

  return (
    <Component className={classNames(styles.title, className)} {...props}>
      {children}
    </Component>
  );
};

const styles = {
  title: classes(
    'flex',
    'items-center',
    'gap-2',
    'pl-1',
    'text-base',
    'font-medium',
    'font-serif',
    'text-skin-accent',
  ),
};

export default Title;
