'use client';

import { classes } from '@nowadays/ui/utils';

const MockupTask = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 653 386'
      className={styles.root}
    >
      <g clipPath='url(#a)'>
        <path
          fill='#3C3C3A'
          d='M203.628 227.612V21.06c0-10.875 8.849-19.724 19.725-19.724h409.551c10.876 0 19.724 8.849 19.724 19.725v206.551c0 10.876-8.848 19.724-19.724 19.724H223.353c-10.876 0-19.725-8.848-19.725-19.724Zm447-206.552c0-9.788-7.935-17.724-17.724-17.724H223.353c-9.789 0-17.725 7.936-17.725 17.725v206.551c0 9.789 7.936 17.724 17.725 17.724h278.57c82.128 0 148.705-66.577 148.705-148.704V21.06Z'
        />
        <path
          fill='#FCFCFA'
          d='M650.628 21.06c0-9.788-7.935-17.724-17.724-17.724H223.353c-9.789 0-17.725 7.936-17.725 17.725v206.551c0 9.789 7.936 17.724 17.725 17.724h278.57c82.128 0 148.705-66.577 148.705-148.704V21.06Z'
        />
        <path
          fill='#FCFCFA'
          d='M347.915 38.907H118.381c-6.806 0-12.34 5.535-12.34 12.34v84.32c0 6.806 5.534 12.34 12.34 12.34h229.534c6.806 0 12.34-5.534 12.34-12.34v-84.32c0-6.806-5.534-12.34-12.34-12.34Z'
        />
        <path
          fill='#3C3C3A'
          d='M347.915 38.907H118.381c-6.806 0-12.34 5.535-12.34 12.34v84.32c0 6.806 5.534 12.34 12.34 12.34h229.534c6.806 0 12.34-5.534 12.34-12.34v-84.32c0-6.806-5.534-12.34-12.34-12.34Zm11.042 96.66c0 6.092-4.95 11.043-11.042 11.043H118.381c-6.092 0-11.043-4.951-11.043-11.043v-84.32c0-6.092 4.951-11.042 11.043-11.042h229.534c6.092 0 11.042 4.95 11.042 11.043v84.319Z'
        />
        <path
          fill='#E26B48'
          d='M129.128 77.336h204a3.5 3.5 0 1 1 0 7h-204a3.5 3.5 0 1 1 0-7Zm0 23h74a3.5 3.5 0 1 1 0 7h-74a3.5 3.5 0 1 1 0-7Z'
        />
        <path
          fill='#3C3C3A'
          d='M574.581 66.644h-140c-2.206 0-4-1.795-4-4 0-2.206 1.794-4 4-4h140c2.206 0 4 1.794 4 4 0 2.205-1.794 4-4 4Zm26.541 86.748H406.041v-1c64.232 9.85 129.324 9.04 195.081 0v1Zm-26.541-35.748h-140c-2.206 0-4-1.795-4-4 0-2.206 1.794-4 4-4h140c2.206 0 4 1.794 4 4 0 2.205-1.794 4-4 4Zm27.541 48.748H407.041v1h195.081v-1Zm-27.541-75.056h-140c-2.206 0-4-1.794-4-4 0-2.205 1.794-4 4-4h140c2.206 0 4 1.795 4 4 0 2.206-1.794 4-4 4Zm27.541 89.056c-67.337-4.581-132.732-5.31-195.081 0v-1h195.081v1Z'
        />
        <path
          fill='#3C3C3A'
          d='m332.893 164.119-8.23-3.899c3.376 4.711 6.284 12.068 7.841 17.923 2.637-5.454 6.887-12.127 11.094-16.112l-8.698 2.237c5.36-26.268 25.519-45.125 48.604-45.125v-2c-24.113 0-45.139 19.647-50.611 46.976Z'
        />
        <path
          fill='#E26B48'
          d='M370.772 204.143h-52c-2.205 0-4-1.795-4-4 0-2.206 1.795-4 4-4h52c2.206 0 4 1.794 4 4 0 2.205-1.794 4-4 4Zm-133.144-86.807c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14Z'
        />
        <path
          fill='#FCFCFA'
          d='M244.91 95.139c-.667-.39-1.686-.188-2.052.538l-4.992 9.89c-1.343-2.103-3.06-3.94-5.052-5.479-.642-.495-1.681-.097-2.053.539-.453.774-.105 1.555.539 2.052 2.323 1.794 4.114 4.207 5.32 6.869.459 1.012 2.112.948 2.591 0l6.237-12.357c.365-.723.191-1.626-.538-2.052Z'
        />
        <path
          fill='#E26B48'
          d='M405.628 76.336c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14Z'
        />
        <path
          fill='#FCFCFA'
          d='M412.91 54.139c-.667-.39-1.686-.188-2.052.538l-4.992 9.89c-1.343-2.103-3.06-3.94-5.052-5.479-.642-.495-1.681-.097-2.053.538-.453.775-.105 1.556.539 2.053 2.323 1.794 4.114 4.207 5.32 6.869.459 1.012 2.112.948 2.591 0l6.237-12.357c.365-.723.191-1.626-.538-2.052Z'
        />
        <path
          fill='#E26B48'
          d='M606.628 99.336c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14Z'
        />
        <path
          fill='#FCFCFA'
          d='M613.91 77.139c-.667-.39-1.686-.188-2.052.538l-4.992 9.89c-1.343-2.103-3.06-3.94-5.052-5.479-.642-.495-1.681-.097-2.053.538-.453.775-.105 1.556.539 2.053 2.323 1.794 4.114 4.207 5.32 6.869.459 1.012 2.112.948 2.591 0l6.237-12.357c.365-.723.191-1.626-.538-2.052Z'
        />
        <path
          fill='#3C3C3A'
          d='M292.844 172.917h-33.721a3.8 3.8 0 0 1-3.797-3.797c-4.459-13.032-4.371-25.801 0-38.323a3.8 3.8 0 0 1 3.797-3.797h33.721a3.8 3.8 0 0 1 3.797 3.797v38.323a3.8 3.8 0 0 1-3.797 3.797Z'
        />
        <path
          fill='#FCFCFA'
          d='M266.348 128.131c-5.463 0-9.891 4.429-9.891 9.891v30.285a3.48 3.48 0 0 0 3.479 3.479h18.648c9.348 0 16.926-7.578 16.926-16.926v-23.25a3.48 3.48 0 0 0-3.479-3.479h-25.683Z'
        />
        <path
          fill='#3C3C3A'
          d='M283.837 139.268h-15.788a.722.722 0 0 1 0-1.443h15.788a.721.721 0 0 1 0 1.443Zm0 15.393h-15.788a.723.723 0 0 1 0-1.444h15.788a.722.722 0 0 1 0 1.444Zm8.472-7.691h-32.732a.723.723 0 0 1 0-1.444h32.732a.722.722 0 0 1 0 1.444Zm.386 13.949h-13.658a.722.722 0 0 1 0-1.443h13.658a.721.721 0 0 1 0 1.443Z'
        />
        <path
          fill='#3C3C3A'
          d='M60.517 68.07c.888-4.095 1.149-1.078 3.081-4.793-1.326 4.801 1.12 3.229 4.371 6.994 22.844 3.581 24.283-4.242 32.737-4.003h.043c1.86-.03 2.816-2.258 1.694-3.742-2.769-3.665-5.821-9.321-2.933-12.882 5.118-6.308.608-19.243-1.598-16.447 0-10.787-13.284-17.86-18.437-16.108-9.521 3.239-18.34 7.606-19.407 17.38a65.037 65.037 0 0 1-.657 4.553c-.556 3.068-2.85 3.882-2.06 6.895l2.467 2.51c1.158 4.42.793 9.062-.794 13.348-.877 2.369-.998 4.4 1.493 6.296Z'
        />
        <path
          fill='#A0616A'
          d='M84.298 53.597c7.908 0 14.319-6.411 14.319-14.319 0-7.908-6.41-14.319-14.319-14.319-7.908 0-14.318 6.411-14.318 14.319 0 7.908 6.41 14.319 14.318 14.319Zm-22.305 312.1 7.374.174 4.11-33.128-10.882-.257-.602 33.211Z'
        />
        <path
          fill='#3C3C3A'
          d='M87.292 381.081c0 1.246-.884 2.256-1.975 2.256H70.681s-1.44-5.79-7.313-8.282l-.405 8.282h-7.55l.914-13.317s-2.02-7.124 2.175-10.766c4.195-3.641.797-3.134.797-3.134l1.65-8.242 11.41 1.342.084 12.937 5.537 12.842 8.121 4.012c.723.357 1.19 1.17 1.19 2.07Z'
        />
        <path
          fill='#A0616A'
          d='m70.103 335.641 5.857 4.483 22.831-24.353-8.643-6.616-20.045 26.486Z'
        />
        <path
          fill='#3C3C3A'
          d='M81.489 362.974c-.734 1.006-2.043 1.302-2.924.66l-11.83-8.62s2.247-5.528-1.032-11l-5.205 6.455-6.102-4.447 8.581-10.223s2.564-6.947 8.099-7.42c5.534-.473 2.49-2.064 2.49-2.064l6.187-5.69 8.431 7.804-7.551 10.506-3.088 13.639 4.2 8.025c.375.714.274 1.648-.256 2.375Z'
        />
        <path
          fill='#3C3C3A'
          d='M49.003 196.309c.444 1.823-.374 10.306.083 13.225 1.165 7.422 11.802 47.887 9.967 59.422-6.723 42.266 2.575 69.38 2.575 69.38h15l8-128 7.808 10.293 15.278 45.631-27.026 48.592L94.8 326.506l42.134-66.991-29.941-112.064-1.809-6.766-40.453-1.129 2.251 3.373-5.12 5.059s-.007.014-.034.041c-.79 1.102-18.32 25.765-12.825 48.28Z'
        />
        <path
          fill='#A0616A'
          d='M61.828 148.029c4.685 1.673 36.346-.054 45.165-.578l-1.809-6.766-40.453-1.129 2.251 3.373-5.12 5.059s-.007.014-.034.041Z'
        />
        <path
          fill='#E26B48'
          d='m114.189 93.843-1.457 7.731-.354 1.855-7.75 15.907 1.019 23.321c.652 2.729-26.867-3.465-41.163 2.934l-11.128-53.22L64.64 67.84l8.83-4.905 1.472-5.887h18.706c.996 4.665-.084 6.964-2.02 8.287l4.686 12.497 17.875 16.01Z'
        />
        <path
          fill='#A0616A'
          d='M128.82 48.239c-.231 2.002-.896 3.786-1.814 5.13l-1.001 19.214-9.128-.744 1.998-19.41c-.587-1.519-.827-3.407-.596-5.41.529-4.573 3.318-8.009 6.229-7.672 2.91.337 4.841 4.317 4.312 8.892Z'
        />
        <path
          fill='#E26B48'
          d='m92.437 68.43 1.191 2.906s-17.4-1.743-17.028 7.342c.317 7.752 33.069 38.472 34.634 39.189 4.635 2.124 10.022-.735 10.861-5.764 7.375-19.027 8.903-36.181 5.533-51.767h-10l-3.256 12.295-.183 9.353-5.273 11.248-17.288-21.896.81-2.906Z'
        />
        <path
          fill='#A0616A'
          d='M34.464 175.224c.643-1.959.79-3.901.514-5.544l7.304-18.275-8.798-3.221-6.462 18.885c-1.196 1.16-2.228 2.811-2.871 4.77-1.469 4.475-.35 8.861 2.497 9.796 2.848.934 6.348-1.936 7.816-6.411Z'
        />
        <path
          fill='#E26B48'
          d='M76.594 68.44s-10.803-4.096-16.935.909c-2.53 2.064-13.714 16.287-15.437 35.436-.135 1.5-1.157 2.791-1.733 4.195-3.552 8.656-9.667 12.643-12.267 20.046-1.017 2.893.847 10.375.205 12.871-3.362 13.069-2.149 20.52-2.149 20.52l9.079 3.022 4.925-3.48 8.088-29.538L76.594 68.44Z'
        />
        <path
          fill='#3C3C3A'
          d='M157.844 45.917h-33.721a3.801 3.801 0 0 1-3.797-3.797c-4.459-13.032-4.371-25.8 0-38.323A3.801 3.801 0 0 1 124.123 0h33.721a3.801 3.801 0 0 1 3.797 3.797V42.12a3.801 3.801 0 0 1-3.797 3.797Z'
        />
        <path
          fill='#FCFCFA'
          d='M131.348 1.131c-5.463 0-9.891 4.429-9.891 9.891v30.285a3.48 3.48 0 0 0 3.479 3.479h18.648c9.348 0 16.926-7.578 16.926-16.926V4.61a3.48 3.48 0 0 0-3.479-3.479h-25.683Z'
        />
        <path
          fill='#3C3C3A'
          d='M148.837 12.268h-15.788a.723.723 0 0 1 0-1.443h15.788a.722.722 0 0 1 0 1.443Zm0 15.392h-15.788a.723.723 0 0 1 0-1.443h15.788a.722.722 0 0 1 0 1.444Zm8.472-7.69h-32.732a.723.723 0 0 1 0-1.444h32.732a.722.722 0 0 1 0 1.444Zm.386 13.95h-13.658a.723.723 0 0 1 0-1.444h13.658a.723.723 0 0 1 0 1.443Z'
        />
        <path
          fill='#E26B48'
          d='M167.551 366.84c2.066.129 3.208-2.437 1.645-3.933l-.155-.619.061-.148c2.09-4.982 9.17-4.947 11.242.042 1.838 4.428 4.179 8.864 4.756 13.546a18.026 18.026 0 0 1-.317 6.2c4.308-9.41 6.575-19.686 6.575-30.021 0-2.596-.142-5.193-.433-7.782a71.698 71.698 0 0 0-.994-6.311c-2.306-11.277-7.299-22.018-14.5-30.989-3.462-1.893-6.349-4.851-8.093-8.397-.627-1.279-1.118-2.655-1.35-4.056.394.052 1.485-5.949 1.188-6.317.549-.833 1.532-1.247 2.132-2.06 2.982-4.044 7.091-3.338 9.236 2.157 4.582 2.313 4.626 6.148 1.815 9.837-1.789 2.347-2.035 5.522-3.604 8.035.161.206.329.407.491.613a73.588 73.588 0 0 1 7.681 12.169c-.61-4.766.29-10.508 1.826-14.21 1.748-4.217 5.025-7.769 7.911-11.415 3.466-4.379 10.573-2.468 11.184 3.084l.017.161c-.429.242-.849.498-1.259.77-2.339 1.547-1.531 5.174 1.241 5.601l.063.01a30.654 30.654 0 0 1-.807 4.579c3.702 14.316-4.29 19.53-15.702 19.765-.252.129-.497.258-.749.381a73.094 73.094 0 0 1 2.764 9.979 73.771 73.771 0 0 1 1.279 9.049c.297 3.83.272 7.679-.051 11.503l.019-.136c.82-4.211 3.107-8.144 6.427-10.87 4.945-4.063 11.932-5.559 17.268-8.824 2.568-1.572 5.859.459 5.411 3.437l-.022.142a20.241 20.241 0 0 0-3.577 1.888c-2.34 1.547-1.531 5.173 1.241 5.601l.063.01.129.019a30.658 30.658 0 0 1-5.639 8.83c-2.314 12.497-12.256 13.683-22.89 10.043h-.006a75.123 75.123 0 0 1-5.045 14.726h-18.02c-.065-.2-.123-.407-.181-.607 1.667.104 3.346.007 4.986-.297-1.337-1.64-2.674-3.294-4.011-4.934-.032-.033-.058-.065-.084-.097-.678-.84-1.362-1.673-2.041-2.513v-.001a30.024 30.024 0 0 1 .879-7.64Z'
        />
        <path
          fill='#3C3C3A'
          d='M0 384.476c0 .66.53 1.19 1.19 1.19h218.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H1.19c-.66 0-1.19.53-1.19 1.19Z'
        />
        <path
          fill='#3C3C3A'
          d='M88.18 22.336h-2.915c-8.528.01-23.105 12.072-17.465 20.533-4.816 12.847-4.991 19.531-2.947 23.03.753 1.287 2.247 1.874 3.735 1.77a5.7 5.7 0 0 1 .6-.012c1.44.048 3.374 6.824 4.716 7.345l3.562-1.083 5.842-5.838-2.964-8.904-5.736-4.488.781-10.393c-3.84-8.824.338-9.256 2.688-12.103l.238 9.132 3.367-3.249c2.742.071 5.946 1.26 8.077-1.212 3.87-4.528 7.881.938 12.452 2.043l1.417-1.122c.049-2.546-.328-5.004-1.059-7.206-.344-1.036-1.223-1.89-1.26-2.92-.139-3.865-7.445-7.615-13.13-5.323Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h652.628v385.666H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

const styles = {
  root: classes('w-full', 'max-w-xl'),
};

export default MockupTask;
