import {
  PresetPlannerUpdated,
  UpdatePresetPlanner,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { presetPlannerEndpoints } from './PresetPlanner.endpoints';

export const updatePresetPlanner = (builder: Builder) =>
  builder.mutation<PresetPlannerUpdated, UpdatePresetPlanner>({
    query: (body) => ({
      url: '/preset-planner/update',
      method: 'PUT',
      body,
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const {
          data: { planner, preset },
        } = await queryFulfilled;

        dispatch(
          presetPlannerEndpoints.util.updateQueryData(
            'presetPlanners',
            undefined,
            (draft) => {
              draft.forEach((i, index) => {
                if (i.id === planner.id) {
                  draft[index] = planner;
                }
              });
            },
          ),
        );

        dispatch(
          presetPlannerEndpoints.util.upsertQueryData(
            'presetPlanner',
            { id: preset.id },
            preset,
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
