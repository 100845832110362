'use client';
import './RichText.styles.css';

import { classes, classNames } from '@nowadays/ui/utils';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Color from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import { useEffect, useMemo } from 'react';

import Input from '../input/Input';
import RichTextBubbleMenu from './components/rich-text-bubble-menu/RichTextBubbleMenu';
import RichTextContent from './components/rich-text-content/RichTextContent';
import RichTextToolbar from './components/rich-text-toolbar/RichTextToolbar';
import { RichTextProvider } from './context/RichTextProvider';
import ImageNode from './nodes/image-node/ImageNode';
import { RichTextProps } from './RichText.types';

const RichText: React.FC<RichTextProps> = ({
  input,
  disabled,
  children,
  className,
  toolbar,
  content,
  extensions = [],
  ...props
}) => {
  const { disable, ...inputProps } = input || {};

  const extension = useMemo(
    () => [
      Document,
      Paragraph,
      Heading,
      Text,
      TextStyle,
      Bold,
      Italic,
      Strike,
      Color,
      ListItem,
      BulletList,
      OrderedList,
      Blockquote,
      History,
      Link.configure({
        autolink: false,
        linkOnPaste: false,
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      HorizontalRule.configure({
        HTMLAttributes: { class: 'border-skin-silent' },
      }),
      ImageNode,
      ...extensions,
    ],
    [extensions],
  );

  const editor = useEditor(
    {
      content,
      extensions: extension,
      ...props,
    },
    [],
  );

  useEffect(() => {
    if (editor) {
      if (editor.isEmpty) {
        setTimeout(() => {
          editor.commands.setContent(content);
        });
      }
    }
  }, [editor, content]);

  if (!editor) {
    return null;
  }

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        inputStyles,
        backgroundStyles,
        focusStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
        boxProps,
      }) => (
        <Input.Box
          className={classNames(
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
          )}
          {...boxProps}
        >
          <RichTextContent
            className={classNames(
              inputStyles,
              backgroundStyles,
              focusStyles,
              styles.root,
              className,
            )}
          >
            <RichTextProvider editor={editor}>
              <RichTextToolbar {...toolbar} />
              <EditorContent className={styles.editor} editor={editor} />
              <BubbleMenu editor={editor}>
                <RichTextBubbleMenu />
              </BubbleMenu>
            </RichTextProvider>
          </RichTextContent>
        </Input.Box>
      )}
    </Input>
  );
};

const styles = {
  root: classes('p-2', 'h-full', 'w-full'),
  editor: classes('max-h-[28rem]', 'overflow-auto'),
};

export default RichText;
