'use client';

import './Loader.styles.css';

import { classes, classNames } from '@nowadays/ui/utils';

import { LoaderProps } from './Loader.types';

const Loader: React.FC<LoaderProps> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className='loader'>
        <span className='bar'></span>
        <span className='bar'></span>
        <span className='bar'></span>
      </div>
    </div>
  );
};

const styles = {
  root: classes('grid', 'place-content-center'),
};

export default Loader;
