import { WidgetCode } from '../page/WidgetElement.types';

export enum WidgetCodes {
  Calendar = 'calendar',
  ContentChart = 'content-chart',
  ContentPlanner = 'content-planner',
  ExpenseChart = 'expense-chart',
  ExpenseTracker = 'expense-tracker',
  FileBox = 'file-box',
  Focus = 'focus',
  HabitChart = 'habit-chart',
  HabitTracker = 'habit-tracker',
  ImageBox = 'image-box',
  Intention = 'intention',
  MealPlanner = 'meal-planner',
  MoodChart = 'mood-chart',
  MoodTracker = 'mood-tracker',
  Pomodoro = 'pomodoro',
  PriorityList = 'priority-list',
  Reflection = 'reflection',
  ScheduleTable = 'schedule-table',
  TextBox = 'text-box',
  ToDoChart = 'to-do-chart',
  ToDoList = 'to-do-list',
  WaterIntakeChart = 'water-intake-chart',
  WaterIntakeTracker = 'water-intake-tracker',
  WeatherChart = 'weather-chart',
  WeatherTracker = 'weather-tracker',
}

export const WIDGET_WIDTH = 480;

export const WIDGET_HEIGHT = 320;

export type WidgetRowSize = 0.375 | 0.5 | 0.75 | 1 | 2 | 3 | 4;

export type WidgetColSize = 0.375 | 0.5 | 0.75 | 1 | 2 | 3 | 4;

export type WidgetSize = {
  row: WidgetRowSize;
  col: WidgetColSize;
};

export interface Widget {
  id: string;
  setId: string;
  code: WidgetCode;
  name: string;
  keywords?: string;
  thumbnail: string;
  favorite?: boolean;
}

export type Widgets = {
  id: string;
  name: string;
  widgets: Widget[];
};

export type WidgetsParams = {
  planner: string;
};
