import { ResetPasswordParams } from '@nowadays/base/types';

import { authEndpoints } from '../auth/Auth.endpoints';
import { Builder } from '../base/query/Api';

export const resetPassword = (builder: Builder) =>
  builder.mutation<void, ResetPasswordParams>({
    query: (body) => ({
      url: '/user/reset-password',
      method: 'POST',
      body,
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;

        dispatch(
          authEndpoints.util.updateQueryData('authUser', undefined, (draft) => {
            if (draft?.user?.hasPassword) {
              draft.user.hasPassword = true;
            }
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
