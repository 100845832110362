'use client';

import Title from '../../typography/title/Title';
import { FormTitleProps } from './FormTitle.types';

const FormTitle: React.FC<FormTitleProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <Title as='h2' className={className}>
      {title || children}
    </Title>
  );
};

export default FormTitle;
