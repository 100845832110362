import { Template, TemplatesParams } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from '../page/Page.endpoints';

export const templates = (builder: Builder) =>
  builder.query<Template[], TemplatesParams>({
    query: ({ planner, ...params }) => ({
      url: `/template/templates/${planner}`,
      params,
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        for (let i = 0; i < data.length; i++) {
          const { page } = data[i];

          dispatch(
            pageEndpoints.util.upsertQueryData('page', { id: page.id }, page),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    providesTags: ['Templates'],
  });
