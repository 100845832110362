'use client';

import AdornmentButton from '../../../button/adornment-button/AdornmentButton';
import { ListboxAdornmentProps } from './ListboxAdornment.types';

const ListboxAdornment: React.FC<ListboxAdornmentProps> = ({ disabled }) => {
  return <AdornmentButton as='span' name='Down' disabled={disabled} />;
};

export default ListboxAdornment;
