import { classes, classNames } from '@nowadays/ui/utils';

import { FieldSkeletonProps } from './FieldSkeleton.types';

const FieldSkeleton: React.FC<FieldSkeletonProps> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.label}>
        <div className={styles.title} />
      </div>
      <div className={styles.field} />
    </div>
  );
};

const styles = {
  root: classes('w-60'),
  label: classes('h-6', 'flex', 'items-center'),
  title: classes(
    'h-3',
    'ml-0.5',
    'w-16',
    'bg-skin-base',
    'rounded',
    'animate-pulse',
  ),
  field: classes(
    'h-8',
    'w-full',
    'border',
    'box-content',
    'border-transparent',
    'bg-skin-base',
    'rounded-lg',
    'animate-pulse',
  ),
};

export default FieldSkeleton;
