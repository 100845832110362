'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import { Icon } from '../../../icon';
import { SwitchOptionsProps } from './SwitchOptions.types';

const SwitchOptions: React.FC<SwitchOptionsProps> = ({
  option = 'true-false',
  selected = true,
  icon,
  className,
  htmlFor,
  disable,
}) => {
  const translate = useTranslate();
  const current = selected.toString();

  return (
    <div
      className={classNames(
        styles.root,
        styles[current],
        disable && styles.disable,
        className,
      )}
    >
      <label
        htmlFor={htmlFor}
        className={classNames(styles.label, disable && styles.labelDisable)}
      >
        {translate(`ui.components.inputs.switch.${option}.${selected}`)}
      </label>
      {icon && (
        <Icon
          name={icon[current]}
          className={classNames(styles.icon, icon.className)}
        />
      )}
    </div>
  );
};

const styles = {
  root: classes('flex', 'items-center', 'gap-2', 'w-20'),
  true: classes('text-skin-green'),
  false: classes('text-skin-red'),
  icon: classes('w-4', 'h-4'),
  disable: classes('opacity-70'),
  label: classes('cursor-pointer', 'select-none'),
  labelDisable: classes('cursor-default'),
};

export default SwitchOptions;
