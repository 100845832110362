import { Page, PageElement, PageSize } from '@nowadays/base/types';

export enum PageCategories {
  Active = 'active',
  Favorite = 'favorite',
}

export type PagePosition = {
  x: number;
  y: number;
};

export interface PageState {
  pageId: string;
  currentPage?: string;
  activePage?: Page;
  activeElement?: PageElement;
  copiedElement?: PageElement;
  isEditable: boolean;
  isPagesOpen: boolean;
  isDragging: boolean;
  isAnimating: boolean;
  isMoving: boolean;
  search: string;
  category?: PageCategories;
  boundary?: PageSize;
  container?: PageSize;
  scale: number;
  initialScale: number;
  position: PagePosition;
  lastScaleOffset?: PagePosition;
}
