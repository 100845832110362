'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { IconButton } from '../../button';
import { useSnackbar } from '../../snackbar/snackbar-context/SnackbarContext';
import Input from '../input/Input';
import DropZone from './drop-zone/DropZone';
import { FilePickerProps } from './FilePicker.types';

const FilePicker: React.FC<FilePickerProps> = (
  {
    type = 'file',
    input,
    value,
    className,
    disabled,
    children,
    multiple,
    maxSize = 16,
    maxFiles,
    helperText = true,
    image = {},
    clear = true,
    onBlur,
    onDrop,
    ...props
  },
  ref: React.Ref<HTMLInputElement>,
) => {
  const translate = useTranslate();
  const { addMessage } = useSnackbar();
  const { disable, ...inputProps } = input || {};

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    type === 'image' && typeof value !== 'object' && (value as string),
  );

  useEffect(() => {
    if (type === 'image') {
      if (value) {
        setImagePreview(
          typeof value === 'string' ? value : URL.createObjectURL(value),
        );
      } else {
        setImagePreview(null);
      }
    }
  }, [value]);

  useEffect(() => {
    if (!imagePreview) {
      return;
    }

    return () => URL.revokeObjectURL(imagePreview);
  }, [imagePreview]);

  const isFilesExceeded = (files: File[]) => {
    if (files.length > maxFiles) {
      addMessage({
        item: translate('ui.components.inputs.file.maxFiles', {
          max: maxFiles,
        }),
        color: 'red',
      });

      return true;
    }
  };

  const isMaxSizeExceeded = (files: File[]) => {
    const maxInMb = maxSize * 1024 * 1024;

    const isBigger = multiple
      ? files.find((i) => i.size > maxInMb)
      : files[0].size > maxInMb;

    if (isBigger) {
      addMessage({
        item: translate('ui.components.inputs.file.maxSize', {
          size: maxSize,
        }),
        color: 'red',
      });

      return true;
    }
  };

  const handleImageLoad = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    setImageLoaded(true);
    image.onLoad && image.onLoad(e);
  };

  const handleClear = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (imagePreview) {
      typeof clear === 'object' && clear.onClick && (await clear.onClick(e));
      setImagePreview(undefined);
      URL.revokeObjectURL(imagePreview);
    }
  };

  const handleDrop: DropzoneOptions['onDrop'] = (event, ...rest) => {
    const files = event as File[];

    if (isFilesExceeded(files) || isMaxSizeExceeded(files)) {
      return;
    }

    if (type === 'image' && !multiple) {
      setImagePreview(URL.createObjectURL(files[0]));
    }

    onDrop && onDrop(files, ...rest);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    disabled,
    onDrop: handleDrop,
    ...props,
  });

  return (
    <Input disable={disabled || disable} {...inputProps}>
      {({
        backgroundStyles,
        focusStyles,
        borderStyles,
        errorStyles,
        disabledStyles,
        customStyles,
      }) => (
        <div
          {...getRootProps()}
          onBlur={onBlur}
          className={classNames(
            styles.root,
            backgroundStyles,
            focusStyles,
            borderStyles,
            errorStyles,
            disabledStyles,
            customStyles,
            className,
            isDragActive && styles.active,
            imagePreview && !imageLoaded && styles.loading,
          )}
        >
          <input ref={ref} {...getInputProps()} />

          {type === 'image' && imagePreview && clear && imageLoaded && (
            <IconButton
              name='Trash'
              color='red'
              variant='contained'
              onClick={handleClear}
              {...(typeof clear === 'object' && clear)}
              className={classNames(
                styles.clear,
                typeof clear === 'object' && clear.className,
              )}
            />
          )}

          {type === 'image' &&
            (children ||
              (imagePreview && (
                <img
                  src={imagePreview}
                  onLoad={handleImageLoad}
                  alt={image.alt}
                  {...image}
                  className={classNames(styles.image, image.className)}
                />
              )) || (
                <DropZone
                  type={type}
                  multiple={multiple}
                  helperText={helperText}
                />
              ))}

          {type === 'file' &&
            (children || (
              <DropZone
                type={type}
                multiple={multiple}
                helperText={helperText}
              />
            ))}
        </div>
      )}
    </Input>
  );
};

const styles = {
  root: classes(
    'group',
    'relative',
    'flex',
    'items-center',
    'justify-center',
    'cursor-pointer',
    'overflow-hidden',
    'w-full ',
  ),
  active: classes('border', 'border-skin-accent'),
  loading: classes('bg-skin-base', 'animate-pulse'),
  image: classes('object-cover', 'h-full'),
  clear: classes(
    'absolute',
    'right-0.5',
    'top-0.5',
    'p-0.5',
    'opacity-0',
    'group-hover:opacity-100',
  ),
};

export default React.forwardRef(FilePicker);
