import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { DrawMode, DrawState, Lines } from './Draw.slice.types';

const initialState: DrawState = {
  lines: {
    draw: [],
    actual: [],
  },
  drawMode: 'draw',
};

export const drawSlice = createSlice({
  name: 'draw',
  initialState,
  reducers: {
    changeLines: (state, { payload }: PayloadAction<Lines>) => {
      state.lines = payload;
    },
    clearLines: (state) => {
      state.lines = {
        draw: [],
        actual: [],
      };
    },
    changeIsDrawing: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrawing = payload;

      if (!payload) {
        state.drawMode = 'draw';
      }
    },
    changeDrawMode: (state, { payload }: PayloadAction<DrawMode>) => {
      state.drawMode = payload;
    },
  },
});

export const drawReducer = drawSlice.reducer;

export const useDrawActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators({ ...drawSlice.actions }, dispatch),
    [dispatch],
  );
};
