import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgDown = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m17 10-1.747 1.763c-1.394 1.406-2.09 2.109-2.94 2.217a2.5 2.5 0 0 1-.626 0c-.85-.108-1.546-.811-2.94-2.217L7 10'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDown);
export default ForwardRef;
