'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import SortableItem from '../../../sortable/sortable-item/SortableItem';
import { useTable } from '../../table-context';
import TableCell from '../table-cell/TableCell';
import { TableSortableRowProps } from './TableSortableRow.types';

const TableSortableRow: React.FC<TableSortableRowProps> = ({
  disabled,
  row,
  className,
  children,
}) => {
  const { sortable } = useTable();
  const cells = row.getVisibleCells();

  const id = row.original[sortable];

  return (
    <SortableItem
      as='tr'
      handle
      id={id}
      key={id}
      disabled={disabled}
      className={classNames(styles.root, className)}
    >
      {({ listeners }) =>
        children
          ? children({ cells, listeners })
          : cells.map((cell) => (
              <TableCell key={cell.id} cell={cell} listeners={listeners} />
            ))
      }
    </SortableItem>
  );
};

const styles = {
  root: classes('border-b', 'border-skin-silent', 'text-skin-muted', 'text-sm'),
};

export default TableSortableRow;
