'use client';

import Label from '../../input/label/Label';
import { LabelProps } from '../../input/label/Label.types';

const FieldLabel: React.FC<LabelProps> = ({
  label,
  htmlFor,
  className,
  children,
}) => {
  return (
    <Label label={label} className={className} htmlFor={htmlFor}>
      {children}
    </Label>
  );
};

export default FieldLabel;
