'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import TableCell from '../table-cell/TableCell';
import { TableRowProps } from './TableRow.types';

const TableRow: React.FC<TableRowProps> = ({ row, className, children }) => {
  const cells = row.getVisibleCells();

  return (
    <tr key={row.id} className={classNames(styles.root, className)}>
      {children
        ? children(cells)
        : cells.map((cell) => <TableCell key={cell.id} cell={cell} />)}
    </tr>
  );
};

const styles = {
  root: classes('border-b', 'border-skin-silent', 'text-skin-muted', 'text-sm'),
};

export default TableRow;
