import {
  ContentChartDatum,
  ContentChartDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const contentChartDatum = (builder: Builder) =>
  builder.query<ContentChartDatum, ContentChartDatumParams>({
    query: (params) => ({ url: '/datum/content-chart-datum', params }),
    providesTags: ['ContentChart'],
  });
