'use client';

import { classes } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import DialogBase from '../dialog-base/DialogBase';
import { ConfirmDialogProps } from './ConfirmDialog.types';

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  confirm,
  description,
  onConfirm,
  onSuccess,
  onClose,
  color = 'orange',
  level,
  children,
}) => {
  const handleConfirm = async () => {
    try {
      if (onConfirm) {
        if (onConfirm.constructor.name === 'AsyncFunction') {
          await onConfirm();
        } else {
          onConfirm();
        }
      }

      onSuccess && onSuccess();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancel = () => onClose && onClose();

  return (
    <Fragment>
      {children}
      <DialogBase open={open} level={level || 1} onClose={handleCancel}>
        <DialogBase.Backdrop />
        <DialogBase.Panel size='sm'>
          <DialogBase.Title
            color={color}
            title={confirm}
            className={styles.header}
          />
          {description && <DialogBase.Description description={description} />}
          <DialogBase.Footer
            cancel={{ color: color || 'orange', onClick: handleCancel }}
            confirm={{ color: color || 'orange', onClick: handleConfirm }}
          />
        </DialogBase.Panel>
      </DialogBase>
    </Fragment>
  );
};

const styles = {
  header: classes('flex', 'space-x-2'),
};

export default ConfirmDialog;
