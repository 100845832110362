'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import IconButton from '../../../button/icon-button/IconButton';
import { CalendarBaseIteratorRenderArgs } from '../../calendar-base/calendar-base-iterator/CalendarBaseIterator.types';
import CalendarBase from '../../calendar-base/CalendarBase';
import { CalendarIteratorProps } from './CalendarIterator.types';

type Direction = 'left' | 'right';

const CalendarIterator: React.FC<CalendarIteratorProps> = ({
  onClick,
  className,
  onlyMonth,
  ...props
}) => {
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);
  const [leftRef, setLeftRef] = useState<HTMLButtonElement>(null);
  const [rightRef, setRightRef] = useState<HTMLButtonElement>(null);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const listener = (event: WheelEvent) => {
      if (event.deltaY < 0) {
        leftRef && leftRef.click();
      } else {
        rightRef && rightRef.click();
      }
    };

    document.addEventListener('wheel', listener);

    return () => {
      document.removeEventListener('wheel', listener);
    };
  }, [leftRef, rightRef]);

  const handleIterate = useCallback(
    (dir: Direction, args: CalendarBaseIteratorRenderArgs) => {
      const { previous, previousMonth, next, nextMonth } = args;

      if (dir === 'left') {
        onlyMonth ? previousMonth() : previous();
      } else {
        onlyMonth ? nextMonth() : next();
      }
    },
    [onlyMonth],
  );

  const handleClick = useCallback(
    (
      dir: Direction,
      args: CalendarBaseIteratorRenderArgs,
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      handleIterate(dir, args);
      onClick && onClick(e);
    },
    [handleIterate, onClick],
  );

  const handlePressEnd = useCallback(() => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
  }, []);

  return (
    <CalendarBase.Iterator>
      {(args) => (
        <div className={styles.root}>
          <IconButton
            ref={setLeftRef}
            name='Left'
            color='silent'
            variant='contained'
            className={classNames(styles.button, className)}
            onClick={(e) => handleClick('left', args, e)}
            onMouseUp={handlePressEnd}
            onMouseLeave={handlePressEnd}
            onTouchEnd={handlePressEnd}
            {...props}
          />
          <IconButton
            ref={setRightRef}
            name='Right'
            color='silent'
            variant='contained'
            className={classNames(styles.button, className)}
            onClick={(e) => handleClick('right', args, e)}
            onMouseUp={handlePressEnd}
            onMouseLeave={handlePressEnd}
            onTouchEnd={handlePressEnd}
            {...props}
          />
        </div>
      )}
    </CalendarBase.Iterator>
  );
};

const styles = {
  root: classes('space-x-1.5', 'flex'),
  button: classes('p-1', 'duration-0'),
};

export default CalendarIterator;
