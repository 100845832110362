export const home = {
  features: { title: 'Özellikler' },
  home: {
    description:
      "Nowadays'i keşfedin: kişiselleştirilebilir planlama artık çok kolay. Araçları özelleştirin, sayfaları zahmetsizce düzenleyin. Rutininizi yeniden tanımlayın!",
    features: {
      '1': {
        alt: 'Nowadays ile gününüzü kendi isteğinize göre tasarlayın. Takvimler, yapılacaklar listeleri ve alışkanlık takipçileri gibi araçları kolayca sürükleyip bırakarak düzenleyin. İhtiyaçlarınıza ve tercihlerinize uygun bir planlayıcı düzeni oluşturun.',
        text: 'Gününüzü kendi tarzınızla tasarlama özgürlüğünü keşfedin. Takvim, yapılacaklar listesi, alışkanlık izleyici ve daha fazlasını içeren araçları sürükleyip bırakın. Araçları basit bir dokunuşla büyütün veya küçültün ve dijital planlayıdınızın yaşam tarzınızın bir yansımasına dönüşmesini izleyin.',
        title: 'Tasarla. Organize Et. Başar.',
      },
      '2': {
        alt: "Nowadays'in entegre takvimi ile düzenli kalın. Günler, haftalar ve aylar arasında sorunsuz gezinerek, tutarlı bir planlama deneyimi yaşayın. Planlarınızı zaman çizelgeleri arasında senkronize ederek, dinamik yaşamınızı kolayca yönetin.",
        text: 'Entegre takvim ile günlük, haftalık ve aylık sayfalar arasında zahmetsizce gezinin. Zaman dilimleri arasında senkronize olan planlarınız, dinamik yaşamınıza uyum sağlayan tutarlı ve düzenli bir planlama deneyimi sunar.',
        title: 'Akıcı ve Kesintisiz Gezinme',
      },
      '3': {
        alt: 'Nowadays ile birden fazla planlayıcıyle sonsuz olanakları keşfedin. Her bir kitabı günlük planlama, finans veya günlük yaşam gibi belirli amaçlar için özelleştirin. Kişiselleştirilmiş planlayıcı ile hayatınızın farklı yönlerini etkili bir şekilde düzenleyin.',
        text: 'Nowadays, herhangi bir tarayıcıdan erişilebilen web tabanlı bir uygulamadır. Kişiselleştirilmiş dijital planlayıcınız ister masaüstünüzde, ister tabletinizde veya mobil cihazınızda olun, sadece bir tık uzağınızda. Görevler, etkinlikler ve alışkanlıkları takip etmek için her gün giriş yapın.',
        title: 'Her An, Her Yerden Erişilebilir',
      },
      '4': {
        alt: 'Nowadays ile planlayıcınıza her zaman ve her yerden erişin. Masaüstü bilgisayar, tablet veya mobil cihazınızda olsun, Nowadays her web tarayıcısından erişilebilir. Günlük olarak giriş yaparak, görevlerinizi, etkinliklerinizi ve alışkanlıklarınızı kolayca yönetin.',
        text: 'Çeşitli amaçlar için farklı planlayıcılar oluşturun ve tasarlayın. İster günlük tutma, ister planlama, finans veya hayatınızın başka bir yönü olsun. Nowadays, benzersiz ihtiyaçlarınıza göre birden fazla kitabı özelleştirmenize olanak tanır.',
        title: 'Farklı Planlayıcılar, Sonsuz Olasılıklar',
      },
      '5': {
        alt: 'Nowadays ile hedeflerinizi başarın. Günlük rutininizi yeniden tanımlayın ve her günü başarı yolculuğunuz için sayın. Hayatınızı etkili bir şekilde düzenlemek ve kişiselleştirilmiş dijital planlama maceranıza Nowadays ile başlamak için kendinize şans verin.',
        text: 'Yeni bir planlama çağına adım atın. Hayatınızı düzenleme şeklinizi dönüştürün; alışkanlıklarınızı, görevlerinizi ve etkinliklerinizi bir bakışta değerlendirin ve her günü dolu dolu geçirin. Kişiselleştirilmiş dijital planlama maceranız burada başlıyor.',
        title: 'Günlük Rutininizi Yeniden Tanımlayın',
      },
      title: 'Her günü benzersiz bir şekilde <0>sizin</0> kılmak.',
    },
    footer: { language: { title: 'Dil' } },
    keywords:
      'Dijital planlama,Yaşam düzenleyici,Günlük planlama,Haftalık planlama,Aylık planlama,Yıllık planlama,Özelleştirilebilir planlama,Esnek planlama,Çizelge,Yapılacaklar listesi,Alışkanlık takipçisi,Finans takipçisi,Takvim,Hedefler,Görevler',
    laptopView:
      "Nowadays dijital planlama arayüzü, kullanıcıların planlama deneyimini kişiselleştirmelerine olanak tanıyan çeşitli özelleştirilebilir widget'lar sunar. Kullanıcılar kolayca widget'lar ekleyebilir, yeniden boyutlandırabilir ve yeniden düzenleyebilirler. Nowadays, günlük yaşamlarını düzenlemenin modern ve sezgisel bir yolunu kullanıcılara sunar.",
    menu: { seeAll: 'Tümünü gör' },
    mixAndMatch:
      'İhtiyacınız olan araçları <0>seçin ve eşleştirin</0>.<1></1>Tüm <2>özgün</2> ihtiyaçlarınıza uygun sayfalar tasarlayın.',
    motto: 'Sizin planlayıcınız, <0>sizin kurallarınız</0>',
    signUpForFree: 'Ücretsiz Kayıt Ol!',
    startPlanning: 'Planlamaya Başla',
    title: 'Nowadays: Sizin planlayıcınız, sizin kurallarınız',
    tryForFree: 'Şimdi Ücretsiz Dene!',
  },
  shared: {
    header: {
      auth: {
        continue: '{{name}} olarak devam et',
        login: 'Oturum aç',
        signup: 'Kayıt ol',
      },
    },
  },
  tutorials: { description: 'Uygulama nasıl kullanılır', title: 'Eğiticiler' },
  widgets: {
    description:
      "Nowadays'in araçlarını keşfedin ve nasıl kullanılacağını öğrenin",
    keywords:
      'Metin kutusu,Görsel kutusu,Yapılacaklar listesi,Öncelikler listesi,Takvim,Zaman çizelgesi,Pomodoro,Odaklanma,Niyet notu,Düşünce notu,Alışkanlık takipçisi,Ruh hali takipçisi,Su içme takipçisi, Hava durumu takipçisi,İçerik planlayıcı',
    title: 'Araçlar',
  },
};
