'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import DatePicker from '../../../input/date-picker/DatePicker';
import { Popover } from '../../../popover/index';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormDatePickerProps } from './FormDatePicker.types';

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  field,
  label,
  disabled,
  placeholder,
  dateinput,
  container,
  ...props
}) => {
  const { input, ...inputProps } = dateinput || {};
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  return (
    <div
      key={field}
      ref={(el) => setRef(el)}
      className={classNames(styles.root, container?.className)}
    >
      {label && <FieldLabel label={label} htmlFor={field} />}

      <Controller
        control={control}
        name={field}
        render={({ field: { value, onBlur, onChange } }) => (
          <Popover reference={ref}>
            {({ close }) => (
              <DatePicker
                key={field}
                date={value}
                onBlur={onBlur}
                onChange={onChange}
                {...props}
              >
                <Popover.Button
                  id={field}
                  as={DatePicker.Input}
                  disabled={isDisable}
                  placeholder={placeholder}
                  input={{ error: isError, disable: isDisable, ...input }}
                  {...inputProps}
                />
                <Popover.Panel
                  floating={{
                    placement: 'bottom',
                    autoUpdate: true,
                    shift: { padding: 0 },
                  }}
                >
                  <DatePicker.Panel onSelect={close} />
                </Popover.Panel>
              </DatePicker>
            )}
          </Popover>
        )}
      />

      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
  button: classes('flex', 'items-center', 'gap-1', 'cursor-pointer'),
  bullet: classes('h-6', 'w-8', 'rounded-md'),
  disabled: classes('cursor-default'),
};

export default FormDatePicker;
