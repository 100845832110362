import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M8 18a1 1 0 1 0 0-2zm8-2a1 1 0 1 0 0 2zm0-10a1 1 0 1 0 0 2zM8 8a1 1 0 0 0 0-2zm0 3a1 1 0 1 0 0 2zm8 2a1 1 0 1 0 0-2zm-8 3a4 4 0 0 1-4-4H2a6 6 0 0 0 6 6zm12-4a4 4 0 0 1-4 4v2a6 6 0 0 0 6-6zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6zM8 6a6 6 0 0 0-6 6h2a4 4 0 0 1 4-4zm0 7h8v-2H8z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
