'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment } from 'react';

import ButtonBase from '../button-base/ButtonBase';
import { ButtonProps } from './Button.types';

const Button: React.FC<ButtonProps> = (
  {
    children,
    className,
    startIcon,
    endIcon,
    spinner,
    text,
    type = 'button',
    ...props
  },
  ref: React.Ref<HTMLButtonElement>,
) => {
  return (
    <ButtonBase
      ref={ref}
      type={type}
      className={classNames(styles.button, className)}
      {...props}
    >
      {({ loading }) => {
        return (
          <Fragment>
            {loading && <ButtonBase.Spinner {...spinner} />}
            {!loading && startIcon && <ButtonBase.Icon {...startIcon} />}
            {children || text}
            {endIcon && <ButtonBase.Icon {...endIcon} />}
          </Fragment>
        );
      }}
    </ButtonBase>
  );
};

const styles = {
  button: classes('px-3', 'py-1.5', 'rounded-lg'),
};

export default React.memo(React.forwardRef(Button));
