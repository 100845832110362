import { Page, PageElement, PageSize } from '@nowadays/base/types';
import { updateObjectWithPath } from '@nowadays/base/utils';
import {
  bindActionCreators,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { store } from '../../store/Store';
import { AppThunk } from '../../store/Store.types';
import { useStateDispatch } from '../../store/useStateDispatch';
import { PageCategories, PagePosition, PageState } from './Page.slice.types';

const DEFAULT_SCALE = 0.75;

export const MAX_SCALE = 3;

const initialState: PageState = {
  pageId: 'PAGE',
  isEditable: false,
  isPagesOpen: false,
  isDragging: false,
  isAnimating: false,
  isMoving: false,
  search: '',
  scale: DEFAULT_SCALE,
  initialScale: DEFAULT_SCALE,
  position: { x: 0, y: 0 },
  category: PageCategories.Active,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    changeCurrentPage: (state, { payload }: PayloadAction<string>) => {
      state.currentPage = payload;
    },
    changeActivePage: (state, { payload }: PayloadAction<Page | undefined>) => {
      if (payload) {
        state.activePage = payload;
      } else {
        state.activePage = undefined;
      }
    },
    changeActiveElement: (
      state,
      { payload }: PayloadAction<PageElement | undefined>,
    ) => {
      state.activeElement = payload;
    },
    updateActivePage: (
      state,
      {
        payload,
      }: PayloadAction<{
        path: string;
        value: unknown;
      }>,
    ) => {
      if (!state.activePage) {
        return;
      }

      const { path, value } = payload;

      updateObjectWithPath(state.activePage, path, value);
    },
    updateActiveElement: (
      state,
      {
        payload,
      }: PayloadAction<{
        path: string;
        value: unknown;
      }>,
    ) => {
      if (!state.activeElement) {
        return;
      }

      const { path, value } = payload;

      updateObjectWithPath(state.activeElement, path, value);
    },
    changeCopiedElement: (state, { payload }: PayloadAction<PageElement>) => {
      state.copiedElement = payload;
    },
    changeCategory: (state, { payload }: PayloadAction<PageCategories>) => {
      state.category = payload;
    },
    changeIsEditable: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditable = payload;
    },
    changeIsPagesOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPagesOpen = payload;
    },
    changeIsDragging: (state, { payload }: PayloadAction<boolean>) => {
      state.isDragging = payload;
    },
    changeAnimating: (state, { payload }: PayloadAction<boolean>) => {
      state.isAnimating = payload;
    },
    changeIsMoving: (state, { payload }: PayloadAction<boolean>) => {
      state.isMoving = payload;
    },
    changeSearchKeyword: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    changeLastScaleOffset: (
      state,
      { payload }: PayloadAction<PagePosition>,
    ) => {
      state.lastScaleOffset = payload;
    },
    updateRectangles: (
      state,
      action: PayloadAction<{
        boundary: PageSize;
        container: PageSize;
      }>,
    ) => {
      state.boundary = action.payload.boundary;
      state.container = action.payload.container;
    },
    findDefaultScale: (state) => {
      if (!state.boundary || !state.container) {
        return;
      }

      const scaleX = state.boundary.width / state.container.width;
      const scaleY = state.boundary.height / state.container.height;

      const initialScale = Math.min(scaleX, scaleY) * state.scale;

      if (initialScale > 0.01 && initialScale < MAX_SCALE) {
        state.initialScale = initialScale;
      }
    },
    useDefaultScale: (state) => {
      state.scale = state.initialScale;
    },
    useMaxScale: (state) => {
      state.scale = state.initialScale * MAX_SCALE;
    },
    updatePosition: (state, { payload }: PayloadAction<PagePosition>) => {
      state.position.x = payload.x;
      state.position.y = payload.y;
    },
    scalePage: (
      state,
      action: PayloadAction<{
        scale: number;
      }>,
    ) => {
      if (
        action.payload.scale < state.initialScale * 0.75 ||
        action.payload.scale > state.initialScale * 1.1 * MAX_SCALE
      ) {
        return;
      }

      state.scale = action.payload.scale;
    },
  },
});

const checkValidScale = createAsyncThunk(
  'page/checkValidScale',
  async (_, { dispatch }) => {
    await new Promise(() => {
      const state = store.getState().page;

      if (state.scale < state.initialScale) {
        setTimeout(() => dispatch(fitContent()), 200);
      } else if (state.scale > state.initialScale) {
        setTimeout(() => dispatch(maximizePage()), 200);
      }
    });
  },
);

const fitContent = (): AppThunk => (dispatch) => {
  dispatch(pageSlice.actions.useDefaultScale());
  dispatch(pageSlice.actions.updatePosition({ x: 0, y: 0 }));
};

const maximizePage = (): AppThunk => (dispatch) =>
  dispatch(pageSlice.actions.useMaxScale());

const adjustPage = (): AppThunk => (dispatch) => {
  const state = store.getState().page;

  if (state.scale === state.initialScale) {
    dispatch(pageSlice.actions.updatePosition({ x: 0, y: 0 }));
  }
};

const fillBoundary = (): AppThunk => (dispatch) => {
  const state = store.getState().page;
  dispatch(pageSlice.actions.findDefaultScale());

  if (state.scale === state.initialScale) {
    dispatch(pageSlice.actions.useDefaultScale());
    dispatch(pageSlice.actions.updatePosition({ x: 0, y: 0 }));
  }
};

export const pageReducer = pageSlice.reducer;

export const usePageActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          ...pageSlice.actions,
          adjustPage,
          fillBoundary,
          fitContent,
          maximizePage,
          checkValidScale,
        },
        dispatch,
      ),
    [dispatch],
  );
};
