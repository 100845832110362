import { FormWrapper } from '@nowadays/ui/shared';

import LoginForm from './components/LoginForm';

const Login = () => {
  return (
    <FormWrapper title='admin.login.title'>
      <LoginForm />
    </FormWrapper>
  );
};

export default Login;
