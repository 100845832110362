import {
  WaterIntakeChartDatum,
  WaterIntakeChartDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const waterIntakeChartDatum = (builder: Builder) =>
  builder.query<WaterIntakeChartDatum, WaterIntakeChartDatumParams>({
    query: (params) => ({ url: '/datum/water-intake-chart-datum', params }),
    providesTags: ['WaterIntakeChart'],
  });
