import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgHide = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M12 14a1 1 0 1 0 0 2zm4-2a1 1 0 1 0-2 0zM3.22 10.125l-.812-.583zm0 3.75.813-.584zm2.568 3.018.75-.661-.025-.029-.027-.026zM18.101 7l-.691.722.028.028.03.024zM2.337 12h-1zm18.442 1.875.813.583zm0-3.75-.812.584zm-.19-1.892a1 1 0 1 0-1.549 1.265zM8.083 17.529a1 1 0 0 0-1.166 1.625zM21.663 12h-1zM1.375 19.22a1 1 0 0 0 1.25 1.56zm21.25-14.44a1 1 0 1 0-1.25-1.56zm-4.45 2.28.624.781zM5.828 16.938l.624.78zM12 8a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2zm-4 4c0 .94.325 1.806.868 2.488l1.565-1.245A2 2 0 0 1 10 12zm7.122-2.5A4 4 0 0 0 12 8v2c.631 0 1.194.291 1.562.75zM12 16a4 4 0 0 0 4-4h-2a2 2 0 0 1-2 2zm0-13C9.777 3 7.786 4.099 6.193 5.402c-1.603 1.313-2.91 2.922-3.785 4.14l1.625 1.167c.823-1.147 2.012-2.6 3.427-3.76C8.887 5.783 10.437 5 12 5zM2.408 14.457c.667.93 1.581 2.08 2.682 3.152l1.396-1.433c-.992-.965-1.83-2.017-2.453-2.885zm16.385-8.18C17.045 4.606 14.689 3 12 3v2c1.905 0 3.78 1.162 5.41 2.722zM2.408 9.543c-.542.756-1.071 1.434-1.071 2.458h2c0-.264.06-.406.696-1.291zm1.625 3.75c-.636-.886-.696-1.028-.696-1.292h-2c0 1.024.528 1.701 1.071 2.457l1.625-1.166ZM12 21c2.223 0 4.214-1.099 5.807-2.402 1.603-1.313 2.91-2.922 3.785-4.14l-1.625-1.167c-.823 1.146-2.012 2.6-3.427 3.76C15.113 18.217 13.563 19 12 19zm9.592-11.458a26 26 0 0 0-1.003-1.31L19.04 9.499c.347.425.658.835.927 1.21zM6.917 19.154C8.365 20.194 10.097 21 12 21v-2c-1.334 0-2.66-.568-3.917-1.47zm14.675-4.696c.543-.756 1.071-1.434 1.071-2.458h-2c0 .264-.06.406-.696 1.291zm-1.625-3.75c.636.886.696 1.028.696 1.292h2c0-1.024-.529-1.702-1.071-2.458zm-1.168-2.867 3.826-3.06-1.25-1.562-3.825 3.06 1.25 1.562Zm-1.33-.067.073.06 1.265-1.548-.073-.06zM2.624 20.781l3.827-3.062-1.249-1.562-3.828 3.062zm3.827-3.062L18.8 7.84l-1.25-1.56-12.347 9.877 1.25 1.562Zm-1.415-.165.04.045 1.501-1.322-.04-.045-1.5 1.322Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHide);
export default ForwardRef;
