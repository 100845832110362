export const RECURRENCES = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
] as const;

export type Recurrences = (typeof RECURRENCES)[number];

export const BADGE_COLOR = [
  'blue',
  'red',
  'orange',
  'green',
  'pink',
  'purple',
  'muted',
  'inverted',
] as const;

export type BadgeColor = (typeof BADGE_COLOR)[number];

export const BADGE_COLORS = {
  background: {
    blue: 'bg-skin-blue',
    green: 'bg-skin-green',
    red: 'bg-skin-red',
    orange: 'bg-skin-orange',
    pink: 'bg-skin-pink',
    purple: 'bg-skin-purple',
    muted: 'bg-skin-muted',
    inverted: 'bg-skin-inverted',
  },
  text: {
    blue: 'text-skin-blue',
    green: 'text-skin-green',
    red: 'text-skin-red',
    orange: 'text-skin-orange',
    pink: 'text-skin-pink',
    purple: 'text-skin-purple',
    primary: 'text-skin-primary',
    muted: 'text-skin-muted',
    inverted: 'text-skin-primary',
  },
  fill: {
    blue: 'fill-skin-blue',
    green: 'fill-skin-green',
    red: 'fill-skin-red',
    orange: 'fill-skin-orange',
    pink: 'fill-skin-pink',
    purple: 'fill-skin-purple',
    muted: 'fill-skin-muted',
    inverted: 'fill-skin-primary',
  },
  icon: {
    blue: 'text-skin-inverted',
    red: 'text-skin-inverted',
    orange: 'text-skin-inverted',
    green: 'text-skin-inverted',
    pink: 'text-skin-inverted',
    purple: 'text-skin-inverted',
    muted: 'text-skin-primary',
    inverted: 'text-skin-inverted',
  },
  border: {
    blue: 'border-skin-blue',
    green: 'border-skin-green',
    red: 'border-skin-red',
    orange: 'border-skin-orange',
    pink: 'border-skin-pink',
    purple: 'border-skin-purple',
    muted: 'border-skin-muted',
    inverted: 'border-skin-primary',
  },
};
