'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { useState } from 'react';

import { ImageSliderThumbnailProps } from './ImageSliderThumbnail.types';

const ImageSliderThumbnail: React.FC<ImageSliderThumbnailProps> = ({
  src,
  index,
  selected,
  loading,
  className,
  custom,
  onClick,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const onLoad = () => setLoaded(true);

  const isLoading = loading || !loaded;

  return custom ? (
    custom({ index, src, selected, handleSelect: onClick })
  ) : (
    <div
      className={classNames(
        styles.root,
        isLoading && styles.loading,
        !isLoading && styles.loaded,
        !isLoading && selected && styles.selected,
        className,
      )}
    >
      <div className={styles.container}>
        <img
          src={src}
          alt=''
          onLoad={onLoad}
          onClick={onClick}
          className={styles.img}
        />
      </div>
    </div>
  );
};

const styles = {
  root: classes(
    'relative',
    'min-w-[4rem]',
    'min-h-[4rem]',
    'lg:min-w-[7rem]',
    'lg:min-h-[7rem]',
    'rounded-md',
    'box-content',
    'border',
    'border-transparent',
    'overflow-hidden',
    'aspect-[1.5]',
  ),
  container: classes('absolute inset-0'),
  loading: classes('bg-skin-base', 'animate-pulse'),
  loaded: classes('cursor-pointer'),
  selected: classes('border-skin-accent'),
  img: classes('object-contain'),
};

export default ImageSliderThumbnail;
