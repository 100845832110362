import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgRainy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m7.813 18.23.693-1.384m5.538 1.384.692-1.384M10.236 21l.693-1.385M4.006 21l.692-1.385M16.467 21l.693-1.385m-.693-6.23a3.461 3.461 0 0 0 0-6.923 3.5 3.5 0 0 0-2.174.761 5.192 5.192 0 1 0-5.095 6.162h7.27Z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRainy);
export default ForwardRef;
