'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { RichTextContentProps } from './RichTextContent.types';

const RichTextContent: React.FC<RichTextContentProps> = ({
  content,
  className,
  ...props
}) => {
  return (
    <div
      {...(content && { dangerouslySetInnerHTML: { __html: content } })}
      className={classNames(styles.root, styles.heading, styles.img, className)}
      {...props}
    />
  );
};

const styles = {
  root: classes('w-full', 'h-full', 'min-w-full', 'prose', 'dark:prose-invert'),
  heading: classes(
    'prose-h1:text-4xl',
    'sm:prose-h1:text-5xl',
    'prose-headings:text-skin-accent',
    'prose-headings:font-serif',
  ),
  img: classes('prose-img:rounded-md'),
};

export default RichTextContent;
