import { PagePeriod } from '@nowadays/base/types';
import { Buffer } from 'buffer';

type PageParam = {
  planner: string;
  period: PagePeriod;
  date: string;
};

export const encodePageParam = ({ planner, period, date }: PageParam) => {
  return Buffer.from(JSON.stringify({ planner, period, date })).toString(
    'base64',
  );
};

export const decodePageParam = (param: string): PageParam => {
  try {
    return JSON.parse(
      Buffer.from(param, 'base64').toString('ascii'),
    ) as PageParam;
  } catch {
    return undefined;
  }
};
