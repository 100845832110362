import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgPen = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m9.531 19.02-.364-.932zm-4.056.779-.5.866zM4.12 15.896l-.988.151zm.49-2.602.866.5zm-.584 1.237-.982-.185zm7.513 2.763.866.5zm-.78 1.125.652.758zM8.41 6.715l-.866-.5zm11 14.536a1 1 0 1 0 0-2zm-6-2a1 1 0 1 0 0 2zm1.062-9.036-3.798 6.58 1.732 1 3.798-6.58zm-8.995 3.58 3.799-6.58-1.732-1-3.799 6.58zm3.69 4.293c-1.103.432-1.83.713-2.385.843-.529.123-.707.06-.807.002l-1 1.732c.732.423 1.508.39 2.262.213.728-.17 1.613-.518 2.658-.927zm-6.034-2.041c.169 1.11.31 2.05.526 2.766.224.74.583 1.429 1.316 1.852l1-1.732c-.1-.058-.244-.18-.402-.7-.165-.545-.285-1.316-.463-2.487zm.612-3.253c-.313.543-.599 1.011-.7 1.552l1.965.37c.024-.128.086-.261.467-.922zm1.365 2.952c-.115-.754-.124-.9-.1-1.03l-1.965-.37c-.102.541-.007 1.082.088 1.701zm5.564 1.048c-.382.66-.466.78-.566.866l1.304 1.517c.417-.358.68-.84.994-1.383zm-.779 3.157c.584-.228 1.1-.415 1.517-.774l-1.304-1.517c-.099.085-.23.15-.94.428zm3.479-13.834c.841.485 1.383.801 1.753 1.086.346.268.417.417.445.521l1.932-.517c-.187-.697-.632-1.183-1.155-1.587-.5-.386-1.183-.778-1.975-1.235zm2.83 5.098c.457-.792.852-1.472 1.093-2.057.251-.612.393-1.255.207-1.951l-1.932.517c.028.104.041.269-.125.674-.177.431-.49.976-.975 1.817zm-1.83-6.83c-.792-.457-1.472-.852-2.057-1.093-.612-.251-1.255-.393-1.951-.207l.517 1.932c.104-.028.269-.041.674.125.431.177.976.49 1.817.975zm-5.098 2.83c.485-.84.801-1.383 1.086-1.753.268-.346.417-.417.521-.445l-.517-1.932c-.697.187-1.183.632-1.587 1.155-.386.5-.778 1.183-1.235 1.975zm6.562 2.634-6.928-4-1 1.732 6.928 4zm3.572 9.402h-6v2h6z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPen);
export default ForwardRef;
