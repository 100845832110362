'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { TableExpandRowProps } from './TableExpandRow.types';

const TableExpandRow: React.FC<TableExpandRowProps> = ({
  row,
  className,
  children,
}) => {
  const isExpanded = row.getIsExpanded();

  if (isExpanded) {
    return (
      <tr key={row.id} className={classNames(styles.root, className)}>
        <td colSpan={row.getVisibleCells().length}>{children}</td>
      </tr>
    );
  }

  return null;
};

const styles = {
  root: classes('border', 'border-skin-silent', 'bg-skin-base'),
};

export default TableExpandRow;
