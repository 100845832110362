import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

const Title = () => {
  const translate = useTranslate();
  return (
    <div className={styles.root}>
      {translate('admin.shared.sidebar.footer.title.text')}
    </div>
  );
};

const styles = {
  root: classes('font-semibold', 'font-serif', 'text-skin-accent'),
};

export default Title;
