import { baseApi } from '../base/query/Api';
import { addPlannerCovers } from './AddPlannerCovers.api';
import { addPlannerCustomImage } from './AddPlannerCustomImage.api';
import { addPlannerImage } from './AddPlannerImage.api';
import { changePlannerImage } from './ChangePlannerImage.api';
import { deletePlannerCover } from './DeletePlannerCover.api';
import { deletePlannerCustomImage } from './DeletePlannerCustomImage.api';
import { deletePlannerImage } from './DeletePlannerImage.api';
import { plannerCovers } from './PlannerCovers.api';
import { plannerImages } from './PlannerImages.api';
import { reorderPlannerCovers } from './ReorderPlannerCovers.api';
import { updatePlannerImage } from './UpdatePlannerImage.api';

export const plannerImageEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    plannerCovers: plannerCovers(builder),
    addPlannerCovers: addPlannerCovers(builder),
    deletePlannerCover: deletePlannerCover(builder),
    reorderPlannerCovers: reorderPlannerCovers(builder),
    plannerImages: plannerImages(builder),
    addPlannerImage: addPlannerImage(builder),
    updatePlannerImage: updatePlannerImage(builder),
    changePlannerImage: changePlannerImage(builder),
    deletePlannerImage: deletePlannerImage(builder),
    addPlannerCustomImage: addPlannerCustomImage(builder),
    deletePlannerCustomImage: deletePlannerCustomImage(builder),
  }),
});

export const {
  usePlannerCoversQuery,
  useLazyPlannerCoversQuery,
  useAddPlannerCoversMutation,
  useDeletePlannerCoverMutation,
  useReorderPlannerCoversMutation,
  usePlannerImagesQuery,
  useAddPlannerImageMutation,
  useUpdatePlannerImageMutation,
  useChangePlannerImageMutation,
  useDeletePlannerImageMutation,
  useAddPlannerCustomImageMutation,
  useDeletePlannerCustomImageMutation,
} = plannerImageEndpoints;
