import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgEraser = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m12.04 16.56-4.856-4.871m8.254-8.21L3.476 15.378a1.615 1.615 0 0 0-.003 2.287l3.124 3.123a1.25 1.25 0 0 0 1.77-.003L20.528 8.568a1.593 1.593 0 0 0 0-2.258l-2.816-2.831a1.59 1.59 0 0 0-2.275 0ZM7.483 21.113h10.23'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEraser);
export default ForwardRef;
