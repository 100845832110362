'use client';

import { classes } from '@nowadays/ui/utils';

const MockupEmpty = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 863 645'
      className={styles.root}
    >
      <g clipPath='url(#a)'>
        <path
          fill='#9E616A'
          d='M629.943 612.644h-17.166l-8.169-66.209h25.335v66.209Z'
        />
        <path
          fill='#3C3C3A'
          d='M639.003 642.385h-12.31l-2.198-11.622-5.627 11.622h-32.649c-1.565 0-3.089-.5-4.35-1.427a7.347 7.347 0 0 1-2.657-3.728 7.331 7.331 0 0 1 .069-4.577 7.333 7.333 0 0 1 2.768-3.646L608.121 611v-11.75l27.424 1.637 3.458 41.498Z'
        />
        <path
          fill='#9E616A'
          d='m731.923 590.981-13.775 10.243-46.063-48.255 20.33-15.118 39.508 53.13Z'
        />
        <path
          fill='#3C3C3A'
          d='m756.94 609.441-9.878 7.345-8.699-8.015 2.42 12.685-26.2 19.482a7.336 7.336 0 0 1-8.699.044 7.337 7.337 0 0 1-2.63-8.292l10.177-30.007-7.011-9.428 22.983-15.051 27.537 31.237ZM649.927 271.04s32.569 28.138 17.542 108.352l-18.345 78.597 59.829 99.256-19.076 23.207-77.78-107.433-28.185-66.114-7.909-117.669 73.924-18.196Z'
        />
        <path
          fill='#3C3C3A'
          d='m599.447 425.746-1.959 30.338 5.995 129.281 28.209-4.913 5.391-92.046-37.636-62.66Z'
        />
        <path
          fill='#FFB8B8'
          d='m237.445 628.211 15.351-.001 7.302-59.209-22.655.001.002 59.209Z'
        />
        <path
          fill='#3C3C3A'
          d='M233.53 623.199h4.32l16.869-6.86 9.041 6.859h.002a19.265 19.265 0 0 1 19.265 19.265v.626l-49.497.002v-19.892Z'
        />
        <path
          fill='#FFB8B8'
          d='m296.932 618.538 14.973 3.38 20.166-56.146-22.099-4.99-13.04 57.756Z'
        />
        <path
          fill='#3C3C3A'
          d='m294.216 612.786 4.215.952 17.966-2.976 7.308 8.682.001.001a19.265 19.265 0 0 1 14.549 23.035l-.138.611-48.282-10.901 4.381-19.404Zm-76.213-346.55c-7.197 21.706-6.436 45.268 1.73 70.556l3.491 142.378s-5.201 93.369 11.582 126.225h24.34l12.051-134.752 1.513-90.446 52.183 76.306-33.237 146.689 29.957 2.678 53.934-159.191-66.544-154.443-91-26Z'
        />
        <path
          fill='#E26B48'
          d='M498.698 204.408c18.617-16.777 46.309-25.212 69.537-15.805a115.464 115.464 0 0 0-51.888 59.935c-3.698 9.838-6.787 21.166-15.882 26.433-5.659 3.278-12.7 3.438-19.046 1.856-6.345-1.583-12.162-4.755-17.899-7.895l-1.632-.036c7.328-23.966 18.192-47.712 36.81-64.488Z'
        />
        <path
          fill='#E26B48'
          d='M568.105 189.112a98.695 98.695 0 0 0-54.906 25.931 42.515 42.515 0 0 0-8.346 10.377 24.374 24.374 0 0 0-2.817 12.515c.1 4.059.673 8.198-.215 12.21a14.913 14.913 0 0 1-7.424 9.689c-4.546 2.613-9.76 3.437-14.886 4.065-5.692.698-11.615 1.332-16.542 4.525-.597.387-1.163-.562-.567-.949 8.573-5.554 19.42-3.533 28.638-7.24 4.301-1.73 8.106-4.766 9.454-9.357 1.177-4.015.59-8.284.453-12.392a26.023 26.023 0 0 1 2.299-12.341 39.299 39.299 0 0 1 7.916-10.659 95.767 95.767 0 0 1 24.333-17.42 100.443 100.443 0 0 1 32.6-10.058c.703-.091.709 1.014.01 1.104Z'
        />
        <path
          fill='#E26B48'
          d='M517.799 210.184a14.805 14.805 0 0 1 1.632-19.104c.506-.498 1.305.265.798.764a13.715 13.715 0 0 0-1.482 17.774c.415.577-.535 1.14-.948.566Zm-16.085 25.7a28.538 28.538 0 0 0 20.394-4.083c.598-.385 1.163.564.566.948a29.681 29.681 0 0 1-21.23 4.206c-.701-.126-.427-1.196.27-1.071Zm44.084-41.543a8.383 8.383 0 0 0 6.269 4.894c.702.118.427 1.188-.27 1.071a9.386 9.386 0 0 1-6.947-5.399.57.57 0 0 1 .191-.757.551.551 0 0 1 .757.191Zm48.015 75.166c-.441-.06-.881-.121-1.328-.175a110.324 110.324 0 0 0-17.882-.909c-.462.007-.931.021-1.392.041a116.364 116.364 0 0 0-41.75 9.61 113.05 113.05 0 0 0-15.163 8.056c-6.687 4.234-13.602 9.357-21.078 11.084a19.31 19.31 0 0 1-2.362.421l-30.889-26.745c-.039-.096-.085-.186-.125-.282l-1.282-1.012c.238-.175.49-.352.728-.528.138-.102.283-.199.421-.301.095-.066.189-.132.27-.198.032-.022.063-.044.088-.059.082-.065.164-.117.239-.176a358.168 358.168 0 0 1 4.235-2.954c.006-.007.006-.007.019-.008a166.155 166.155 0 0 1 34.601-18.6c.367-.138.74-.284 1.121-.411a107.816 107.816 0 0 1 16.939-4.766 95.45 95.45 0 0 1 9.553-1.335 79.258 79.258 0 0 1 24.723 1.752c16.143 3.743 30.91 12.608 39.656 26.433.224.353.441.702.658 1.062Z'
        />
        <path
          fill='#E26B48'
          d='M593.404 269.839a98.7 98.7 0 0 0-59.452-12.353 42.487 42.487 0 0 0-12.91 3.261 24.375 24.375 0 0 0-9.785 8.296c-2.363 3.301-4.398 6.951-7.523 9.62a14.914 14.914 0 0 1-11.761 3.266c-5.203-.65-9.862-3.132-14.333-5.716-4.965-2.87-10.076-5.93-15.933-6.347-.709-.051-.589-1.149.119-1.098 10.189.726 17.633 8.87 27.225 11.46 4.476 1.208 9.342 1.075 13.182-1.779 3.358-2.496 5.459-6.259 7.823-9.622a26.024 26.024 0 0 1 9.265-8.469 39.327 39.327 0 0 1 12.738-3.745 95.752 95.752 0 0 1 29.917.742 100.428 100.428 0 0 1 32.085 11.596c.616.351-.045 1.237-.657.888Z'
        />
        <path
          fill='#E26B48'
          d='M540.551 256.376a14.802 14.802 0 0 1 3.781-9.487 14.798 14.798 0 0 1 9.024-4.783c.704-.094.883.997.177 1.09a13.715 13.715 0 0 0-11.884 13.299c-.016.711-1.114.588-1.098-.119Zm-28.317 10.836a28.54 28.54 0 0 0 18.742 9.018c.71.053.59 1.151-.119 1.098a29.678 29.678 0 0 1-19.483-9.424c-.484-.522.38-1.212.86-.692Zm60.211-6.628a8.38 8.38 0 0 0 2.059 7.682c.49.516-.374 1.205-.861.692a9.383 9.383 0 0 1-2.296-8.493.57.57 0 0 1 .609-.49.557.557 0 0 1 .489.609ZM51.273 627.136c-1.45 5.44-5.26 9.97-9.86 13.27-.75.54-1.52 1.04-2.3 1.51-.24.14-.48.29-.73.42-.27.16-.54.31-.81.45h-21.63c-.39-.79-.77-1.59-1.15-2.38-9.27-19.48-15.78-40.5-14.67-61.91a79.251 79.251 0 0 1 5.17-24.25c5.94-15.47 16.78-28.86 31.69-35.6.37-.17.76-.34 1.14-.5l-.36 1.28a110.8 110.8 0 0 0-3.38 17.59c-.06.46-.11.92-.15 1.39a116.057 116.057 0 0 0 3.72 42.69l.03.1a105.2 105.2 0 0 0 2.96 9.07c.88 2.35 1.83 4.67 2.87 6.95 4.34 9.57 10.16 19.77 7.46 29.92Z'
        />
        <path
          fill='#E26B48'
          d='M38.393 518.596c-.21.28-.42.55-.63.83a98.136 98.136 0 0 0-11.12 18.76c-.16.33-.31.66-.44 1a97.81 97.81 0 0 0-7.82 29.24 1.479 1.479 0 0 0-.02.21c-.25 2.36-.4 4.74-.46 7.12a42.48 42.48 0 0 0 1.43 13.24 23.768 23.768 0 0 0 5.46 9.42c.25.27.5.54.77.8.2.21.42.42.63.62 2.02 1.93 4.23 3.72 6.13 5.79a21.345 21.345 0 0 1 2.35 3 14.9 14.9 0 0 1 1.6 12.1c-1.36 5.06-4.47 9.33-7.65 13.4-1.59 2.04-3.23 4.1-4.65 6.28-.52.78-1 1.57-1.44 2.38h-1.26c.42-.81.88-1.6 1.38-2.38 3.65-5.75 8.84-10.69 11.53-17.02 1.82-4.27 2.37-9.11.07-13.3a17.657 17.657 0 0 0-2.43-3.38c-1.83-2.07-4.02-3.84-6.01-5.71-.5-.47-.99-.95-1.46-1.45a24.969 24.969 0 0 1-5.64-8.9 39.234 39.234 0 0 1-1.94-13.13c0-2.84.15-5.7.43-8.54.03-.36.07-.73.11-1.1a100.765 100.765 0 0 1 19.67-49.23c.2-.28.41-.55.62-.82.43-.56 1.22.22.79.77Z'
        />
        <path
          fill='#E26B48'
          d='M17.717 569.07a14.802 14.802 0 0 1-12.354-14.662.552.552 0 0 1 1.104-.024A13.712 13.712 0 0 0 17.987 568c.702.114.427 1.184-.27 1.07Zm6.799 29.547a28.538 28.538 0 0 0 11.533-17.308c.15-.695 1.222-.424 1.07.27a29.681 29.681 0 0 1-12.037 17.986c-.585.407-1.148-.544-.566-.948Zm1.796-60.548a8.386 8.386 0 0 0 7.893-.972c.58-.413 1.143.538.567.948a9.388 9.388 0 0 1-8.73 1.095.573.573 0 0 1-.4-.671.554.554 0 0 1 .67-.4Zm87.101 19.197c-.35.27-.71.54-1.06.82a110.421 110.421 0 0 0-13.29 12c-.32.33-.64.67-.95 1.01l-.01.01a116.347 116.347 0 0 0-22.66 36.14l-.03.09c-.01.03-.02.05-.03.08a114.451 114.451 0 0 0-5.03 16.42c-1.22 5.46-2.22 11.31-4.13 16.57-.29.81-.61 1.61-.95 2.38h-44.46c.15-.79.31-1.59.47-2.38a160.308 160.308 0 0 1 10.54-33.7c.16-.36.32-.72.5-1.08a108.318 108.318 0 0 1 8.61-15.35v-.004l.003-.003.003-.002.004-.001a95.017 95.017 0 0 1 5.8-7.69 79.113 79.113 0 0 1 18.72-16.24c.04-.03.09-.05.13-.08 14.04-8.71 30.68-12.86 46.59-9.27h.01c.41.09.81.18 1.22.28Z'
        />
        <path
          fill='#E26B48'
          d='M113.363 557.796c-.34.09-.68.19-1.01.29a98.552 98.552 0 0 0-20.17 8.27c-.32.17-.64.35-.96.53a98.262 98.262 0 0 0-23.79 18.59.033.033 0 0 0-.01.02c-.08.08-.17.17-.24.25a90.03 90.03 0 0 0-4.6 5.35 42.765 42.765 0 0 0-6.82 11.43 23.673 23.673 0 0 0-1.31 10.81c.03.37.08.73.13 1.1.04.29.08.58.13.88.66 4.01 1.8 8.03 1.48 12.12a14.905 14.905 0 0 1-6.01 10.63 23.814 23.814 0 0 1-3.68 2.34 36.88 36.88 0 0 1-5.77 2.38h-3.93l1.58-.45a48.235 48.235 0 0 0 5.53-1.93 26.984 26.984 0 0 0 3-1.48c4.02-2.31 7.37-5.85 8.07-10.58.61-4.14-.57-8.28-1.27-12.33-.12-.7-.23-1.39-.29-2.08a24.44 24.44 0 0 1 .85-10.46 39.066 39.066 0 0 1 6.36-11.66 83.23 83.23 0 0 1 5.48-6.55c.24-.27.49-.54.75-.81a100.912 100.912 0 0 1 24.21-18.73h.01a99.301 99.301 0 0 1 21.1-8.74h.01c.33-.1.67-.2 1-.29.69-.18.85.91.17 1.1Z'
        />
        <path
          fill='#E26B48'
          d='M66.468 585.645A14.807 14.807 0 0 1 65.43 566.5c.433-.565 1.33.08.897.645a13.714 13.714 0 0 0 1 17.808c.491.513-.372 1.203-.86.692ZM54.107 613.33a28.545 28.545 0 0 0 19.63-6.876c.538-.464 1.23.397.692.861a29.688 29.688 0 0 1-20.44 7.113c-.712-.028-.59-1.126.118-1.098Zm37.889-47.263a8.376 8.376 0 0 0 6.888 3.977c.71.019.587 1.117-.12 1.098a9.391 9.391 0 0 1-7.629-4.382.572.572 0 0 1 .084-.777.554.554 0 0 1 .777.084Z'
        />
        <path
          fill='#FFB8B8'
          d='M456.383 173.13a11.598 11.598 0 0 1-17.767.837l-37.801 16.44 3.682-21.101 35.332-12.377a11.66 11.66 0 0 1 15.473.748 11.663 11.663 0 0 1 1.081 15.453Z'
        />
        <path
          fill='#E26B48'
          d='m431.157 179.718-87.797 39.108-.189-.067L243.104 183.1a32.961 32.961 0 0 1-14.781-42.756 32.928 32.928 0 0 1 20.207-17.867 32.919 32.919 0 0 1 26.78 3.231l74.468 44.859 72.212-9.359 9.167 18.51Z'
        />
        <path
          fill='#3C3C3A'
          d='M862.703 643.596a1.191 1.191 0 0 1-.347.842 1.188 1.188 0 0 1-.843.348H1.223a1.191 1.191 0 0 1 0-2.38h860.29a1.184 1.184 0 0 1 1.19 1.19Z'
        />
        <path
          fill='#E26B48'
          d='m313.343 296.796-88.505-14.156A16.903 16.903 0 0 1 213.106 266a16.9 16.9 0 0 1 1.776-7.006l4.014-8.029-1.559-84.346a62.484 62.484 0 0 1 30.336-54.654l8.631-5.161 4.362-11.076 40.22.98.117 14.528 14.404 22.968-.001.095-.903 125.014-3.97 12.901 6.002 15.006-3.192 9.576Z'
        />
        <path
          fill='#FFB8B8'
          d='M284.459 81.954c20.183 0 36.544-16.361 36.544-36.544 0-20.183-16.361-36.544-36.544-36.544-20.183 0-36.544 16.361-36.544 36.544 0 20.183 16.361 36.544 36.544 36.544Z'
        />
        <path
          fill='#3C3C3A'
          d='M246.406 53.377c-1.09-4.592-.59-11.054.026-15.677 1.615-12.122 8.346-23.645 18.573-30.47a13.381 13.381 0 0 1 6.665-2.65c2.419-.1 5.042 1.195 5.785 3.5a11.992 11.992 0 0 1 6.765-6.71 21.136 21.136 0 0 1 9.631-1.297 35.2 35.2 0 0 1 29.363 20.99c.976 2.319 3.702-6.246 4.939-4.056a9.742 9.742 0 0 0 5.524 4.854c2.423.676 3.407 10.66 4.361 8.332a11.1 11.1 0 0 1-10.61 15.475c-2.467-.092-4.825-1-7.262-1.39-8.716-1.396-17.96 4.923-19.824 13.55a23.983 23.983 0 0 0-3.155-7.02 8.123 8.123 0 0 0-6.513-3.579c-2.48.093-4.66 1.714-6.268 3.603-1.609 1.89-2.818 4.093-4.438 5.972-4.756 5.513-11.188 18.37-17.965 17.432-5.348-.74-14.109-14.59-15.597-20.86Z'
        />
        <path
          fill='#E26B48'
          d='M505.364 214.541a7.133 7.133 0 0 0-4.807-7.854l-98.413-32.777a7.136 7.136 0 0 0-2.933-.337l-24.667 2.333-14.154 1.342-26.118 2.469a7.15 7.15 0 0 0-6.384 5.989l-13.261 82.838a7.184 7.184 0 0 0 4.484 7.796l99.44 38.384c.467.182.952.312 1.447.389.72.116 1.454.122 2.175.016l64.256-9.523a7.122 7.122 0 0 0 6.023-6l12.912-85.065Z'
        />
        <path
          fill='#EBE9E5'
          d='m321.365 270.503 99.44 38.382a.892.892 0 0 0 .457.054l64.247-9.522a.886.886 0 0 0 .755-.752l12.92-85.067a.903.903 0 0 0-.6-.981l-.661-.224-97.758-32.546a.637.637 0 0 0-.137-.033.887.887 0 0 0-.23-.012l-60.165 5.693-4.774.448a.904.904 0 0 0-.795.748l-13.259 82.834a.9.9 0 0 0 .56.978Z'
        />
        <path
          fill='#3C3C3A'
          d='m339.634 185.495 60.164-5.693a.887.887 0 0 1 .23.012.637.637 0 0 1 .137.033l97.758 32.546-25.787 2.73-9.65 1.016-27.461 2.902c-.08.004-.16.003-.24-.003a3.221 3.221 0 0 0-.157-.045l-94.994-33.498Z'
        />
        <path
          fill='#3C3C3A'
          d='m319.109 276.345 99.44 38.382c.468.181.955.308 1.451.38.72.119 1.454.128 2.177.027l64.247-9.522a7.14 7.14 0 0 0 6.029-6.004l12.909-85.067a7.187 7.187 0 0 0-.418-3.717 6.23 6.23 0 0 0-.207-.462 6.98 6.98 0 0 0-2.264-2.697 7.114 7.114 0 0 0-1.915-.977l-.117-.041-98.292-32.738a8.883 8.883 0 0 0-1.227-.298 7.08 7.08 0 0 0-1.715-.033l-24.663 2.324-14.152 1.35-26.123 2.467a7.105 7.105 0 0 0-3.019.997 1.339 1.339 0 0 0-.202.122 1.029 1.029 0 0 0-.13.098 7.149 7.149 0 0 0-3.027 4.763l-13.27 82.844a7.194 7.194 0 0 0 4.488 7.802Zm10.543-90.357a5.298 5.298 0 0 1 1.269-2.671 4.68 4.68 0 0 1 .676-.659 5.33 5.33 0 0 1 2.833-1.156l43.985-4.155 20.964-1.995c.143-.016.273-.014.404-.022.318-.006.635.017.948.071.288.037.571.105.845.201l98.408 32.779c.078.027.145.054.223.082a5.205 5.205 0 0 1 2.273 1.653 5.265 5.265 0 0 1 1.121 4.146l-12.921 85.076a5.347 5.347 0 0 1-4.508 4.502l-64.257 9.521a5.414 5.414 0 0 1-2.723-.31l-99.441-38.372a5.404 5.404 0 0 1-3.359-5.846l13.26-82.845Z'
        />
        <path
          fill='#3C3C3A'
          d='M330.704 181.387a.875.875 0 0 1 .397-.474c.041-.016.084-.042.126-.058a.872.872 0 0 1 .624-.011l2.07.73 101.115 35.669 23.665-2.5 13.243-1.397 28.03-2.967 2.506-.263.487-.054a.908.908 0 0 1 .952.654.894.894 0 0 1-.523 1.065 1.011 1.011 0 0 1-.248.067l-3.409.357-27.603 2.918-9.65 1.016-27.461 2.902a1.94 1.94 0 0 1-.241-.003 2.876 2.876 0 0 0-.156-.045l-103.031-36.335-.35-.124a.663.663 0 0 1-.219-.121.914.914 0 0 1-.324-1.026Z'
        />
        <path
          fill='#3C3C3A'
          d='M420.271 315.368a.896.896 0 0 1-.743-1.016l14.517-96.334a.89.89 0 0 1 1.017-.751l.008.002a.888.888 0 0 1 .58.355c.139.19.198.427.162.66l-14.516 96.334a.894.894 0 0 1-1.017.751l-.008-.001Zm36.797-6.112-9.655 1.019 11.293-95.534s12.895-2.335 13.24-1.399c.212.559-14.786 95.302-14.878 95.914Z'
        />
        <path
          fill='#3C3C3A'
          d='m331.25 182.533-.26 43.567 77.126 29.388 27.697-37.204-104.563-35.751Zm171.233 27.585a5.302 5.302 0 0 0-2.497-1.737l-98.406-32.778a5.041 5.041 0 0 0-.848-.206 4.96 4.96 0 0 0-.95-.071l.159-.998.986-.713 23.368-16.919 78.04 23.917.136 27.052.012 2.453Z'
        />
        <path
          fill='#E26B48'
          d='M335.181 252.472c.11.011.22.034.326.069l30.193 9.917a1.501 1.501 0 0 1 .21 2.764 1.499 1.499 0 0 1-1.145.086l-30.194-9.916a1.5 1.5 0 0 1 .61-2.92Zm121.822 182.764c6.628 0 12-5.373 12-12 0-6.628-5.372-12-12-12-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12Zm-306 44c6.628 0 12-5.373 12-12 0-6.628-5.372-12-12-12-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12Zm250-397c6.628 0 12-5.373 12-12 0-6.628-5.372-12-12-12-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12Z'
        />
        <path
          fill='#FFB8B8'
          d='M420.692 270.121a11.591 11.591 0 0 1-9.811-.264 11.6 11.6 0 0 1-6.096-7.693l-41.012-4.143 13.53-16.606 36.871 6.48a11.665 11.665 0 0 1 13.131 8.218 11.66 11.66 0 0 1-6.613 14.008Z'
        />
        <path
          fill='#E26B48'
          d='m395.467 263.534-95.709-8.819-.131-.151-69.851-80.036a32.963 32.963 0 0 1 8.013-44.524 32.92 32.92 0 0 1 38.959-.08 32.922 32.922 0 0 1 9.185 10.289l43.022 75.545 67.566 27.147-1.054 20.629Z'
        />
        <path
          fill='#3C3C3A'
          d='M635.69 109.617c-2.377-17.434-5.358-36.152-17.654-48.737a41.348 41.348 0 0 0-59.744.618c-8.951 9.55-12.903 22.957-13.265 36.04-.362 13.083 2.552 26.021 5.784 38.704a119.3 119.3 0 0 0 49.786-9.8c3.926-1.704 7.789-3.63 11.937-4.686s7.104 1.601 10.963 3.453l2.118-4.055c1.734 3.226 7.102 2.27 9.05-.832 1.947-3.103 1.52-7.076 1.025-10.705Z'
        />
        <path
          fill='#9E616A'
          d='m567.884 206.925-69.876 1.495a11.05 11.05 0 0 0-10.768-5.454 11.057 11.057 0 0 0-9.339 7.647 11.058 11.058 0 0 0 8.936 14.28c2.12.311 4.285-.002 6.231-.899 9.267.526 81.772 10.817 86.097 4.185 4.391-6.731 27.825-30.486 27.825-30.486l-18.013-25.644-21.093 34.876Zm17.027-80.06c18.132 0 32.83-14.698 32.83-32.83 0-18.131-14.698-32.83-32.83-32.83-18.132 0-32.83 14.699-32.83 32.83 0 18.132 14.698 32.83 32.83 32.83Z'
        />
        <path
          fill='#3C3C3A'
          d='M637.494 157.204c-3.869-7.7-5.749-17.212-13.997-19.708-5.57-1.686-28.097.84-33.173 3.686-8.444 4.733-.792 13.602-5.774 21.902-5.415 9.022-20.132 27.129-25.547 36.152-3.723 6.203 8.817 24.41 6.804 31.358-2.012 6.949-2.109 14.747 1.32 21.117 3.069 5.702-1.372 10.745 1.715 16.437 3.21 5.92 7.148 28.053 4.161 34.088l-2 6c19.847 1.166 36.535-22.544 56.258-25.042 4.899-.62 9.986-1.43 14.023-4.274 5.946-4.189 8.297-11.789 9.766-18.913a159.326 159.326 0 0 0-13.556-102.803Z'
        />
        <path
          fill='#9E616A'
          d='M667.249 238.505c-2.764-7.545-7.769-40.536-7.769-40.536l-31.324-.919 15.315 37.772-41.791 58.503.218.358A11.044 11.044 0 0 0 590.617 299a11.053 11.053 0 0 0 .855 12.442 11.053 11.053 0 0 0 19.696-6.014.766.766 0 0 0 .958-.165c4.65-5.835 57.888-59.212 55.123-66.758Z'
        />
        <path
          fill='#3C3C3A'
          d='M670.434 217.67c-2.875-12.134-5.771-24.335-10.619-35.825-4.847-11.49-11.786-22.343-21.546-30.106-3.121-2.481-6.609-4.672-10.521-5.443-3.912-.772-8.32.091-11.067 2.981-4.396 4.623-3.073 12.045-1.461 18.218l14.263 54.601 41.188-4.366-.237-.06ZM625.139 98.589c-1.209-7.942-2.472-15.95-5.313-23.429-2.84-7.478-7.418-14.482-13.986-18.719-10.399-6.707-23.862-5.413-35.521-1.555-9.016 2.984-17.817 7.519-24.176 14.81-6.358 7.29-9.929 17.693-7.564 27.226l37.309-8.815-1.361.962a30.045 30.045 0 0 1 16.03 20.893 31.12 31.12 0 0 1-6.565 25.848l25.445-9.027c5.235-1.857 10.838-3.997 13.943-8.76 3.599-5.523 2.763-12.843 1.759-19.434Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h862.703v644.786H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

const styles = {
  root: classes('w-full', 'h-full', 'max-w-[30rem]', 'max-h-[30rem]'),
};

export default MockupEmpty;
