'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import Switch from '../../../input/switch/Switch';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormSwitchProps } from './FormSwitch.types';

const FormSwitch: React.FC<FormSwitchProps> = ({
  field,
  label,
  checked,
  disabled,
  options,
  input,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div className={classNames(styles.root, container?.className)}>
      {label && (
        <FieldLabel className={styles.label} label={label} htmlFor={field} />
      )}
      <Controller
        control={control}
        name={field}
        defaultValue={checked || false}
        render={({ field: { value, ...rest } }) => (
          <div className={styles.toggle}>
            <Switch
              id={field}
              key={field}
              checked={value}
              disabled={isDisable}
              input={{ error: isError, disable: isDisable, ...input }}
              {...rest}
              {...props}
            />
            <Switch.Options
              htmlFor={field}
              selected={value}
              className={styles.options}
              disable={isDisable}
              {...options}
            />
          </div>
        )}
      />
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('space-x-2'),
  label: classes('select-none'),
  toggle: classes('flex', 'gap-2', 'h-8', 'my-0.5', 'items-center'),
  options: classes('text-sm', 'text-skin-muted', 'cursor-pointer'),
};

export default FormSwitch;
