import { Button } from '@nowadays/ui/components';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { useLogoutMutation } from '@nowadays/ui/services';
import { useAuth } from '@nowadays/ui/shared';
import { classes } from '@nowadays/ui/utils';
import { useEffect } from 'react';

const LogoutButton = () => {
  const { logout } = useAuth();
  const translate = useTranslate();
  const [logoutUser, { isSuccess, isLoading }] = useLogoutMutation();

  useEffect(() => {
    if (isSuccess) {
      logout();
    }
  }, [isSuccess]);

  const handleLogout = async () => await logoutUser();

  return (
    <Button
      className={styles.root}
      startIcon={{ name: 'Logout' }}
      loading={isLoading}
      onClick={handleLogout}
    >
      {translate('admin.shared.sidebar.footer.logout.title')}
    </Button>
  );
};

const styles = {
  root: classes('w-full', 'justify-start'),
};

export default LogoutButton;
