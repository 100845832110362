'use client';

import './Picker.styles.css';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { IconButton } from '../../../button';
import Button from '../../../button/button/Button';
import Popover from '../../../popover/popover/Popover';
import { Tooltip } from '../../../tooltip';
import { Title } from '../../../typography';
import { useColorPicker } from '../context/ColorPickerContext';
import { PickerHeaderProps } from './PickerHeader.types';
import PickerPanel from './PickerPanel';

const PickerHeader: React.FC<PickerHeaderProps> = ({ transparent }) => {
  const translate = useTranslate();
  const { changeColor } = useColorPicker();

  const handleTransparent = () => changeColor('');

  return (
    <div className={styles.root}>
      <Title className={styles.title}>
        {translate('ui.components.colorPicker.preset')}
      </Title>

      <div className={styles.container}>
        {transparent && (
          <Tooltip title={translate('ui.components.colorPicker.transparent')}>
            <IconButton
              color='base'
              variant='text'
              name='Transparent'
              className={styles.remove}
              onClick={handleTransparent}
            />
          </Tooltip>
        )}

        <Popover>
          {({ close }) => (
            <Fragment>
              <Popover.Button
                as={Button}
                color='silent'
                variant='contained'
                className={styles.button}
                endIcon={{ name: 'ColorPicker' }}
              >
                {translate('ui.components.colorPicker.select')}
              </Popover.Button>

              <PickerPanel close={close} />
            </Fragment>
          )}
        </Popover>
      </div>
    </div>
  );
};

const styles = {
  root: classes('flex', 'justify-between', 'items-center'),
  title: classes('text-sm'),
  container: classes('flex', 'items-center', 'gap-1'),
  button: classes('text-xs', 'py-1', 'px-2'),
  remove: classes('p-1'),
};

export default PickerHeader;
