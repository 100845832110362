'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import Radio from '../../../input/radio/Radio';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormRadioProps } from './FormRadio.types';

const FormRadio: React.FC<FormRadioProps> & {
  Option: typeof Radio.Option;
} = ({
  field,
  label,
  value,
  className,
  disabled,
  input,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}
      <Controller
        name={field}
        control={control}
        defaultValue={value || null}
        render={({ field: { value, ...rest } }) => (
          <Radio
            id={field}
            key={field}
            value={value}
            disabled={isDisable}
            className={className}
            input={{ error: isError, disable: isDisable, ...input }}
            {...rest}
            {...props}
          />
        )}
      />
      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

FormRadio.Option = Radio.Option;

export default FormRadio;
