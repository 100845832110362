'use client';

import {
  Dialog as HeadlessDialog,
  Transition as HeadlessTransition,
} from '@headlessui/react';
import {} from '@headlessui/react';
import { classes, classNames } from '@nowadays/ui/utils';
import React from 'react';
import { Fragment } from 'react';

import DialogBackdrop from './dialog-backdrop/DialogBackdrop';
import { DialogProvider } from './dialog-context/DialogProvider';
import DialogDescription from './dialog-description/DialogDescription';
import DialogFooter from './dialog-footer/DialogFooter';
import DialogPanel from './dialog-panel/DialogPanel';
import DialogTitle from './dialog-title/DialogTitle';
import { DialogBaseProps } from './DialogBase.types';

const DialogBase: React.FC<DialogBaseProps> & {
  Panel: typeof DialogPanel;
  Title: typeof DialogTitle;
  Description: typeof DialogDescription;
  Footer: typeof DialogFooter;
  Backdrop: typeof DialogBackdrop;
} = ({ open, onClose, children, level = 0, className, style, ...props }) => {
  const handleClose = () => onClose && onClose();

  const zIndex = 1600 + level * 10;

  return (
    <DialogProvider onClose={handleClose} zIndex={zIndex}>
      <HeadlessTransition appear show={open || false} as={Fragment}>
        <HeadlessDialog
          as='div'
          onClose={handleClose}
          className={classNames(styles.root, className)}
          style={{ zIndex, ...style }}
          {...props}
        >
          {children}
        </HeadlessDialog>
      </HeadlessTransition>
    </DialogProvider>
  );
};

DialogBase.Panel = DialogPanel;
DialogBase.Title = DialogTitle;
DialogBase.Description = DialogDescription;
DialogBase.Footer = DialogFooter;
DialogBase.Backdrop = DialogBackdrop;

const styles = {
  root: classes(
    'p-3',
    'fixed',
    'inset-0',
    'flex',
    'items-center',
    'justify-center',
  ),
};

export default DialogBase;
