'use client';

import { Title } from '../../../typography';
import { CaptionTitleProps } from './CaptionTitle.types';

const CaptionTitle: React.FC<CaptionTitleProps> = ({ title }) => {
  return <Title>{title}</Title>;
};

export default CaptionTitle;
