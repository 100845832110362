import {
  ExpenseChartDatum,
  ExpenseChartDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const expenseChartDatum = (builder: Builder) =>
  builder.query<ExpenseChartDatum, ExpenseChartDatumParams>({
    query: (params) => ({ url: '/datum/expense-chart-datum', params }),
    providesTags: ['ExpenseChart'],
  });
