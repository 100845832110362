'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import { Button } from '../../button';
import { ChipInputProps } from './ChipInput.types';
import ChipInputItem from './components/ChipInputItem';

const ChipInput: React.FC<ChipInputProps> & {
  Item: typeof ChipInputItem;
} = ({ items, maxItems, onAdded, onDeleted, onEdited, className }) => {
  const translate = useTranslate();

  return (
    <div className={classNames(styles.items, className)}>
      {items.map((item, index) => (
        <ChipInputItem
          key={index}
          item={item}
          index={index}
          onDeleted={onDeleted}
          onEdited={onEdited}
        />
      ))}

      {onAdded && (
        <Button
          color='accent'
          variant='outlined'
          onClick={onAdded}
          className={styles.button}
          disabled={
            (maxItems && items.length === maxItems) ||
            items.filter((i) => i === '').length !== 0
          }
          startIcon={{ name: 'Plus', className: styles.icon }}
        >
          {translate('ui.components.inputs.chip.add')}
        </Button>
      )}
    </div>
  );
};

const styles = {
  items: classes('flex', 'flex-wrap', 'gap-2'),
  button: classes('py-0.5'),
  icon: classes('w-4', 'h-4'),
};

ChipInput.Item = ChipInputItem;

export default ChipInput;
