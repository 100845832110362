'use client';

import { Language } from '@nowadays/base/locales';
import { useLanguage, useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import Icon from '../../icon/Icon';
import { Spinner } from '../spinner';
import { UploadIndicatorProps } from './UploadIndicator.types';

const formatPercentage = (number: number, language: Language) => {
  const percentage = new Intl.NumberFormat(language, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

  return percentage;
};

const UploadIndicator: React.FC<UploadIndicatorProps> = ({
  status,
  className,
}) => {
  const translate = useTranslate();
  const { language } = useLanguage();

  if (!status) {
    return null;
  }

  const success = status === 1 || status === 'success';
  const failed = status === 'failed';
  const loading = typeof status === 'number' && status !== 1;
  const percentange =
    typeof status === 'number' && formatPercentage(status, language);

  return (
    <div
      className={classNames(
        styles.root,
        success && styles.success,
        failed && styles.error,
        className,
      )}
    >
      {loading && percentange && (
        <Fragment>
          <span>{translate('ui.components.indicator.upload.uploading')}</span>
          <span>{percentange}</span>
          <Spinner className={styles.icon} />
        </Fragment>
      )}

      {!loading && success && (
        <Fragment>
          <span>{translate('ui.components.indicator.upload.loaded')}</span>
          <Icon name='Tick' className={styles.icon} />
        </Fragment>
      )}

      {!loading && failed && (
        <Fragment>
          <span>{translate('ui.components.indicator.upload.failed')}</span>
          <Icon name='Close' className={styles.icon} />
        </Fragment>
      )}
    </div>
  );
};

const styles = {
  root: classes(
    'text-xs',
    'bg-skin-base',
    'text-skin-blue',
    'w-min',
    'border',
    'border-skin-silent',
    'flex',
    'px-2.5',
    'py-1',
    'rounded-md',
    'gap-1',
    'justify-center',
    'items-center',
  ),
  success: classes('text-skin-green'),
  error: classes('text-skin-red'),
  icon: classes('w-4', 'h-4', 'inline'),
};

export default UploadIndicator;
