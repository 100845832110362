'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';

import Icon from '../../../icon/Icon';
import { ListboxNotFoundProps } from './ListboxNotFound.types';

const ListboxNotFound: React.FC<ListboxNotFoundProps> = ({ className }) => {
  const translate = useTranslate();

  return (
    <div className={classNames(styles.root, className)}>
      <Icon name='InfoCircle' className={styles.icon} />
      <span>{translate('ui.components.inputs.listbox.notFound')}</span>
    </div>
  );
};

const styles = {
  root: classes(
    'px-2',
    'py-3',
    'text-skin-muted',
    'flex',
    'items-center',
    'space-x-3',
  ),
  icon: classes('w-4', 'h-4'),
};

export default ListboxNotFound;
