'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import Text from '../../../input/text/Text';
import { useTable } from '../../table-context/TableContext';

const PaginationInfo = () => {
  const { table } = useTable();
  const translate = useTranslate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    table.setPageIndex(page);
  };

  return (
    <div className={styles.root}>
      <span className={styles.info}>
        <div>{translate('ui.components.table.pagination.info.page')}</div>
        {table.getState().pagination.pageIndex + 1} -{' '}
        {table.getPageCount() || 1}
      </span>
      <span>|</span>
      <span className={styles.goto}>
        <span>{translate('ui.components.table.pagination.info.goto')}</span>
        <Text
          type='number'
          onChange={handleChange}
          value={table.getState().pagination.pageIndex + 1}
          input={{ className: styles.input }}
        />
      </span>
    </div>
  );
};

const styles = {
  root: classes(
    'flex',
    'gap-3',
    'items-center',
    'text-skin-muted',
    'w-64',
    'justify-center',
  ),
  input: classes('w-16'),
  info: classes('flex', 'items-center', 'gap-1'),
  goto: classes('flex', 'items-center', 'gap-2'),
};

export default PaginationInfo;
