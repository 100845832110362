import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgDocumentAdd = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='m19.218 8.938-.157.988zm-4.156-4.156-.988.157zm-10 15.263.587-.809zM3.954 18.94l.809-.588-.81.588Zm16.09 0-.809-.588zm-1.106 1.106-.588-.809.588.81Zm0-16.09.588-.81zm1.106 1.106.81-.588zM5.061 3.955l.588.809zM3.955 5.06l.809.588zM15 3.03l.034-1zM20.971 9l1-.034zM9 11a1 1 0 1 0 0 2zm6 2a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 2 0zm2-6a1 1 0 1 0-2 0zm6.374-1.05a4 4 0 0 1-3.325-3.324l-1.975.313a6 6 0 0 0 4.987 4.987l.313-1.975ZM12 20c-1.897 0-3.24-.001-4.275-.113-1.017-.11-1.618-.318-2.076-.65l-1.176 1.617c.856.622 1.85.892 3.037 1.02 1.168.127 2.637.126 4.49.126zM2 12c0 1.853-.001 3.322.125 4.49.129 1.187.399 2.18 1.02 3.037l1.619-1.176c-.333-.458-.54-1.06-.65-2.076C4 15.24 4 13.897 4 12zm3.649 7.236a4 4 0 0 1-.885-.885l-1.618 1.176a6 6 0 0 0 1.327 1.327zM20 12c0 1.897-.001 3.24-.113 4.275-.11 1.017-.318 1.618-.65 2.076l1.617 1.176c.622-.856.892-1.85 1.02-3.037.127-1.168.126-2.637.126-4.49zm-8 10c1.853 0 3.322.001 4.49-.125 1.187-.129 2.18-.399 3.037-1.02l-1.176-1.619c-.458.333-1.06.54-2.076.65C15.24 20 13.897 20 12 20zm7.236-3.649c-.247.34-.545.638-.885.885l1.176 1.618a6 6 0 0 0 1.327-1.327zm-.885-13.587a4 4 0 0 1 .885.885l1.618-1.176a6 6 0 0 0-1.327-1.327zM12 2c-1.853 0-3.322-.001-4.49.125-1.187.129-2.18.399-3.037 1.02l1.177 1.62c.458-.333 1.06-.54 2.076-.65C8.76 4 10.103 4 12 4zM4 12c0-1.897.001-3.24.114-4.275.11-1.017.317-1.618.65-2.076L3.146 4.473c-.622.856-.892 1.85-1.02 3.037C1.998 8.678 2 10.147 2 12zm.473-8.854a6 6 0 0 0-1.327 1.327L4.764 5.65a4 4 0 0 1 .885-.885zM12 4c1.175 0 2.142 0 2.966.028l.068-1.998C14.17 2 13.164 2 12 2zm2.966.028c1.852.064 2.746.272 3.385.736l1.176-1.618c-1.17-.85-2.616-1.052-4.493-1.116zm-.966-1c0 .874-.002 1.429.074 1.91l1.975-.312c-.045-.288-.049-.65-.049-1.597zM22 12c0-1.163 0-2.17-.03-3.034l-1.998.068C20 9.858 20 10.825 20 12zm-.03-3.034c-.064-1.877-.266-3.323-1.116-4.493L19.236 5.65c.465.64.672 1.533.736 3.385l1.998-.068ZM20.972 8c-.946 0-1.31-.004-1.597-.05l-.313 1.976c.482.076 1.037.074 1.91.074zM9 13h6v-2H9zm4 2V9h-2v6z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDocumentAdd);
export default ForwardRef;
