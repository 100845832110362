'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import Button from '../../button/button/Button';
import { NotFoundInfoProps } from './NotFoundInfo.types';

const NotFoundInfo: React.FC<NotFoundInfoProps> = ({ onClick }) => {
  const translate = useTranslate();

  const handleClick = () => onClick && onClick();

  return (
    <div className={styles.container}>
      <div className={styles.code}>
        {translate('ui.components.notFound.title')}
      </div>
      <div className={styles.description}>
        {translate('ui.components.notFound.description')}
      </div>

      <Button
        color='accent'
        variant='contained'
        endIcon={{ name: 'Undo' }}
        onClick={handleClick}
      >
        {translate('ui.components.notFound.home')}
      </Button>
    </div>
  );
};

const styles = {
  container: classes(
    'flex',
    'flex-col',
    'items-center',
    'sm:items-start',
    'justify-center',
    'space-y-4',
    'text-center',
    'sm:text-start',
  ),
  code: classes('text-4xl', 'md:text-6xl', 'font-bold', 'font-serif'),
  error: classes('text-3xl', 'text-skin-accent'),
  description: classes('text-skin-muted'),
};

export default NotFoundInfo;
