export const api = {
  datum: {
    contentPlanner: {
      defaults: {
        pillars: {
          guide: 'Rehber',
          howTo: 'Nasıl yapılır',
          news: 'Haber',
          question: 'Soru',
          review: 'İnceleme',
          story: 'Hikaye',
          tip: 'İpucu',
          visual: 'Görsel',
        },
      },
    },
    mealPlanner: {
      defaults: {
        mealTypes: {
          breakfast: 'Kahvaltı',
          lunch: 'Öğle yemeği',
          dinner: 'Akşam yemeği',
          snack: 'Ara öğün',
        },
      },
    },
    expenseTracker: {
      defaults: {
        categories: {
          bills: 'Faturalar',
          debts: 'Borçlar',
          expenses: 'Harcamalar',
          insuranceTaxes: 'Sigorta ve vergiler',
          savings: 'Birikim',
          subscriptions: 'Abonelikler',
        },
        subcategories: {
          bills: {
            electricity: 'Elektrik',
            gas: 'Gaz',
            internet: 'İnternet',
            phone: 'Telefon',
            rent: 'Kira',
            transport: 'Ulaşım',
            water: 'Su',
          },
          debts: {
            creditCard: 'Kredi kartı',
            debitCard: 'Banka kartı',
            mortgage: 'Mortgage',
            personalDebt: 'Kişisel borç',
            studentLoan: 'Öğrenci kredisi',
          },
          expenses: {
            carPayments: 'Araç ödemeleri',
            coffee: 'Kahve',
            dateNight: 'Akşam yemeği',
            diningOut: 'Dışarıda yemek',
            groceries: 'Market alışverişi',
            homeMaintenance: 'Ev bakımı',
            personalCare: 'Kişisel bakım',
            socialEvent: 'Sosyal etkinlik',
            thrifting: 'İkinci el alışverişi',
          },
          insuranceTaxes: {
            carInsurance: 'Araç sigortası',
            healthInsurance: 'Sağlık sigortası',
            homeInsurance: 'Konut sigortası',
            lifeInsurance: 'Hayat sigortası',
            propertyTaxes: 'Emlak vergileri',
          },
          savings: {
            carFund: 'Araç fonu',
            emergencyFund: 'Acil durum fonu',
            houseFund: 'Ev fonu',
            retirement: 'Emeklilik',
            summerHoliday: 'Yaz tatili',
          },
          subscriptions: {
            googleDrive: 'Google drive',
            gym: 'Spor salonu',
            netflix: 'Netflix',
            spotify: 'Spotify',
            youtube: 'YouTube',
          },
        },
      },
    },
  },
  email: {
    changeEmailRequest: { subject: 'E-posta adresini güncelle' },
    changeEmailSuccess: { subject: 'E-posta adresiniz başarıyla güncellendi' },
    createPasswordRequest: { subject: 'Hesabınız için bir şifre oluşturun' },
    passwordResetRequest: { subject: 'Parola sıfırlama talebi' },
    resetPasswordSuccess: {
      subject: 'Parolanız başarılı bir şekilde ayarlandı',
    },
    signupLocalSuccess: { subject: "Nowadays'e hoşgeldiniz!" },
    signupSocialSuccess: { subject: "Nowadays'e hoşgeldiniz!" },
    verifyEmailRequest: { subject: 'E-posta adresinizi doğrulayın' },
  },
  sticker: { stickers: { mostlyUsed: 'Çok kullanılan' } },
  widget: { widgets: { mostlyUsed: 'Çok kullanılan' } },
};
