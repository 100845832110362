import { AnchorButton, Brand } from '@nowadays/ui/components';
import { useNavigate } from 'react-router-dom';

const SidebarHeader = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate('/');

  return (
    <AnchorButton onClick={handleClick}>
      <Brand />
    </AnchorButton>
  );
};

export default SidebarHeader;
