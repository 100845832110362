import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { useEffect, useMemo, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { Button } from '../../../button/index';
import { Popover } from '../../../popover/index';
import Text from '../../text/Text';
import { useColorPicker } from '../context/ColorPickerContext';
import { PickerPanelProps } from './PickerPanel.types';

const HEX_VALID_REGEX = /^#?([0-9A-F]{3}){1,2}$/;

const PickerPanel: React.FC<PickerPanelProps> = ({ close }) => {
  const translate = useTranslate();
  const { color, changeColor } = useColorPicker();
  const [value, setValue] = useState<string>(color || '');

  useEffect(() => {
    if (color !== value) {
      setValue(color);
    }
  }, [color]);

  const isValid = useMemo(() => HEX_VALID_REGEX.test(value), [value]);

  const handleChange = (color: string) => setValue(color.toUpperCase());

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value.toUpperCase());

  const handleConfirm = () => {
    changeColor(value);
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && isValid) {
      handleConfirm();
    }
  };

  return (
    <Popover.Panel
      onKeyDown={handleKeyDown}
      className={styles.panel}
      floating={{ portal: false }}
    >
      <HexColorPicker
        color={value}
        onChange={handleChange}
        className='color-picker'
      />
      <div className={styles.container}>
        <Text
          value={value}
          input={{
            start: (
              <div className={styles.bullet} style={{ background: value }} />
            ),
          }}
          maxLength={7}
          className={styles.input}
          onChange={handleTextChange}
        />

        <Button
          color='accent'
          variant='contained'
          disabled={!isValid}
          onClick={handleConfirm}
        >
          {translate('ui.components.colorPicker.select')}
        </Button>
      </div>
    </Popover.Panel>
  );
};

const styles = {
  panel: classes('flex', 'flex-col', 'gap-2'),
  floating: classes('w-64'),
  container: classes('flex', 'justify-between', 'gap-2'),
  bullet: classes('w-6', 'h-9', 'rounded-l-lg'),
  input: classes('pl-7'),
};

export default PickerPanel;
