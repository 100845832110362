'use client';

import { useElementRef } from '@nowadays/ui/hooks';
import { classes, classNames } from '@nowadays/ui/utils';
import React, { Fragment, useRef, useState } from 'react';

import Divider from '../../divider/Divider';
import Input from '../input/Input';
import { ComboboxOption, ComboboxProps, TValues } from './Combobox.types';
import ComboboxBase from './combobox-base/ComboboxBase';

const Combobox = <T extends TValues>(
  {
    value,
    nullable,
    multiple,
    onChange,
    disabled,
    input,
    options = [],
    className,
    hideEmpty,
    hideTick,
    hideIcon,
    filtering = true,
    loading,
    panel,
    autoComplete = 'off',
    ...props
  }: ComboboxProps<T>,
  ref: React.Ref<HTMLInputElement>,
) => {
  const isEmpty = options.length === 0;

  const [filter, setFilter] = useState<string>('');

  const { disable, ...containerProps } = input || {};

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isAllSelected =
    multiple &&
    value?.length === options.filter((item) => !item.disabled)?.length;

  const filtered =
    filter === ''
      ? options
      : options.filter((option) =>
          option.key
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(filter.toLowerCase().replace(/\s+/g, '')),
        );

  const notFound = !filtered || filtered.length === 0;

  const inputRef = useElementRef((node: HTMLInputElement) => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(node);
      } else {
        (ref as React.MutableRefObject<HTMLInputElement>).current = node;
      }
    }
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filtering && setFilter(e.target.value);
  };

  const handleSelectAll = () =>
    multiple && onChange && onChange(options.filter((item) => !item.disabled));

  const handleClear = () => {
    multiple && onChange && onChange([]);
    !multiple && nullable && onChange && onChange(null);
    buttonRef?.current?.click();
  };

  const handleDisplayValue = (value: ComboboxOption<T> | ComboboxOption<T>[]) =>
    (value &&
      (Array.isArray(value) ? value.map((i) => i.key).join(',') : value.key)) ||
    '';

  const handleChange = (selected: ComboboxOption<T> & ComboboxOption<T>[]) => {
    onChange && onChange(selected);
    !multiple && setFilter('');
  };

  return (
    <ComboboxBase.Base
      disabled={disabled}
      onChange={handleChange}
      multiple={multiple}
      value={value || (multiple ? [] : null)}
    >
      {({ open }) => (
        <Fragment>
          <Input disable={disabled || disable} {...containerProps}>
            {({
              inputStyles,
              backgroundStyles,
              focusStyles,
              borderStyles,
              errorStyles,
              disabledStyles,
              customStyles,
              boxProps,
            }) => (
              <Input.Box
                end={
                  <ComboboxBase.Adornment
                    loading={loading}
                    disabled={disabled || loading}
                  />
                }
                className={classNames(
                  backgroundStyles,
                  borderStyles,
                  errorStyles,
                  disabledStyles,
                  customStyles,
                )}
                {...boxProps}
              >
                <ComboboxBase.Button ref={buttonRef} className={styles.button}>
                  <ComboboxBase.Input
                    ref={inputRef}
                    onChange={handleFilterChange}
                    displayValue={handleDisplayValue}
                    autoComplete={autoComplete}
                    className={classNames(
                      inputStyles,
                      focusStyles,
                      styles.end,
                      className,
                    )}
                    {...props}
                  />
                </ComboboxBase.Button>
              </Input.Box>
            )}
          </Input>

          <ComboboxBase.Panel
            reference={buttonRef.current}
            open={open}
            {...panel}
            className={classNames(
              hideEmpty && !isEmpty && notFound && styles.notFound,
              panel?.className,
            )}
          >
            {isEmpty && loading && <ComboboxBase.Loading />}

            {!loading && (isEmpty || (!hideEmpty && notFound)) && (
              <ComboboxBase.NotFound />
            )}

            {!isEmpty && !multiple && !notFound && nullable && (
              <Fragment>
                <ComboboxBase.Clear
                  multiple={multiple}
                  onClear={handleClear}
                  disabled={!value}
                />
                <Divider />
              </Fragment>
            )}

            {!isEmpty && multiple && nullable && !notFound && (
              <Fragment>
                {isAllSelected ? (
                  <ComboboxBase.Clear
                    multiple={multiple}
                    onClear={handleClear}
                    disabled={!value || value.length === 0}
                  />
                ) : (
                  <ComboboxBase.SelectAll
                    handleSelectAll={handleSelectAll}
                    disabled={options?.length === 0}
                  />
                )}
                <Divider />
              </Fragment>
            )}

            <ComboboxBase.Options
              options={filtered}
              hideIcon={hideIcon}
              hideTick={hideTick}
            />
          </ComboboxBase.Panel>
        </Fragment>
      )}
    </ComboboxBase.Base>
  );
};

const styles = {
  end: classes('pr-8'),
  button: classes('w-full', 'h-full', 'flex'),
  notFound: classes('border-0'),
};

export default Object.assign(React.forwardRef(Combobox), ComboboxBase);
