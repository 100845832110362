'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import { ImageSliderProvider } from './image-slider-context/ImageSliderProvider';
import ImageSliderPaging from './image-slider-paging/ImageSliderPaging';
import ImageSliderPreview from './image-slider-preview/ImageSliderPreview';
import ImageSliderThumbnail from './image-slider-thumbnail/ImageSliderThumbnail';
import { ImageSliderProps } from './ImageSlider.types';

const ImageSlider: React.FC<ImageSliderProps> & {
  Paging: typeof ImageSliderPaging;
  Thumbnail: typeof ImageSliderThumbnail;
  Preview: typeof ImageSliderPreview;
} = ({ images, loading, className, children, paging, thumbnail, preview }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <ImageSliderProvider images={images} loading={loading}>
        {children ? (
          children
        ) : (
          <Fragment>
            <ImageSliderPreview {...preview} />

            <ImageSliderPaging {...paging}>
              {loading
                ? [...new Array(4)].map((_, key) => (
                    <ImageSliderThumbnail key={key} loading {...thumbnail} />
                  ))
                : ({ src, index, selected, handleSelect }) => (
                    <ImageSliderThumbnail
                      key={index}
                      index={index}
                      src={src}
                      selected={selected}
                      onClick={() => handleSelect(index)}
                      {...thumbnail}
                    />
                  )}
            </ImageSliderPaging>
          </Fragment>
        )}
      </ImageSliderProvider>
    </div>
  );
};

ImageSlider.Paging = ImageSliderPaging;
ImageSlider.Thumbnail = ImageSliderThumbnail;
ImageSlider.Preview = ImageSliderPreview;

const styles = {
  root: classes(
    'grid',
    'justify-items-center',
    'md:flex',
    'md:flex-row-reverse',
    'overflow-hidden',
    'gap-3',
    'p-0',
    'py-2',
    'sm:px-2',
    'md:px-4',
    'lg:px-6',
  ),
};

export default ImageSlider;
