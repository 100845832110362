'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { Icon } from '../../../icon';
import { IconProps } from '../../../icon/Icon.types';

const ButtonIcon: React.FC<IconProps> = ({ className, ...props }) => {
  return <Icon className={classNames(styles.icon, className)} {...props} />;
};
const styles = {
  icon: classes('w-5', 'h-5', 'flex-none'),
};

export default ButtonIcon;
