'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import CalendarBase from '../../calendar-base/CalendarBase';
import { CalendarDaysProps } from './CalendarDays.types';

const CalendarDays: React.FC<CalendarDaysProps> = ({
  children,
  className,
  extraCellOnStart = true,
}) => {
  return (
    <thead>
      <tr className={classNames(styles.root, className)}>
        {extraCellOnStart && typeof extraCellOnStart === 'boolean' ? (
          <td className={styles.extraCell} />
        ) : (
          extraCellOnStart
        )}
        <CalendarBase.Days>
          {({ day, formatted }) =>
            children ? (
              children({ day })
            ) : (
              <td key={day.toString()}>
                <div className={styles.day}>
                  <label className={className}>{formatted}</label>
                  <div className={styles.divider} />
                </div>
              </td>
            )
          }
        </CalendarBase.Days>
      </tr>
    </thead>
  );
};

const styles = {
  extraCell: classes('w-12'),
  root: classes('text-xs', 'text-skin-silent', 'font-normal', 'text-center'),
  day: classes('grid', 'place-content-center', 'pb-0.5'),
  divider: classes(
    'w-7',
    'border-b',
    'border-opacity-60',
    'border-skin-silent',
  ),
};

export default CalendarDays;
