import { ADMIN_ENVS, APP_ENVS } from '@nowadays/ui/utils';
import type {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  RetryOptions,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ServerError } from './Api.types';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: (APP_ENVS || ADMIN_ENVS).API_URL,
  credentials: 'include',
  responseHandler: 'content-type',
}) as BaseQueryFn<string | FetchArgs, unknown, ServerError, unknown>;

const TagTypes = [
  'Articles',
  'AuthUser',
  'Emails',
  'FontPacks',
  'Languages',
  'Namespaces',
  'PlannerCovers',
  'PlannerImages',
  'PresetPlanners',
  'Reports',
  'Resources',
  'StickerPackItems',
  'StickerPacks',
  'Stickers',
  'Templates',
  'Users',
  'WebFontPacks',
  'Widgets',
  'WidgetSetItems',
  'WidgetSetItemsCodes',
  'WidgetSets',
  'ContentChart',
  'ExpenseChart',
  'HabitChart',
  'MoodChart',
  'ToDoChart',
  'WaterIntakeChart',
  'WeatherChart',
] as const;

export type TagType = (typeof TagTypes)[number];

export const baseApi = createApi({
  baseQuery: rawBaseQuery,
  reducerPath: 'baseApi',
  tagTypes: TagTypes,
  endpoints: () => ({}),
});

export type Builder = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, ServerError, RetryOptions>,
  TagType,
  'baseApi'
>;
