import React from 'react';

import { IconNames } from '../../../../../icon';
import { useRichText } from '../../../context/RichTextContext';
import RichTextToolbarButton from '../../rich-text-toolbar-button/RichTextToolbarButton';
import { TextAlignProps } from './RichTextTextAlign.types';

const RichTextTextAlign: React.FC<TextAlignProps> = ({ textAlign }) => {
  const { editor } = useRichText();

  if (!editor) {
    return null;
  }

  let icon: IconNames;

  switch (textAlign) {
    case 'left':
      icon = 'TextAlignLeft';
      break;

    case 'center':
      icon = 'TextAlignCenter';
      break;
    case 'right':
      icon = 'TextAlignRight';
      break;
    case 'justify':
      icon = 'TextAlignJustifyCenter';
      break;

    default:
      break;
  }

  return (
    <RichTextToolbarButton
      name={icon}
      active={editor.isActive({ textAlign })}
      onClick={() => editor.chain().focus().setTextAlign(textAlign).run()}
    />
  );
};

export default RichTextTextAlign;
