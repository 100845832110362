'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { animated, useSpring } from '@react-spring/web';

import BottomSheetContent from '../bottom-sheet-content/BottomSheetContent';
import { useBottomSheet } from '../bottom-sheet-context/BottomSheetContext';
import BottomSheetToggle from '../bottom-sheet-toggle/BottomSheetToggle';
import { BottomSheetPanelProps } from './BottomSheetPanel.types';

const BottomSheetPanel: React.FC<BottomSheetPanelProps> = ({
  children,
  className,
}) => {
  const { y, drag, transition } = useBottomSheet();
  const springs = useSpring({ y });

  return transition(({ height }, open) => (
    <animated.div
      {...drag()}
      style={{ height, ...springs }}
      className={classNames(styles.root, !open && styles.closed, className)}
    >
      <BottomSheetToggle />
      <BottomSheetContent>{children}</BottomSheetContent>
    </animated.div>
  ));
};

const styles = {
  root: classes(
    'z-[1600]',
    'fixed',
    'w-full',
    'h-full',
    'bottom-0',
    'rounded-tl-xl',
    'rounded-tr-xl',
    'bg-skin-primary',
    'flex',
    'flex-col',
    'border-t',
    'border-skin-silent',
    'touch-none',
  ),
  closed: classes('hidden'),
};

export default BottomSheetPanel;
