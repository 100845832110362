import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgSync = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M20 12.35a1 1 0 1 1 2 0zm-15 0a1 1 0 1 1-2 0zm13.96-5.525-.72.694-.02-.021-.02-.023zM6.04 17.875l-.76.65-.012-.014-.011-.015zm12.887-14.87a1 1 0 0 1 2-.01zm1.007 1.683 1-.005zm-3.128 3.128-.004 1zm-1.692.992a1 1 0 1 1 .009-2zm4.473-1.64.79.611-.79-.612Zm-.301.3.612.791-.612-.79ZM6.142 21.629a1 1 0 1 1-2 .008l2-.009Zm-1.008-1.684-1 .004zm3.128-3.128.005-1zm1.692-.993a1 1 0 0 1-.008 2zm-4.473 1.641-.79-.612zm.302-.3-.612-.792.612.791Zm13.662-9.836.706.708zM12.5 19.85a7.5 7.5 0 0 0 7.5-7.5h2a9.5 9.5 0 0 1-9.5 9.5zm0-15a7.5 7.5 0 0 0-7.5 7.5H3a9.5 9.5 0 0 1 9.5-9.5zm5.7 2.625a7.48 7.48 0 0 0-5.7-2.625v-2a9.48 9.48 0 0 1 7.22 3.325zm-11.4 9.75a7.48 7.48 0 0 0 5.7 2.625v2a9.48 9.48 0 0 1-7.22-3.325zm14.127-14.23.007 1.688-2 .01-.007-1.689 2-.008Zm-4.125 5.82-1.688-.007.009-2 1.688.008-.01 2Zm4.132-4.132c.003.65.007 1.222-.043 1.685-.053.484-.176.975-.513 1.411l-1.582-1.224c.02-.024.073-.1.106-.404.036-.324.035-.762.032-1.459zm-4.123 2.133c.696.003 1.134.003 1.459-.032.304-.033.38-.087.404-.106l1.224 1.581c-.436.338-.927.46-1.411.513-.464.05-1.036.047-1.685.044zM4.14 21.636l-.007-1.688 2-.009.008 1.688-2 .01Zm4.126-5.82 1.687.007-.008 2-1.688-.007zm-4.133 4.132c-.003-.65-.007-1.22.044-1.685.053-.483.175-.975.513-1.41l1.581 1.223c-.019.025-.073.1-.106.404-.035.325-.035.763-.032 1.46zm4.124-2.132c-.697-.003-1.135-.004-1.46.032-.303.033-.379.087-.403.106L5.17 16.372c.436-.337.927-.46 1.41-.512.464-.051 1.036-.047 1.686-.044zm12.12-10.037q-.105.135-.227.257l-1.413-1.415a1 1 0 0 0 .058-.066zm-.227.257q-.12.12-.253.223l-1.224-1.581a1 1 0 0 0 .064-.057zm-.471-1.905.485.503-1.44 1.388-.485-.503zM4.69 16.852q.091-.117.195-.224l1.437 1.391a1 1 0 0 0-.05.057l-1.581-1.224Zm.195-.224a3 3 0 0 1 .286-.256l1.224 1.582a1 1 0 0 0-.073.065zm.372 1.868-.437-.551 1.567-1.242.437.551z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSync);
export default ForwardRef;
