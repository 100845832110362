import { ChangePlannerImage, PlannerImage } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { plannerEndpoints } from '../planner/Planner.endpoints';
import { presetPlannerEndpoints } from '../preset-planner/PresetPlanner.endpoints';

export const changePlannerImage = (builder: Builder) =>
  builder.mutation<PlannerImage, ChangePlannerImage>({
    query: (body) => ({
      url: '/planner-image/change',
      method: 'POST',
      body,
    }),
    async onQueryStarted({ planner }, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;

        dispatch(
          plannerEndpoints.util.updateQueryData(
            'planners',
            undefined,
            (draft) => {
              const found = draft.find((i) => i.id === planner);

              if (found) {
                Object.assign(found.image, data);
              }
            },
          ),
        );

        dispatch(
          presetPlannerEndpoints.util.updateQueryData(
            'presetPlanners',
            undefined,
            (draft) => {
              const found = draft.find((i) => i.id === planner);

              if (found) {
                Object.assign(found.image, data);
              }
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
    invalidatesTags: (res, err) => (!err && ['PlannerImages']) || [],
  });
