'use client';

import { classes } from '@nowadays/ui/utils';

import { useBottomSheet } from '../bottom-sheet-context/BottomSheetContext';

const BottomSheetToggle = () => {
  const { onClose } = useBottomSheet();

  const handleClick = () => onClose();

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.bullet} />
    </div>
  );
};

const styles = {
  root: classes(
    'grid',
    'place-content-center',
    'cursor-row-resize',
    'w-full',
    'h-6',
  ),
  bullet: classes(
    'w-24',
    'bg-skin-silent',
    'border',
    'border-skin-silent',
    'h-1.5',
    'rounded-full',
  ),
};

export default BottomSheetToggle;
