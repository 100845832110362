import { WidgetCodes } from '../widget/Widget.types';
import { PageElements } from './Page.types';

export const WIDGET_CODES = Object.values(WidgetCodes);

export type WidgetCode = (typeof WIDGET_CODES)[number];

export type WidgetElementData = {
  code: WidgetCodes;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  zIndex?: number;
};

export type WidgetElementType = {
  id: string;
  type: PageElements.Widget;
  data: WidgetElementData;
  createdAt: string;
};
