import {
  WeatherChartDatum,
  WeatherChartDatumParams,
} from '@nowadays/base/types';

import { Builder } from '../base/query/Api';

export const weatherChartDatum = (builder: Builder) =>
  builder.query<WeatherChartDatum, WeatherChartDatumParams>({
    query: (params) => ({ url: '/datum/weather-chart-datum', params }),
    providesTags: ['WeatherChart'],
  });
