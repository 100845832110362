import { Title } from '@nowadays/ui/components';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';

import { FormWrapperProps } from './FormWrapper.types';

const FormWrapper: React.FC<FormWrapperProps> = ({ title, children }) => {
  const translate = useTranslate();

  return (
    <div className={styles.wrapper}>
      <Title className={styles.header}>{translate(title)}</Title>
      {children}
    </div>
  );
};

const styles = {
  wrapper: classes(
    'p-4',
    'space-y-4',
    'h-auto',
    'w-full',
    'max-w-md',
    'rounded-2xl',
    'bg-skin-primary',
  ),
  header: classes('text-xl'),
};

export default FormWrapper;
