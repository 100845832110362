import { WidgetSet } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { widgetSetEndpoints } from './WidgetSet.endpoints';

export const widgetSets = (builder: Builder) =>
  builder.query<WidgetSet[], void>({
    query: () => `/widget-set/sets`,
    providesTags: (res) =>
      res
        ? [
            ...res.map(({ id }) => ({ type: 'WidgetSets' as const, id })),
            { type: 'WidgetSets', id: 'LIST' },
          ]
        : [{ type: 'WidgetSets', id: 'LIST' }],
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data: sets } = await queryFulfilled;

        sets.forEach(async (set) => {
          await dispatch(
            widgetSetEndpoints.util.upsertQueryData(
              'widgetSet',
              { id: set.id },
              set,
            ),
          );
        });
      } catch (err) {
        console.error(err);
      }
    },
  });
