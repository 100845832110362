import { ApplyTemplateParams, Page } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { pageEndpoints } from '../page/Page.endpoints';

export const applyTemplate = (builder: Builder) =>
  builder.mutation<Page, ApplyTemplateParams>({
    query: (params) => ({
      url: '/template/apply',
      method: 'POST',
      params,
    }),
    async onQueryStarted(
      { planner, page },
      { dispatch, queryFulfilled, getState },
    ) {
      try {
        const { data } = await queryFulfilled;

        const activePage = (getState() as RootState).page.activePage;

        dispatch(
          pageEndpoints.util.updateQueryData('page', { id: data.id }, (draft) =>
            Object.assign(draft, data),
          ),
        );

        dispatch(
          pageEndpoints.util.updateQueryData(
            'pages',
            { planner, period: activePage.period },
            (draft) => {
              const index = draft.findIndex((i) => i.id === page);

              if (index) {
                draft[index] = data;
              }
            },
          ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
