'use client';

import { Language } from '@nowadays/base/locales';
import { HourFormat, TimeInterval, WeekStartsOn } from '@nowadays/base/types';
import { Day, Locale } from 'date-fns';
import { createContext, useContext } from 'react';

export interface Settings {
  language: Language;
  timeZone: string;
  currency: string;
  weekStartsOn: WeekStartsOn;
  hourFormat: HourFormat;
  dayStartsAt: number;
  dayEndsAt: number;
}

export interface FormatOptions {
  locale: Locale;
  timeZone: string;
  weekStartsOn: Day;
}

export type FormatLocaleOptions = Intl.DateTimeFormatOptions;

interface RegionContextProps {
  language: Language;
  timeZone: string;
  currency: string;
  currencySymbol: string;
  weekStartsOn: WeekStartsOn;
  hourFormat: HourFormat;
  dayStartsAt: number;
  dayEndsAt: number;
  formatOptions: FormatOptions;
  changeSettings: (settings: Partial<Settings>) => void;
  format: (date: Date, formatStr: string) => string;
  formatLocale: (date: Date, options?: FormatLocaleOptions) => string;
  formatDistance: (date: Date, baseDate: Date) => string;
  formatTime: (time: TimeInterval, formatStr?: string) => string;
  parseTime: (time: string) => TimeInterval | undefined;
  compareTime: (left: TimeInterval, right: TimeInterval) => boolean;
  formatNumber: (value: number, options?: Intl.NumberFormatOptions) => string;
  formatPercent: (value: number, hideZero?: boolean) => string;
  formatAmount: (amount: number, options?: Intl.NumberFormatOptions) => string;
}

export const RegionContext = createContext<RegionContextProps>({
  language: null,
  timeZone: null,
  currency: null,
  currencySymbol: null,
  weekStartsOn: null,
  hourFormat: null,
  dayStartsAt: null,
  dayEndsAt: null,
  formatOptions: null,
  changeSettings: () => null,
  format: () => null,
  formatLocale: () => null,
  formatDistance: () => null,
  formatTime: () => null,
  parseTime: () => null,
  compareTime: () => null,
  formatNumber: () => null,
  formatPercent: () => null,
  formatAmount: () => null,
});

export const useRegion = () => {
  return useContext(RegionContext);
};
