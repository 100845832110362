import { DEVICE_THEMES, DeviceTheme } from '@nowadays/base/types';
import { Radio, useInterface } from '@nowadays/ui/components';
import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, setLocalStorage } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import SelectionTitle from '../selection-title/SelectionTitle';

const ThemeSelection = () => {
  const translate = useTranslate();
  const { deviceTheme, changeTheme } = useInterface();

  const value = DEVICE_THEMES.find((lng) => lng === deviceTheme);

  const handleChange = (value: DeviceTheme) => {
    changeTheme(value);
    setLocalStorage<DeviceTheme>('theme', value);
  };

  return (
    <Fragment>
      <SelectionTitle
        title={translate('admin.shared.sidebar.footer.theme.title')}
      />
      <Radio value={value} onChange={handleChange} className={styles.root}>
        {DEVICE_THEMES.map((lng, key) => (
          <Radio.Option key={key} value={lng} className={styles.option}>
            {translate(`admin.shared.sidebar.footer.theme.options.${lng}`)}
          </Radio.Option>
        ))}
      </Radio>
    </Fragment>
  );
};

const styles = {
  root: classes('flex', 'space-x-4'),
  option: classes('flex', 'items-center', 'px-2', 'justify-center'),
};

export default ThemeSelection;
