'use client';

import { createContext, useContext } from 'react';

interface ImageSliderContextProps {
  images?: string[];
  loading?: boolean;
  active?: number;
  changeActive: (index: number) => void;
}

export const ImageSliderContext = createContext<ImageSliderContextProps>({
  changeActive: () => null,
});

export const useImageSlider = () => {
  return useContext(ImageSliderContext);
};
