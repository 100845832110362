'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Fragment } from 'react';

import Adornment from './adornment/Adornment';
import Box from './box/Box';
import { InnerInputProps } from './Input.types';

const Input: React.FC<InnerInputProps> & {
  Box: typeof Box;
  Adornment: typeof Adornment;
} = ({ error, disable, readOnly, children, className, ...boxProps }) => (
  <Fragment>
    {children({
      inputStyles: classNames(styles.inputStyles, readOnly && styles.readOnly),
      backgroundStyles: styles.backgroundStyles,
      borderStyles: styles.borderStyles,
      focusStyles: !disable && styles.focusStyles,
      disabledStyles: (disable && styles.disabledStyles) || undefined,
      errorStyles: (error && !disable && styles.errorStyles) || undefined,
      customStyles: classNames(styles.customStyles, className),
      boxProps,
    })}
  </Fragment>
);

Input.Box = Box;
Input.Adornment = Adornment;

const styles = {
  inputStyles: classes(
    'h-8',
    'px-2.5',
    'w-full',
    'transition',
    'duration-200',
    'ease-in-out',
    'text-sm',
    'rounded-[0.425rem]',
    'bg-transparent',
    'placeholder-skin-primary',
  ),
  backgroundStyles: classes('bg-skin-base'),
  focusStyles: classes('focus-ring'),
  disabledStyles: classes(
    'opacity-80',
    'disabled:opacity-80',
    'cursor-default',
    'pointer-events-none',
  ),
  borderStyles: classes('border', 'border-skin-silent'),
  errorStyles: classes('outline-2', '-outline-offset-1', 'outline-skin-red'),
  readOnly: classes('cursor-default'),
  customStyles: classes('rounded-lg'),
};

export default Input;
