'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { Controller } from 'react-hook-form';

import Selectbox from '../../../input/selectbox/Selectbox';
import { SelectboxOption } from '../../../input/selectbox/Selectbox.types';
import FieldError from '../../field-error/FieldError';
import FieldLabel from '../../field-label/FieldLabel';
import { useFormControl } from '../../form-control/useFormControl';
import { FormSelectboxProps } from './FormSelectbox.types';

const FormSelectbox: React.FC<FormSelectboxProps> = ({
  field,
  by,
  options = [],
  label,
  disabled,
  defaultValue,
  input,
  container,
  ...props
}) => {
  const { control, error, isDisabled } = useFormControl(field);

  const isDisable = disabled || isDisabled;
  const isError = !!error && !isDisable;

  return (
    <div key={field} className={classNames(styles.root, container?.className)}>
      {label && <FieldLabel label={label} htmlFor={field} />}

      <Controller
        control={control}
        name={field}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, ...rest } }) => {
          const selected = value
            ?.map((v) =>
              options?.find(
                (i) => (by && (i[by] as unknown) === v) || i.key === v.key,
              ),
            )
            .filter((v) => v);

          const handleChange = (selected: SelectboxOption[]) =>
            onChange(selected.map((i) => i[by] || i));

          return (
            <Selectbox
              id={field}
              key={field}
              values={selected}
              options={options}
              disabled={isDisable}
              input={{ error: isError, disable: isDisable, ...input }}
              onChange={handleChange}
              {...rest}
              {...props}
            />
          );
        }}
      />

      {isError && <FieldError error={error} label={label} />}
    </div>
  );
};

const styles = {
  root: classes('w-full'),
};

export default FormSelectbox;
