'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { LogoProps } from './Logo.types';

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 25 14'
      className={classNames(styles.root, className)}
    >
      <path
        fill='currentColor'
        className={styles.accent}
        fillRule='evenodd'
        d='M21.427 13.184a.3.3 0 0 0 .284-.398L18.268 2.783a4 4 0 0 0-5.085-2.48L2.781 3.884a4 4 0 0 0-2.48 5.084l.523 1.518a4 4 0 0 0 3.782 2.697h16.821Zm1.043-.571a.25.25 0 0 0 .461.028l1.31-2.686a3 3 0 0 0-1.381-4.011l-2.686-1.31a.25.25 0 0 0-.346.306l2.642 7.673Z'
        clipRule='evenodd'
      />
    </svg>
  );
};

const styles = {
  root: classes('h-7'),
  accent: classes('text-skin-accent'),
};

export default Logo;
