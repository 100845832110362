import { StickerPack } from '@nowadays/base/types';

import { Builder } from '../base/query/Api';
import { stickerPackEndpoints } from './StickerPack.endpoints';

export const stickerPacks = (builder: Builder) =>
  builder.query<StickerPack[], void>({
    query: () => '/sticker-pack/packs',
    providesTags: (res) =>
      res
        ? [
            ...res.map(({ id }) => ({ type: 'StickerPacks' as const, id })),
            { type: 'StickerPacks', id: 'LIST' },
          ]
        : [{ type: 'StickerPacks', id: 'LIST' }],
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data: packs } = await queryFulfilled;

        packs.forEach(
          async (pack) =>
            await dispatch(
              stickerPackEndpoints.util.upsertQueryData(
                'stickerPack',
                pack.id,
                pack,
              ),
            ),
        );
      } catch (err) {
        console.error(err);
      }
    },
  });
