import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgCool = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12.322 17.008c.482.066 1.217-.217 1.799-.662.31-.238.588-.53.789-.824'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M20.814 10.17a9 9 0 1 1-17.628 0m16.8-2.324A9 9 0 0 0 12 3a9 9 0 0 0-7.986 4.846'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M3 8.885V7.846h9v1.039a4.5 4.5 0 0 1-9 0m9 0V7.846h9v1.039a4.5 4.5 0 0 1-9 0'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCool);
export default ForwardRef;
