import { classes } from '@nowadays/ui/utils';
import React from 'react';

import Icon from '../../../../../icon/Icon';
import ImageNodeAltEdit from './components/image-node-alt-edit/ImageNodeAltEdit';
import { ImageNodeAltEditProps } from './components/image-node-alt-edit/ImageNodeAltEdit.types';
import ImageNodeAltInfo from './components/image-node-alt-info/ImageNodeAltInfo';

const ImageNodeAlt: React.FC<ImageNodeAltEditProps> = ({
  onChange,
  onConfirm,
  value,
}) => {
  return (
    <div className={styles.alt}>
      <Icon name={value ? 'Tick' : 'Warning'} className={styles.icon} />
      <ImageNodeAltInfo value={value} />
      <ImageNodeAltEdit
        value={value}
        onChange={onChange}
        onConfirm={onConfirm}
      />
    </div>
  );
};

const styles = {
  alt: classes(
    'absolute',
    'bottom-4',
    'left-4',
    'p-2',
    'flex',
    'items-center',
    'gap-2',
    'bg-skin-primary',
    'rounded-lg',
    'border',
    'border-skin-silent',
  ),
  icon: classes('w-5', 'h-5'),
};

export default ImageNodeAlt;
