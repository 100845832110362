import type { SVGProps } from 'react';
import * as React from 'react';
import { forwardRef,Ref } from 'react';
const SvgRight = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={2}
      d='m10 7 1.763 1.747c1.406 1.394 2.109 2.09 2.217 2.94a2.5 2.5 0 0 1 0 .626c-.108.85-.811 1.546-2.217 2.94L10 17'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRight);
export default ForwardRef;
