'use client';

import { classes, classNames } from '@nowadays/ui/utils';
import { useEffect, useRef, useState } from 'react';

import { IconButton } from '../../../button';
import { FieldError } from '../../../form';
import Text from '../../text/Text';
import { ChipInputItemProps } from './ChipInputItem.types';

const ChipInputItem: React.FC<ChipInputItemProps> = ({
  item,
  index,
  maxLength = 32,
  onDeleted,
  onEdited,
  className,
}) => {
  const inputRef = useRef(null);
  const spanRef = useRef(null);
  const [errorAt, setErrorAt] = useState<number | undefined>();
  const [internalValue, setInternalValue] = useState<string>(item);

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.textContent = internalValue;
      if (inputRef.current) {
        inputRef.current.style.width = `${spanRef.current.offsetWidth + 32}px`;
      }
    }
  }, [internalValue]);

  const handleDelete = () => onDeleted && onDeleted(index);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setInternalValue(value);

    setErrorAt(value.length > maxLength ? index : undefined);
  };

  const handleDebounceChange = (value: string) =>
    onEdited && value.length <= maxLength && onEdited(index, value as string);

  return (
    <div className={styles.root}>
      <span ref={spanRef} className={styles.span}>
        {internalValue}
      </span>

      <Text
        ref={inputRef}
        value={item}
        autoFocus={item === ''}
        className={classNames(styles.text, className)}
        input={{
          className: styles.input,
          end: onDeleted && (
            <IconButton
              name='Close'
              color='red'
              variant='text'
              onClick={handleDelete}
              className={styles.delete}
              icon={{ className: styles.icon }}
            />
          ),
        }}
        disabled={!onEdited}
        onChange={handleChange}
        onDebounceChanged={handleDebounceChange}
      />
      {errorAt === index && (
        <FieldError
          error={{
            message: 'base.error.string.max',
            params: {
              max: maxLength,
            },
          }}
          className={styles.error}
        />
      )}
    </div>
  );
};

const styles = {
  root: classes('relative'),
  span: classes('invisible', 'absolute', 'whitespace-pre'),
  text: classes('h-7', 'pr-7', 'w-auto', 'min-w-24', 'max-w-64'),
  input: classes('flex'),
  delete: classes('p-0.5', 'mr-0.5'),
  icon: classes('w-4', 'h-4'),
  error: classes('first-letter:uppercase'),
};

export default ChipInputItem;
