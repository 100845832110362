'use client';

import { classes } from '@nowadays/ui/utils';

import { MockupEmpty } from '../../mockup';
import NotFoundInfo from '../not-found-info/NotFoundInfo';
import { NotFoundContainerProps } from './NotFoundContainer.types';

const NotFoundContainer: React.FC<NotFoundContainerProps> = ({ onClick }) => {
  return (
    <div className={styles.root}>
      <MockupEmpty />
      <NotFoundInfo onClick={onClick} />
    </div>
  );
};

const styles = {
  root: classes(
    'h-full',
    'p-6',
    'gap-6',
    'flex',
    'flex-col',
    'sm:flex-row',
    'items-center',
    'text-center',
    'justify-center',
  ),
};

export default NotFoundContainer;
