import { baseApi } from '../base/query/Api';
import { datePage } from './DatePage.api';
import { favoritePage } from './FavoritePage.api';
import { page } from './Page.api';
import { pages } from './Pages.api';

export const pageEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    pages: pages(builder),
    page: page(builder),
    datePage: datePage(builder),
    favoritePage: favoritePage(builder),
  }),
});

export const {
  usePagesQuery,
  usePageQuery,
  useDatePageQuery,
  useFavoritePageMutation,
} = pageEndpoints;
