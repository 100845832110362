import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useStateDispatch } from '../../store/useStateDispatch';
import { HistoryState, HistoryType } from './History.slice.types';

const initialState: HistoryState = {
  undoStack: [],
  redoStack: [],
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addToHistory: (state, { payload }: PayloadAction<HistoryType>) => {
      state.undoStack.push(payload);
      state.redoStack = [];
    },
    undo: (state) => {
      if (state.undoStack.length > 0) {
        const lastAction = state.undoStack.pop();
        state.redoStack.push(lastAction);
      }
    },
    redo: (state) => {
      if (state.redoStack.length > 0) {
        const nextAction = state.redoStack.pop();
        state.undoStack.push(nextAction);
      }
    },
    clearHistory: (state) => {
      state.undoStack = [];
      state.redoStack = [];
    },
  },
});

export const historyReducer = historySlice.reducer;

export const useHistoryActions = () => {
  const { dispatch } = useStateDispatch();

  return useMemo(
    () => bindActionCreators({ ...historySlice.actions }, dispatch),
    [dispatch],
  );
};
